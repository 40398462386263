import { Component, OnInit, ViewEncapsulation, TemplateRef } from '@angular/core';
import { ToastData, ToastOptions, ToastyService } from 'ng2-toasty';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ServiceApi } from '../../../shared/services/ServiceApi';
import { ProductQuotationCustomerBE } from '../../../Entity/ProductQuotationCustomerBE'
import { Router } from '@angular/router';
import { CompileShallowModuleMetadata } from '@angular/compiler';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';



@Component({
  selector: 'app-quotation_product_customer',
  templateUrl: './quotation_product_customer.component.html',
  styleUrls: [
    '../../../../../node_modules/ng2-toasty/style-bootstrap.css',
    '../../../../../node_modules/ng2-toasty/style-default.css',
    '../../../../../node_modules/ng2-toasty/style-material.css'
  ],
  encapsulation: ViewEncapsulation.None
})
export class QuotationProductCusotmerComponent implements OnInit {

  public loading = false;
  position = 'bottom-right';
  title: string;
  msg: string;
  strMeasure: string;
  showClose = true;
  theme = 'bootstrap';
  type = 'default';
  closeOther = false;
  modalRef: BsModalRef;
  public isCollapsed = true;
  showAdditional = false

  lstTrademarks: any = [];
  lstDelivertimes: any = [];
  lstMeasures: any = [];
  objProductTypeCustomer: any = {};
  objHistory: any = {};
  objProductQuotationCustomer = new ProductQuotationCustomerBE();
  objResponseProductQuotationCustomer: any = [];
  public category = {};
  DataForm: FormGroup;


  constructor(
    private toastyService: ToastyService,
    private services: ServiceApi,
    private modalService: BsModalService,
    private router: Router,
    private _formBuilder: FormBuilder,
  ) {
    this.router.getCurrentNavigation().extras.state

    this.DataForm = this._formBuilder.group({
      quantity: ['', Validators.required]
    });

  }

  gotoSubcategory(value) {
    this.category = value
    this.router.navigateByUrl('customersubcategories', { state: this.category });
  }


  ngOnInit() {
    this.objHistory = Object.assign({}, history.state);
    this.objProductQuotationCustomer.height = 0;
    this.objProductQuotationCustomer.width = 0;
    this.objProductQuotationCustomer.length = 0;

    this.services.executeMethodGet(this.services.enviroment, "Product/" + this.objHistory.product.id, (x) => {
      this.objProductTypeCustomer = x.information;
      //console.log(this.objProductTypeCustomer)
    });

    this.services.executeMethodGet(this.services.enviroment, "ProviderPrices/Brands/" + this.objHistory.product.id, (x) => {
      this.lstTrademarks = x.information.items;
      //console.log(this.lstTrademarks)
    });

    this.services.executeMethodGet(this.services.enviroment, "ProviderPrices/DeliveryTimes/" + this.objHistory.product.id, (x) => {
      this.lstDelivertimes = x.information.items;
      //console.log(this.lstDelivertimes)
    });

    this.services.executeMethodGet(this.services.enviroment, "Category/Measures/" + this.objHistory.product.id, (x) => {
      this.lstMeasures = x.information.items;
    });

  }

  onChangeMeasure(event) {
    if (this.strMeasure == "Tramo") {
      this.showAdditional = true
      this.objProductQuotationCustomer.height = 0;
      this.objProductQuotationCustomer.width = 0;
    } else {
      this.showAdditional = false
      var item = this.strMeasure.split("x")
      this.objProductQuotationCustomer.height = +item[0];
      this.objProductQuotationCustomer.width = +item[1];
    }
  }

  QuoteProductCustomer() {
    this.modalRef.hide();
    this.loading = true
    console.log(this.objProductTypeCustomer)
    this.objProductQuotationCustomer.userId = this.services.getStorage('userInfo').id;
    this.objProductQuotationCustomer.productId = this.objProductTypeCustomer.id;
    this.objProductQuotationCustomer.measureId = "";
    this.services.executeMethod(this.services.catalog, "Quotation", this.objProductQuotationCustomer, (x) => {
      this.loading = false
      this.objResponseProductQuotationCustomer = x
      if (this.objResponseProductQuotationCustomer.status == true) {
        this.modalRef.hide();
        this.router.navigateByUrl('successquotationproductcustomer');
        this.ngOnInit();
      }
      this.objProductQuotationCustomer = new ProductQuotationCustomerBE();
    });
  }

  ModalConfirmation(template, orderId) {
    if (this.DataForm.valid) {
      this.modalRef = this.modalService.show(template, {
        class: 'modal-dialog-centered',
      });
    } else {
      this.services.validateAllFormFields(this.DataForm);
    }
  }

  addToast(options) {
    if (options.closeOther) {
      this.toastyService.clearAll();
    }
    this.position = options.position ? options.position : this.position;
    const toastOptions: ToastOptions = {
      title: options.title,
      msg: options.msg,
      showClose: options.showClose,
      timeout: options.timeout,
      theme: options.theme,
      onAdd: (toast: ToastData) => {
        /* added */
      },
      onRemove: (toast: ToastData) => {
        /* removed */
      }
    };

    switch (options.type) {
      case 'default': this.toastyService.default(toastOptions); break;
      case 'info': this.toastyService.info(toastOptions); break;
      case 'success': this.toastyService.success(toastOptions); break;
      case 'wait': this.toastyService.wait(toastOptions); break;
      case 'error': this.toastyService.error(toastOptions); break;
      case 'warning': this.toastyService.warning(toastOptions); break;
    }
  }
}
