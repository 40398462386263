import { Component, OnInit } from '@angular/core';
import { ServiceApi } from '../../../../shared/services/ServiceApi';
import { UserLoginBE } from '../../../../Entity/UserLoginBE'
import { Router } from '@angular/router';

@Component({
  selector: 'app-restore_password',
  templateUrl: './restore_password.component.html'
})
export class RestorePasswordComponent implements OnInit {

  objUserLogin = new UserLoginBE();
  public identity;
  public _user;
  data: any = [];
  strEmail = ""
  showError = false
  strError = ""

  constructor(
    private services: ServiceApi,
    private router: Router
  ) { }

  sendCode() {
    this.showError = false
    this.services.executeMethodWithOut(this.services.security, "User/sendVerificationCode/" + this.strEmail, {}, (x) => {
      console.log(x)
      if (x.status) {
        this.services.setStorage("emailRecovery", this.strEmail)
        this.router.navigate(['./verifyCode']);
      } else {
        this.showError = true
        this.strError = x.message
      }
    });
  }

  ngOnInit() {
    document.querySelector('body').setAttribute('themebg-pattern', 'theme1');
  }


}
