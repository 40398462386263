import { Component, OnInit, ViewEncapsulation, TemplateRef } from '@angular/core';
import { ToastData, ToastOptions, ToastyService } from 'ng2-toasty';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ServiceApi } from '../../../../shared/services/ServiceApi';
import { EpaycoRequestBE } from '../../../../Entity/EpaycoRequestBE';
import { UserBE } from '../../../../Entity/UserBE';
import { Router } from '@angular/router';


@Component({
  selector: 'app-cash_provider',
  templateUrl: './cash_provider.component.html',
  styleUrls: [
    '../../../../../../node_modules/ng2-toasty/style-bootstrap.css',
    '../../../../../../node_modules/ng2-toasty/style-default.css',
    '../../../../../../node_modules/ng2-toasty/style-material.css'    
  ],
  encapsulation: ViewEncapsulation.None
})
export class CashProviderComponent implements OnInit {  

  position = 'bottom-right';
  title: string;
  msg: string;
  showClose = true;
  theme = 'bootstrap';
  type = 'default';
  closeOther = false;
  modalRef: BsModalRef;
  strError = ""
  strBranch = ""

  objUser = new UserBE()
  objRequestPayment = new EpaycoRequestBE()

  lstPaymentsProvider: any = [];

  public provider = {};

  constructor(
    private toastyService: ToastyService,
    private services: ServiceApi,
    private modalService: BsModalService,
    private router: Router
  ) {

  }

  PaymentmethodsProvider(value) {    
    this.provider = value
    this.router.navigateByUrl('/paymentmethodsproviders', { state: this.provider });
  }

  GoToConfirmation(x) {    
    var objResponse = x
    this.router.navigateByUrl('/successcashpay', { state: objResponse });
  }

  ngOnInit() {
    this.objUser = this.services.getStorage('userInfo')
    this.services.executeMethodGet(this.services.enviroment, "Epayco/GetLastPayments/" + this.services.getStorage('userInfo').id, (x) => {
      this.lstPaymentsProvider = x.information.items;
      //console.log(this.lstOrdersProvider)
    });
  }

  onChangePayment(value){
    this.strBranch = value
    this.objRequestPayment.branch = value
  }

  MakePaymentCASH() {
    debugger
    if (this.objRequestPayment.branch != "" && this.objRequestPayment.branch != undefined) {
      this.strError = ""
      this.objRequestPayment.userId = this.objUser.id
      this.objRequestPayment.typePaymentMethod = 3;
      this.services.executeMethod(this.services.security, "Epayco", this.objRequestPayment, (x) => {
        if (x.status) {
          if(x.information.status){
            this.GoToConfirmation(x)
            //window.open(x.information.message, "_blank");
            //go to confirmation page 
            // x.information.message PIN

            
          } else {
            this.strError = x.information.message
          }

        } else {
          this.strError =  x.message
        }
      });
    } else {
      this.strError = "Debes seleccionar una sucursal"
    }
  }

  ModalEditProvider(template, provider) {
    this.modalRef = this.modalService.show(template,{
      class: 'modal-lg modal-dialog-centered',
    });
    ///this.objProvider = provider
    //this.objProvider.password = "";
  }

  addToast(options) {
    if (options.closeOther) {
      this.toastyService.clearAll();
    }
    this.position = options.position ? options.position : this.position;
    const toastOptions: ToastOptions = {
      title: options.title,
      msg: options.msg,
      showClose: options.showClose,
      timeout: options.timeout,
      theme: options.theme,
      onAdd: (toast: ToastData) => {
        /* added */
      },
      onRemove: (toast: ToastData) => {
        /* removed */
      }
    };

    switch (options.type) {
      case 'default': this.toastyService.default(toastOptions); break;
      case 'info': this.toastyService.info(toastOptions); break;
      case 'success': this.toastyService.success(toastOptions); break;
      case 'wait': this.toastyService.wait(toastOptions); break;
      case 'error': this.toastyService.error(toastOptions); break;
      case 'warning': this.toastyService.warning(toastOptions); break;
    }
  }
}
