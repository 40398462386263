import { CardInformationBE } from './CardInformationBE'

export class EpaycoRequestBE {
    constructor(){
    }
    userId: string;
    typePaymentMethod: number;
    dues: number;
    cardInformation: CardInformationBE;    
    cardToken: string;
    branch: string;
    bankCode: string;
}