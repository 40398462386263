import { Component, OnInit, ViewEncapsulation, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { ServiceApi } from '../../../shared/services/ServiceApi';



@Component({
  selector: 'app-success_cash_pay',
  templateUrl: './success_cash_pay.component.html',
  styleUrls: [
    '../../../../../node_modules/ng2-toasty/style-bootstrap.css',
    '../../../../../node_modules/ng2-toasty/style-default.css',
    '../../../../../node_modules/ng2-toasty/style-material.css'
  ],
  encapsulation: ViewEncapsulation.None
})
export class SuccessCashPayComponent implements OnInit {

  position = 'bottom-right';
  title: string;
  msg: string;
  showClose = true;
  theme = 'bootstrap';
  type = 'default';
  closeOther = false;
  objHistory: any = {}
  dblTotal = 0
  
  
  public category = {};


  constructor(        
    private router: Router,
    private services: ServiceApi,
  ) {
    this.router.getCurrentNavigation().extras.state
  }

  gotoSubcategory(value) {
    this.category = value
    this.router.navigateByUrl('customersubcategories', { state: this.category });
  }

  ngOnInit() {
    this.objHistory = Object.assign({}, history.state);
    this.dblTotal = this.services.getStorage("dblTotalPay")
  }
}
