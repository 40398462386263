export class ProductBE {
    constructor(){
    }
    id: string;
    subCategoryId: string;
    brandId: string;
    name: string;
    codeISO3: string;
    measuresIds: string[]; 
    color: string;
    price: number;
    urlImage: string;
    stock: number;
    discount: number;
    characteristics: string;
    description:string;
    providerId: string;
    isFavorite: boolean;
    isEnabled: boolean;
}