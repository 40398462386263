export class ProductQuotationCustomerBE {
    constructor() {
    }
    id: number;
    number: string;
    userId: string;
    productId: string;
    quantity: number;
    measureId: string;
    brandId: string;
    deliveryTimeId: string;
    height: number;
    width: number;
    length: number;
}