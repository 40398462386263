import { BrowserModule } from '@angular/platform-browser';
import {APP_INITIALIZER, NgModule, NO_ERRORS_SCHEMA} from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { ToastyConfig, ToastyModule, ToastyService } from 'ng2-toasty';
import { AppComponent } from './app.component';
import { AdminComponent } from './layout/admin/admin.component';
import { BreadcrumbsComponent } from './layout/admin/breadcrumbs/breadcrumbs.component';
import { TitleComponent } from './layout/admin/title/title.component';
import { AuthComponent } from './layout/auth/auth.component';
import {SharedModule} from './shared/shared.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { AppConfig } from './app.config';
import { ServiceApi } from './shared/services/ServiceApi';
import { HttpClientModule } from '@angular/common/http';
import { DataTablesModule } from 'angular-datatables';
import { BasicLoginComponent } from './pages/auth/login/basic-login/basic-login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BasicLoginModule } from './pages/auth/login/basic-login/basic-login.module';
import { ModalModule } from 'ngx-bootstrap/modal';
import { OrdersComponent } from './pages/navigation/orders/orders.component';
import { CustomersComponent } from './pages/navigation/customers/customers.component';
import { ProductsComponent } from './pages/navigation/products/products.component';
import { ProvidersComponent } from './pages/navigation/providers/providers.component';
import { UsersComponent } from './pages/settings/users/users.component';
import { EmailComponent } from './pages/settings/email/email.component';
import { NotificationsComponent } from './pages/settings/notifications/notifications.component';
import { PricesProvidersComponent } from './pages/navigation/pricesproviders/pricesproviders.component';
import { ProfileComponent } from './pages/user/profile/profile.component';
import { ConfigComponent } from './pages/navigation/config/config.component';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { CKEditorModule } from 'ng2-ckeditor';
import { QuotesProvidersComponent } from './pages/navigation/quotesproviders/quotesproviders.component';
import { PaymentmethodsProvidersComponent } from './pages/navigation/paymentmethodsproviders/paymentmethodsproviders.component';
import { ImageUploadModule } from 'angular2-image-upload';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ServicesProvidersComponent } from './pages/navigation/servicesproviders/servicesproviders.component';
import { QuotesProviderServicesComponent } from './pages/navigation/quotesproviderservices/quotesproviderservices.component';
import { UploadServicesFilesComponent } from './pages/auth/login/upload-services-files/upload-services-files.component';
import { PinchZoomModule } from 'ngx-pinch-zoom';
import { NumbersOnly } from './shared/directives/NumbersOnly';
import { ConfirmationComponent } from './Epayco/confirmation.component';
import { PaymentsComponent } from './pages/navigation/payments/payments.component';
//CUSTOMER
import { ProductsCusotmerComponent } from './pages/navigation_customer/products_customer/products_customer.component';
import { ServicesCustomerComponent } from './pages/navigation_customer/services_customer/services_customer.component';
import { ShoppingCustomerComponent } from './pages/navigation_customer/shopping_customer/shopping_customer.component';
import { FavoriteCustomerComponent } from './pages/navigation_customer/favorite_customer/favorite_customer.component';
import { PaymentsProviderComponent } from './pages/navigation_provider/payments_provider/payments_provider.component';
import { ProductsProviderComponent } from './pages/navigation_provider/products_provider/products_provider.component';
import { SaleshistoryProviderComponent } from './pages/navigation_provider/saleshistory_provider/saleshistory_provider.component';

import { SubcategoriesCustomerComponent } from './pages/navigation_customer/subcategories_customer/subcategories_customer.component';
import { CategoriesCusotmerComponent } from './pages/navigation_customer/categories_customer/categories_customer.component';
import { ServicesProviderComponent } from './pages/navigation_provider/services_provider/services_provider.component';
import { QuotationProductCusotmerComponent } from './pages/navigation_customer/quotation_product_customer/quotation_product_customer.component';
import { SaleshistoryProviderDetailComponent } from './pages/navigation_provider/saleshistory_provider_detail/saleshistory_provider_detail.component';
import { ShoppingCustomerDetailComponent } from './pages/navigation_customer/shopping_customer_detail/shopping_customer_detail.component';
import { SuccessQuotationProductCustomerComponent } from './pages/navigation_customer/success_quotation_product_customer/success_quotation_product_customer.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { CreatePurchaseCustomerComponent } from './pages/navigation_customer/create_purchase_customer/create_purchase_customer.component';
import { SuccessPurchaseCustomerComponent } from './pages/navigation_customer/success_purchase_customer/success_purchase_customer.component';
import { SignUpComponent } from './pages/auth/login/sign-up/signup.component';
import { VerifyCodeComponent } from './pages/auth/login/verifyCode/verifyCode.component';
import { RatingProviderComponent } from './pages/navigation_provider/rating_provider/rating_provider.component';
import { SuccessRatingShopProviderComponent } from './pages/navigation_provider/success_rating_shop_provider/success_rating_shop_provider.component';
import { SignupPaymentMethodsComponent } from './pages/auth/login/sign-up/signup_payment_methods/signup_payment_methods.component';
import { SignupUploadFilesComponent } from './pages/auth/login/sign-up/signup_uploadfiles/signup_uploadfiles.component';
import { CreditCardComponent } from './pages/navigation_provider/payment_type_provider/credit_card/credit_card.component';
import { CashProviderComponent } from './pages/navigation_provider/payment_type_provider/cash_provider/cash_provider.component';
import { RestorePasswordComponent } from './pages/auth/login/restore_password/restore_password.component';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { FileUploadModule } from 'ng2-file-upload';
import { ChangePasswordComponent } from './pages/auth/login/change_password/change_password.component';
import { ngxLoadingAnimationTypes, NgxLoadingModule } from 'ngx-loading';
import { RatingCustomerComponent } from './pages/navigation_customer/rating_customer/rating_customer.component';
import { NoPaymentComponent } from './pages/navigation_provider/no_payment/no_payment.component';
import { SuccessCashPayComponent } from './pages/navigation_provider/success_cash_pay/success_cash_pay.component';
import { PaymentmethodsComponent } from './pages/settings/setup/paymentmethods/paymentmethods.component';


@NgModule({
  declarations: [
    AppComponent,
    AdminComponent,
   // BasicLoginComponent,
    BreadcrumbsComponent,
    SignUpComponent,
    VerifyCodeComponent,
    ChangePasswordComponent,
    RestorePasswordComponent,
    NumbersOnly,
    TitleComponent,
    AuthComponent,  
    OrdersComponent,
    CustomersComponent,
    ProductsComponent,
    ProvidersComponent,
    UsersComponent,
    EmailComponent,
    NotificationsComponent,
    PricesProvidersComponent,    
    ProfileComponent,
    ConfigComponent,
    QuotesProvidersComponent,
    PaymentmethodsProvidersComponent,
    ServicesProvidersComponent,
    QuotesProviderServicesComponent,
    ConfirmationComponent,
    PaymentsComponent,
    UploadServicesFilesComponent ,
    //START CUSTOMER
    ProductsCusotmerComponent,       
    ServicesCustomerComponent,
    ShoppingCustomerComponent,
    FavoriteCustomerComponent, 
    SubcategoriesCustomerComponent, 
    CategoriesCusotmerComponent,  
    QuotationProductCusotmerComponent, 
    ShoppingCustomerDetailComponent,
    SuccessQuotationProductCustomerComponent,
    SuccessPurchaseCustomerComponent,   
    RatingCustomerComponent,
    //END CUSTOMER
    //START PROVIDER
    PaymentsProviderComponent,
    PaymentmethodsComponent,
    ProductsProviderComponent,
    SaleshistoryProviderComponent,
    ServicesProviderComponent,
    SaleshistoryProviderDetailComponent,
    CreatePurchaseCustomerComponent,
    RatingProviderComponent,
    SuccessRatingShopProviderComponent,
    SignupPaymentMethodsComponent,
    SignupUploadFilesComponent,
    CreditCardComponent,
    CashProviderComponent,
    NoPaymentComponent,
    SuccessCashPayComponent
    //END PROVIDER
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    SharedModule,
    FormsModule,    
    ReactiveFormsModule,
    HttpClientModule,
    DataTablesModule, 
    ToastyModule.forRoot(),    
    NgxLoadingModule.forRoot({
      animationType: ngxLoadingAnimationTypes.circleSwish,
      backdropBackgroundColour: 'rgba(0,0,0,0.5)',
      backdropBorderRadius: '4px',
      fullScreenBackdrop: true,
      primaryColour: '#ffffff',
      secondaryColour: '#ffffff',
      tertiaryColour: '#ffffff'
    }),
    BasicLoginModule, ModalModule.forRoot(),
    CKEditorModule,
    PinchZoomModule,
    ImageUploadModule.forRoot(),   
    NgbModule,
    InfiniteScrollModule,
    GooglePlaceModule,
    FileUploadModule
  ],
  schemas: [ NO_ERRORS_SCHEMA ],
  providers: [
    {provide: LocationStrategy, useClass: HashLocationStrategy},
    ServiceApi,
    AppConfig,
    {
      provide: APP_INITIALIZER,
      useFactory: (config: AppConfig) => () => config.Load(),
      deps: [AppConfig],
      multi: true
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
