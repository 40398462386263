export class SendOrderRatingBE {
    constructor(){
    }
    id: string;
    userId: string;
    userName: string = "";  
    orderId: string;
    rate: number;
    comment: string;
    isClient: boolean;
}