import { Component, OnInit, ViewEncapsulation, TemplateRef } from '@angular/core';
import { ToastData, ToastOptions, ToastyService } from 'ng2-toasty';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { UserBE } from '../../../Entity/UserBE'
import { ServiceApi } from '../../../shared/services/ServiceApi';
import { Router } from '@angular/router';
import { FileHolder } from 'angular2-image-upload';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';


@Component({
  selector: 'app-providers',
  templateUrl: './providers.component.html',
  styleUrls: [
    '../../../../../node_modules/ng2-toasty/style-bootstrap.css',
    '../../../../../node_modules/ng2-toasty/style-default.css',
    '../../../../../node_modules/ng2-toasty/style-material.css'
  ],
  encapsulation: ViewEncapsulation.None
})
export class ProvidersComponent implements OnInit {  

  position = 'bottom-right';
  title: string;
  msg: string;
  showClose = true;
  theme = 'bootstrap';
  type = 'default';
  closeOther = false;
  objProvider = new UserBE();
  objResponseProviders: any = [];
  lstProviders: any = [];
  dtOptions: DataTables.Settings = {};
  modalRef: BsModalRef;
  dtTrigger: Subject<any> = new Subject<any>();
  dtElement: DataTableDirective;
  isDtInitialized: boolean = false;
  loading: boolean = false;

  public provider = {};

  constructor(
    private toastyService: ToastyService,
    private services: ServiceApi,
    private modalService: BsModalService,
    private router: Router
  ) {

  }

  PaymentmethodsProvider(value) {    
    this.provider = value
    this.router.navigateByUrl('/paymentmethodsproviders', { state: this.provider });
  }

  ServicesProvider(value) {    
    this.provider = value
    this.router.navigateByUrl('/servicesproviders', { state: this.provider });
  }

  SaveProvider() {    
    this.objProvider.profileId = "0614E506-4447-4FD1-81F3-08D8820C3640";
    console.log(this.objProvider);
    this.services.executeMethod(this.services.security, "User", this.objProvider, (x) => {
      this.objResponseProviders = x      
      if (this.objResponseProviders.status == true) {
        this.modalRef.hide();
        setTimeout(() => {                          
          this.addToast({ title: 'Registro Exitos!', msg: '', showClose: true, timeout: 1500, theme: 'material', position: 'center-center', type: 'success' });
        }, 800);        
        this.ngOnInit();
      }
      this.objProvider = new UserBE();
    });
  }

  DeleteProvider(category) {
    if(!confirm("Estás seguro que deseas eliminar este item?")){
      return false;
    }
    this.services.executeMethodDelete(this.services.security, "User/", category, (x) => {      
      this.lstProviders = x
      if (this.lstProviders.status == true) {
        this.addToast({ title: 'Registro Eliminado!', msg: '', showClose: true, timeout: 1500, theme: 'material', position: 'center-center', type: 'success' });
        this.ngOnInit();
      }
      this.objProvider = new UserBE();
    });
  }

  UpdateProvider() {
    debugger;
    this.services.executeMethodPut(this.services.security, "User", this.objProvider, (x) => {
      this.objResponseProviders = x     
      if (this.objResponseProviders.status == true) {
        this.modalRef.hide();
        setTimeout(() => {                          
          this.addToast({ title: 'Se Actualizo Con Exito!', msg: '', showClose: true, timeout: 1500, theme: 'material', position: 'center-center', type: 'success' });
        }, 800);         
        this.ngOnInit();
      }      
    });
  }

  ngOnInit() {
    this.loading = true
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 100
    };

    this.services.executeMethodGet(this.services.security, "User?Search=0614E506-4447-4FD1-81F3-08D8820C3640", (x) => {      
      this.loading = false
      this.lstProviders = x.information.items;      
      if (this.isDtInitialized) {
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
          this.dtTrigger.next();
        });
      } else {
        this.isDtInitialized = true
        this.dtTrigger.next();
      }
    });
  }

  ModalEditProvider(template, provider) {
    this.modalRef = this.modalService.show(template,{
      class: 'modal-lg modal-dialog-centered',
    });
    this.objProvider = provider
    this.objProvider.password = "";
  }

  ModalAddProvider(template) {
    this.objProvider = new UserBE();
    this.modalRef = this.modalService.show(template,{
      class: 'modal-lg modal-dialog-centered',
    });
  }

  ModalImage1(template, user) {
    this.modalRef = this.modalService.show(template, {
      class: 'modal-lg modal-dialog-centered',
    });
    this.objProvider = user;
  }

  ModalImage2(template, user) {
    this.modalRef = this.modalService.show(template, {
      class: 'modal-lg modal-dialog-centered',
    });
    this.objProvider = user;
  }

  ModalImage3(template, user) {
    this.modalRef = this.modalService.show(template, {
      class: 'modal-lg modal-dialog-centered',
    });
    this.objProvider = user;    
  }

  ModalImage4(template, user) {
    this.modalRef = this.modalService.show(template, {
      class: 'modal-lg modal-dialog-centered',
    });
    this.objProvider = user;    
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

  handleUpload(event, value) {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      if(value == 1){
        this.objProvider.urlImage = reader.result.toString();
      }
      if(value == 2){
        this.objProvider.urlImageRut = reader.result.toString();
      }
      if(value == 3){
        this.objProvider.urlImageCedulaFrontal = reader.result.toString();
      }      
      if(value == 4){
        this.objProvider.urlImageCedulaRespaldo = reader.result.toString();
      }      
    };
  }

  onUploadFinished(file: FileHolder) {
    console.log(file);
  }
  
  onRemoved(file: FileHolder) {
    console.log(file);
  }
  
  onUploadStateChanged(state: boolean) {
    console.log(state);
  }

  addToast(options) {
    if (options.closeOther) {
      this.toastyService.clearAll();
    }
    this.position = options.position ? options.position : this.position;
    const toastOptions: ToastOptions = {
      title: options.title,
      msg: options.msg,
      showClose: options.showClose,
      timeout: options.timeout,
      theme: options.theme,
      onAdd: (toast: ToastData) => {
        /* added */
      },
      onRemove: (toast: ToastData) => {
        /* removed */
      }
    };

    switch (options.type) {
      case 'default': this.toastyService.default(toastOptions); break;
      case 'info': this.toastyService.info(toastOptions); break;
      case 'success': this.toastyService.success(toastOptions); break;
      case 'wait': this.toastyService.wait(toastOptions); break;
      case 'error': this.toastyService.error(toastOptions); break;
      case 'warning': this.toastyService.warning(toastOptions); break;
    }
  }
}
