import { Component, OnInit } from '@angular/core';
import { ServiceApi } from '../../../../../shared/services/ServiceApi';
import { UserLoginBE } from '../../../../../Entity/UserLoginBE'
import { Router } from '@angular/router';
import { UserBE } from '../../../../../Entity/UserBE';

@Component({
  selector: 'app-signup_uploadfiles',
  templateUrl: './signup_uploadfiles.component.html'
})
export class SignupUploadFilesComponent implements OnInit {


  public loading = false;
  objUserLogin = new UserLoginBE();
  public identity;
  public _user;
  data: any = [];
  objUser: UserBE
  strError = ""
  strRut = "Seleccionar Archivo"
  strFront = "Seleccionar Archivo"
  strBack = "Seleccionar Archivo"

  constructor(
    private services: ServiceApi,
    private router: Router
  ) { }

  Next() {
    if (this.objUser.urlImageRut == undefined) {
      this.strError = "El RUT es obligatorio"
    } else if (this.objUser.urlImageCedulaFrontal == undefined) {
      this.strError = "La identificacion frontal es obligatoria"
    } else if (this.objUser.urlImageCedulaRespaldo == undefined) {
      this.strError = "La identificacion posterior es obligatoria"
    } else {
      this.loading = true
      this.services.executeMethodWithOut(this.services.orders, "User/UploadFiles", this.objUser, (x) => {
        this.loading = false
        if (x.status) {
          this.router.navigate(['./verifyCode']);
        }
      });

    }
  }

  ngOnInit() {
    document.querySelector('body').setAttribute('themebg-pattern', 'theme1');
    this.objUser = this.services.getStorage("tempSignUp")
  }

  handleUpload(event, value) {

    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      if (value == 1) {
        this.objUser.urlImageRut = reader.result.toString();
        this.strRut = file.name
      } else if (value == 2) {
        this.objUser.urlImageCedulaFrontal = reader.result.toString();
        this.strFront = file.name
      } else if (value == 3) {
        this.objUser.urlImageCedulaRespaldo = reader.result.toString();
        this.strBack = file.name
      }

    };
  }
}
