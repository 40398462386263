import { Component, OnInit, ViewEncapsulation, TemplateRef } from '@angular/core';
import { ToastData, ToastOptions, ToastyService } from 'ng2-toasty';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { UserBE } from '../../../Entity/UserBE'
import { ServiceApi } from '../../../shared/services/ServiceApi';
import { Router } from '@angular/router';
import { FileHolder } from 'angular2-image-upload';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';

@Component({
  selector: 'app-saleshistory_provider_detail',
  templateUrl: './saleshistory_provider_detail.component.html',
  styleUrls: [
    '../../../../../node_modules/ng2-toasty/style-bootstrap.css',
    '../../../../../node_modules/ng2-toasty/style-default.css',
    '../../../../../node_modules/ng2-toasty/style-material.css'
  ],
  encapsulation: ViewEncapsulation.None
})
export class SaleshistoryProviderDetailComponent implements OnInit {  

  position = 'bottom-right';
  title: string;
  msg: string;
  showClose = true;
  theme = 'bootstrap';
  type = 'default';
  closeOther = false;

  objDetailOrderProvider: any = {};
  lstCategories: any = [];
  public category = {};
  public provider = {};

  constructor(
    private toastyService: ToastyService,
    private services: ServiceApi,
    private modalService: BsModalService,
    private router: Router
  ) {

  }

  gotoQuotesProvidersServices(value) {
    this.provider = value
    this.router.navigateByUrl('/saleshistoryproviderdetail', { state: this.provider });
  }

  ngOnInit() {
    this.services.executeMethodGet(this.services.enviroment, "Order/idOrderDetail=e73aa686-066a-49ae-270f-08d90017071e", (x) => {
      this.objDetailOrderProvider = x.information;     
      console.log(this.objDetailOrderProvider)
    });
  }


  addToast(options) {
    if (options.closeOther) {
      this.toastyService.clearAll();
    }
    this.position = options.position ? options.position : this.position;
    const toastOptions: ToastOptions = {
      title: options.title,
      msg: options.msg,
      showClose: options.showClose,
      timeout: options.timeout,
      theme: options.theme,
      onAdd: (toast: ToastData) => {
        /* added */
      },
      onRemove: (toast: ToastData) => {
        /* removed */
      }
    };

    switch (options.type) {
      case 'default': this.toastyService.default(toastOptions); break;
      case 'info': this.toastyService.info(toastOptions); break;
      case 'success': this.toastyService.success(toastOptions); break;
      case 'wait': this.toastyService.wait(toastOptions); break;
      case 'error': this.toastyService.error(toastOptions); break;
      case 'warning': this.toastyService.warning(toastOptions); break;
    }
  }
}
