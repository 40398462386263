export class ServiceQuotationCustomerBE {
    constructor() {
    }
    id: string;
    userId: string;
    serviceTypeId: string;
    number: string;
    material: string;
    thickness: string;
    cutTypeCNC: string;
    units: string;
    incudeMaterial: boolean;
    quantity: number;
    observations: string;
    deliveryMethod: string;
    urlFile1: string;
    urlFile2: string;
    urlFile3: string;
    urlFile4: string;
    urlFile5: string;
    isEnabled: boolean;
    justDescription: boolean;
    moreImages: boolean;
}