import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {ToastData, ToastOptions, ToastyService} from 'ng2-toasty';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ServiceApi } from '../../../shared/services/ServiceApi';
import {NgbRatingConfig} from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';


@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: [    
    '../../../../../node_modules/ng2-toasty/style-bootstrap.css',
    '../../../../../node_modules/ng2-toasty/style-default.css',
    '../../../../../node_modules/ng2-toasty/style-material.css'
  ],
  styles: [`
  .star {
    font-size: 3rem;
    color: #b0c4de;
  }
  .filled {
    color: #0A0909;
  }
  .bad {
    color: #deb0b0;
  }
  .filled.bad {
    color: #ff1e1e;
  }
`],
  encapsulation: ViewEncapsulation.None
})
export class OrdersComponent implements OnInit {
  position = 'bottom-right';
  title: string;
  msg: string;
  showClose = true;
  theme = 'bootstrap';
  type = 'default';
  closeOther = false;
  objDetailOrder: any = {};
  lstRatingOrder: any = [];
  lstStatusOrder: any = [];
  lstOrders: any = [];
  dtOptions: DataTables.Settings = {};
  modalRef: BsModalRef;
  currentRate: number;
  dtTrigger: Subject<any> = new Subject<any>();
  dtElement: DataTableDirective;
  isDtInitialized: boolean = false;
  loading: boolean = false;

  constructor(
    private toastyService: ToastyService,
    private services: ServiceApi,
    private modalService: BsModalService,
    config: NgbRatingConfig
    ) {
      config.max = 5;
      config.readonly = true;
    }

  ngOnInit() {
    this.loading = true
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 100
    };

    this.services.executeMethodGet(this.services.orders, "Order", (x) => {    
      this.loading = false  
      this.lstOrders = x.information.items;      
      if (this.isDtInitialized) {
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
          this.dtTrigger.next();
        });
      } else {
        this.isDtInitialized = true
        this.dtTrigger.next();
      }
    });    
  }

  GetOrder(orderId) {
    this.services.executeMethodGet(this.services.catalog, "Order/idOrderDetail=" + orderId, (x) => {     
      this.objDetailOrder = x.information;                     
    });    
  }

  GetOrderRating(orderId) {    
    this.services.executeMethodGet(this.services.catalog, "OrderRating?Search=" + orderId, (x) => {     
      this.lstRatingOrder = x.information.items;     
    });    
  }

  GetStatusOrder(orderId) {
    this.services.executeMethodGet(this.services.catalog, "Order/idOrderStatus=" + orderId, (x) => {     
      this.lstStatusOrder = x.information.items;     
    });    
  }

  ModalDetailOrder(template, orderId) {
    this.modalRef = this.modalService.show(template, {
      class: 'modal-lg modal-dialog-centered',
    });
    this.GetOrder(orderId)
  }

  ModalStatusOrder(template, orderId) {
    this.modalRef = this.modalService.show(template, {
      class: 'modal-lg modal-dialog-centered',
    });   
    this.GetStatusOrder(orderId) 
  }
  
  ModalRatingOrder(template, orderId) {
    this.modalRef = this.modalService.show(template, {
      class: 'modal-dialog-centered',
    });   
    this.GetOrderRating(orderId); 
  }

  addToast(options) {
    if (options.closeOther) {
      this.toastyService.clearAll();
    }
    this.position = options.position ? options.position : this.position;
    const toastOptions: ToastOptions = {
      title: options.title,
      msg: options.msg,
      showClose: options.showClose,
      timeout: options.timeout,
      theme: options.theme,
      onAdd: (toast: ToastData) => {
        /* added */
      },
      onRemove: (toast: ToastData) => {
        /* removed */
      }
    };

    switch (options.type) {
      case 'default': this.toastyService.default(toastOptions); break;
      case 'info': this.toastyService.info(toastOptions); break;
      case 'success': this.toastyService.success(toastOptions); break;
      case 'wait': this.toastyService.wait(toastOptions); break;
      case 'error': this.toastyService.error(toastOptions); break;
      case 'warning': this.toastyService.warning(toastOptions); break;
    }
  }
}
