import { Injectable } from '@angular/core';



export interface BadgeItem {
  type: string;
  value: string;
}

export interface ChildrenItems {
  state: string;
  target?: boolean;
  name: string;
  type?: string;
  children?: ChildrenItems[];
}

export interface MainMenuItems {
  state: string;
  short_label?: string;
  main_state?: string;
  target?: boolean;
  name: string;
  type: string;
  icon: string;
  badge?: BadgeItem[];
  children?: ChildrenItems[];
}

export interface Menu {
  label: string;
  main: MainMenuItems[];
}


const MENUITEMSUSER = [
  {
    label: 'Navegación',
    main: [
      {
        state: 'cotiza',
        short_label: 'B',
        name: 'Cotizaciones',
        type: 'sub',
        icon: 'ti-layers-alt',
        children: [
          {
            state: 'productsquotes',
            name: 'Productos',
          },
          {
            state: 'servicesquotes',
            name: 'Servicios',
          },
        ]
      },
      {
        state: 'products',
        short_label: 'D',
        name: 'Productos',
        type: 'link',
        icon: 'ti-layout-accordion-list'
      },
      {
        state: 'orders',
        short_label: 'D',
        name: 'Ordenes',
        type: 'link',
        icon: 'ti-layers-alt'
      },
      {
        state: 'providers',
        short_label: 'D',
        name: 'Proveedores',
        type: 'link',
        icon: 'ti-id-badge'
      },
      {
        state: 'customers',
        short_label: 'D',
        name: 'Clientes',
        type: 'link',
        icon: 'ti-user'
      },
      {
        state: 'reports',
        short_label: 'B',
        name: 'Reportes',
        type: 'sub',
        icon: 'ti-layers-alt',
        children: [
          {
            state: 'payments',
            name: 'Pagos',
          },
        ]
      },
    ],
  },
  {
    label: 'Configuracion',
    main: [
      {
        state: 'setup',
        short_label: 'B',
        name: 'Settings',
        type: 'sub',
        icon: 'ti-settings',
        children: [
          {
            state: 'topcategories',
            name: 'Categorías Top',
          },
          {
            state: 'categories',
            name: 'Categorias',
          },
          {
            state: 'serviceconfig',
            name: 'Servicios',
          },
          {
            state: 'countries',
            name: 'Origen'
          },
          {
            state: 'trademark',
            name: 'Marcas'
          },
          {
            state: 'delivertime',
            name: 'Tiempos de entrega'
          },
          /* {
            state: 'paymentmethod',
            name: 'Metodos de Pago'
          },*/
          {
            state: 'promotion',
            name: 'Promocion'
          },
          {
            state: 'measurements',
            name: 'Medidas'
          }
        ]
      },
      {
        state: 'users',
        short_label: 'D',
        name: 'Usuarios',
        type: 'link',
        icon: 'ti-user'
      },
      {
        state: 'email',
        short_label: 'B',
        name: 'Correo',
        type: 'link',
        icon: 'ti-email'
      },
      {
        state: 'notifications',
        short_label: 'B',
        name: 'Notificaciones',
        type: 'link',
        icon: 'ti-email'
      }
    ]
  },
];


const MENUITEMSCUSTOMER = [

  {
    label: 'Navegación',
    main: [
      {
        state: 'customerfavorite',
        short_label: 'B',
        name: 'Favoritos',
        type: 'link',
        icon: 'favorites.png'
      },
      {
        state: 'customercategories',
        short_label: 'D',
        name: 'Productos',
        type: 'link',
        icon: 'productivity.png'
      },
      {
        state: 'customerservices',
        short_label: 'D',
        name: 'Servicios',
        type: 'link',
        icon: 'services.png'
      },
      {
        state: 'quotescustomer',
        short_label: 'B',
        name: 'Cotizaciones',
        type: 'sub',
        icon: 'Cotizaciones-activas_bottom.png',
        children: [
          {
            state: 'productsquotescustomer',
            name: 'Productos',
          },
          {
            state: 'servicesquotescustomer',
            name: 'Servicios',
          },
        ]
      },
      {
        state: 'dashboard',
        short_label: 'B',
        name: 'Analítica',
        type: 'link',
        icon: 'analitic.png'
      },
      {
        state: 'customershopping',
        short_label: 'D',
        name: 'Mis Compras',
        type: 'link',
        icon: 'mis-compras.png'
      },


    ]
  },
];

const MENUITEMSPROVIDER = [
  {
    label: 'Navegación',
    main: [
      {
        state: 'providerproducts',
        short_label: 'D',
        name: 'Productos',
        type: 'link',
        icon: 'productivity.png'
      },
      {
        state: 'providerservices',
        short_label: 'D',
        name: 'Servicios',
        type: 'link',
        icon: 'services.png'
      },
      {
        state: 'ordersprovider',
        short_label: 'B',
        name: 'Pedidos',
        type: 'sub',
        icon: 'mis-compras.png',
        children: [
          {
            state: 'acceptedprovider',
            name: 'Aceptados',
          },
          {
            state: 'notacceptedprovider',
            name: 'Sin Aceptar',
          },
        ]
      },
      {
        state: 'quotesprovider',
        short_label: 'B',
        name: 'Cotizaciones',
        type: 'sub',
        icon: 'Cotizaciones-activas_bottom.png',
        children: [
          {
            state: 'productsquotesprovider',
            name: 'Productos',
          },
          {
            state: 'servicesquotesprovider',
            name: 'Servicios',
          },
        ]
      },
      {
        state: 'dashboard',
        short_label: 'B',
        name: 'Analítica',
        type: 'link',
        icon: 'analitic.png'
      },
      {
        state: 'saleshistoryprovider',
        short_label: 'D',
        name: 'Historial de ventas',
        type: 'link',
        icon: 'history.png'
      },
      {
        state: 'providerpayment',
        short_label: 'D',
        name: 'Pagos',
        type: 'link',
        icon: 'payments.png'
      }
    ],
  }
];

@Injectable()
export class MenuItems {

  getAll(): Menu[] {

    var profile = localStorage.getItem('profile');
    if (profile == '1DC3CE2E-9A93-4574-9481-D9C8BDD79867') {
      return MENUITEMSUSER;
    } else if (profile == '0614E506-5557-4FD1-81F3-08D8820C3640') {
      return MENUITEMSCUSTOMER;
    } else if (profile == '0614E506-4447-4FD1-81F3-08D8820C3640') {
      return MENUITEMSPROVIDER;
    }
  }

  /*add(menu: Menu) {
    MENUITEMS.push(menu);
  }*/
}
