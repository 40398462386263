import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { ToastData, ToastOptions, ToastyService } from 'ng2-toasty';
import { UserBE } from '../../../Entity/UserBE';
import { ServiceApi } from '../../../shared/services/ServiceApi';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { UserAddressBE } from '../../../Entity/UserAddressBE';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: [
    '../../../../../node_modules/ng2-toasty/style-bootstrap.css',
    '../../../../../node_modules/ng2-toasty/style-default.css',
    '../../../../../node_modules/ng2-toasty/style-material.css'
  ],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('fadeInOutTranslate', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('400ms ease-in-out', style({ opacity: 1 }))
      ]),
      transition(':leave', [
        style({ transform: 'translate(0)' }),
        animate('400ms ease-in-out', style({ opacity: 0 }))
      ])
    ])
  ]
})
export class ProfileComponent implements OnInit {
  position = 'bottom-right';
  title: string;
  msg: string;
  showClose = true;
  theme = 'bootstrap';
  type = 'default';
  closeOther = false;
  objUser = new UserBE();
  public userId: string;
  public objFullUser: any = {}
  objResponseUser: any = [];
  objDelete: any = {}

  editProfile = true;
  isChangedAddress = false;
  editProfileIcon = 'icofont-edit';

  editAbout = true;
  editAboutIcon = 'icofont-edit';

  public basicContent: string;
  public _user;  
  


  public rowsOnPage = 10;
  public filterQuery = '';
  public sortBy = '';
  public sortOrder = 'desc';
  profitChartOption: any;
  lstPaymentMethods: any[] = []
  lstAllPaymentMethods: any[] = []
  modalRef: BsModalRef;
  objRequestAdd: any = {}
  lstAddresses: any = [];
  DataForm: FormGroup;
  strNewAddress = ""

  objUserAddress: UserAddressBE = new UserAddressBE();
  objUserAddressUpdate: UserAddressBE = new UserAddressBE();

  constructor(
    private toastyService: ToastyService,
    private services: ServiceApi,
    private modalService: BsModalService,
    private _formBuilder: FormBuilder,
  ) {

    this.DataForm = this._formBuilder.group({
      Address: ['', Validators.required],
      Neighborhood: ['', Validators.required],
      Notes: ['', Validators.required],
    });
  }

  UpdateUserProfile() {
    this.services.executeMethodPut(this.services.security, "User", this.objUser, (x) => {
      //console.log(x)
      this.objResponseUser = x   
      this._user = this.objResponseUser.information;   
      if (this.objResponseUser.status == true) {
        
        this.updateSesionImage(this._user.urlImage)
       

        if (this.objUserAddressUpdate.address != this.strNewAddress && this.isChangedAddress) {
          this.updateAddress()
        } else {
          this.addToast({ title: 'Se Actualizo Con Exito!', msg: '', showClose: true, timeout: 1500, theme: 'material', position: 'center-center', type: 'success' });
          this.ngOnInit();
          this.toggleEditProfile()
        }

      }
    });
  }

  updateSesionImage(strImage){
    $(".imgFullProfile").attr("src",strImage);
    var info = this.services.getStorage("userInfo")
    info.urlImage = strImage
    this.services.setStorage("userInfo", info)
  }


  deletePayment() {
    if(!confirm("Estás seguro que deseas eliminar este item?")){
      return false;
    }
    this.services.executeMethodDelete(this.services.security, "ProviderPaymentMethod/", this.objDelete.id, (x) => {
      this.modalRef.hide();
      this.loadPaymentMethods();
    });
  }

  deleteAddress() {
    if(!confirm("Estás seguro que deseas eliminar este item?")){
      return false;
    }
    this.services.executeMethodDelete(this.services.security, "UserAddress/", this.objDelete.id, (x) => {
      this.modalRef.hide();
      this.loadAddress();
    });
  }

  handleAddressChange(address: any, type) {
    if (type == 1) {
      this.objUserAddress.address = address.formatted_address
      this.objUserAddress.latitude = address.geometry.location.lat()
      this.objUserAddress.longitude = address.geometry.location.lng()
    } else {
      this.isChangedAddress = true
      this.objUserAddressUpdate.address = address.formatted_address
      this.objUserAddressUpdate.latitude = address.geometry.location.lat()
      this.objUserAddressUpdate.longitude = address.geometry.location.lng()
      this.objUserAddressUpdate.neighborhood = "Bogota"
    }
  }

  SaveAddress() {
    if (this.DataForm.valid) {
      this.objUserAddress.userId = this.objUser.id
      this.services.executeMethod(this.services.catalog, "UserAddress", this.objUserAddress, (x) => {
        this.modalRef.hide();
        this.loadAddress();
      });
    } else {
      this.services.validateAllFormFields(this.DataForm);
    }
  }

  ModalDelete(template, obj) {
    this.objDelete = obj
    this.modalRef = this.modalService.show(template, {
      class: 'modal-xs modal-dialog-centered',
    });
  }

  ModalAddAddress(template) {
    this.modalRef = this.modalService.show(template, {
      class: 'modal-xs modal-dialog-centered',
    });
  }

  ngOnInit() {
    this.objFullUser = this.services.getStorage("userInfo")
    this.userId = this.objFullUser.id;
    this.services.executeMethodGet(this.services.security, "User?Search=" + this.userId, (x) => {
      this.objUser = x.information.items[0];
      this.objUser.password = "";
    });

    this.loadPaymentMethods();
    this.loadAllPaymentMethods();
    this.loadAddress();
  }

  loadAddress() {
    this.services.executeMethodGet(this.services.enviroment, "UserAddress?Search=" + this.userId, (x) => {
      this.lstAddresses = x.information.items;
      if (this.lstAddresses.length > 0) {
        this.strNewAddress = this.lstAddresses[0].address
        this.objUserAddressUpdate.id = this.lstAddresses[0].id
        this.objUserAddressUpdate.address = this.lstAddresses[0].address
        this.objUserAddressUpdate.userId = this.objFullUser.id
        //console.log(this.lstAddresses)
      }
    });
  }

  loadAllPaymentMethods() {
    this.services.executeMethodGet(this.services.security, "PaymentMethod", (x) => {
      this.lstAllPaymentMethods = x.information.items;
      //console.log(this.lstAllPaymentMethods)
    });
  }

  updateAddress() {
    this.services.executeMethodPut(this.services.security, "UserAddress", this.objUserAddressUpdate, (x) => {
      if (x.status) {
        this.addToast({ title: 'Se Actualizo Con Exito!', msg: '', showClose: true, timeout: 1500, theme: 'material', position: 'center-center', type: 'success' });
        this.toggleEditProfile()
        this.ngOnInit();
      }
    });

  }


  loadPaymentMethods() {
    this.services.executeMethodGet(this.services.security, "ProviderPaymentMethod?Search=" + this.userId, (x) => {
      this.lstPaymentMethods = x.information.items;
    });
  }

  onChangePayment(value) {
    this.objRequestAdd.Id = value;
  }

  SavePaymentMethod() {
    var request: any = {}
    request.userId = this.userId
    request.paymentMethodId = this.objRequestAdd.Id;

    this.services.executeMethod(this.services.orders, "ProviderPaymentMethod", request, (x) => {
      if (x.status) {
        this.modalRef.hide();
        this.loadPaymentMethods();
      }
    });
  }


  ModalCreatePayment(template) {
    this.objRequestAdd = {};
    this.modalRef = this.modalService.show(template, {
      class: 'modal-dialog-centered',
    });
  }

  handleUpload(event) {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.objUser.urlImage = reader.result.toString();
    };
  }

  toggleEditProfile() {
    this.editProfileIcon = (this.editProfileIcon === 'icofont-close') ? 'icofont-edit' : 'icofont-close';
    this.editProfile = !this.editProfile;
  }

  toggleEditAbout() {
    this.editAboutIcon = (this.editAboutIcon === 'icofont-close') ? 'icofont-edit' : 'icofont-close';
    this.editAbout = !this.editAbout;
  }

  addToast(options) {
    if (options.closeOther) {
      this.toastyService.clearAll();
    }
    this.position = options.position ? options.position : this.position;
    const toastOptions: ToastOptions = {
      title: options.title,
      msg: options.msg,
      showClose: options.showClose,
      timeout: options.timeout,
      theme: options.theme,
      onAdd: (toast: ToastData) => {
        /* added */
      },
      onRemove: (toast: ToastData) => {
        /* removed */
      }
    };

    switch (options.type) {
      case 'default': this.toastyService.default(toastOptions); break;
      case 'info': this.toastyService.info(toastOptions); break;
      case 'success': this.toastyService.success(toastOptions); break;
      case 'wait': this.toastyService.wait(toastOptions); break;
      case 'error': this.toastyService.error(toastOptions); break;
      case 'warning': this.toastyService.warning(toastOptions); break;
    }
  }
}
