import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminComponent } from './layout/admin/admin.component';
import { AuthComponent } from './layout/auth/auth.component';
import { BasicLoginComponent } from './pages/auth/login/basic-login/basic-login.component';
import { ConfigComponent } from './pages/navigation/config/config.component';
import { CustomersComponent } from './pages/navigation/customers/customers.component';
import { OrdersComponent } from './pages/navigation/orders/orders.component';
import { PaymentmethodsProvidersComponent } from './pages/navigation/paymentmethodsproviders/paymentmethodsproviders.component';
import { PricesProvidersComponent } from './pages/navigation/pricesproviders/pricesproviders.component';
import { ProductsComponent } from './pages/navigation/products/products.component';
import { ProvidersComponent } from './pages/navigation/providers/providers.component';

import { QuotesProvidersComponent } from './pages/navigation/quotesproviders/quotesproviders.component';
import { EmailComponent } from './pages/settings/email/email.component';
import { UsersComponent } from './pages/settings/users/users.component';
import { ProfileComponent } from './pages/user/profile/profile.component';
import { NotificationsComponent } from './pages/settings/notifications/notifications.component';
import { ServicesProvidersComponent } from './pages/navigation/servicesproviders/servicesproviders.component';
import { QuotesProviderServicesComponent } from './pages/navigation/quotesproviderservices/quotesproviderservices.component';
import { UploadServicesFilesComponent } from './pages/auth/login/upload-services-files/upload-services-files.component';
import { ConfirmationComponent } from './Epayco/confirmation.component';
import { PaymentsComponent } from './pages/navigation/payments/payments.component';
//CUSTOMER
import { ProductsCusotmerComponent } from './pages/navigation_customer/products_customer/products_customer.component';
import { ServicesCustomerComponent } from './pages/navigation_customer/services_customer/services_customer.component';
import { ShoppingCustomerComponent } from './pages/navigation_customer/shopping_customer/shopping_customer.component';
import { FavoriteCustomerComponent } from './pages/navigation_customer/favorite_customer/favorite_customer.component';
//PROVIDER
import { PaymentsProviderComponent } from './pages/navigation_provider/payments_provider/payments_provider.component';
import { ProductsProviderComponent } from './pages/navigation_provider/products_provider/products_provider.component';
import { SaleshistoryProviderComponent } from './pages/navigation_provider/saleshistory_provider/saleshistory_provider.component';
import { ServicesProviderComponent } from './pages/navigation_provider/services_provider/services_provider.component';
import { SubcategoriesCustomerComponent } from './pages/navigation_customer/subcategories_customer/subcategories_customer.component';
import { CategoriesCusotmerComponent } from './pages/navigation_customer/categories_customer/categories_customer.component';
import { QuotationProductCusotmerComponent } from './pages/navigation_customer/quotation_product_customer/quotation_product_customer.component';
import { SaleshistoryProviderDetailComponent } from './pages/navigation_provider/saleshistory_provider_detail/saleshistory_provider_detail.component';
import { ShoppingCustomerDetailComponent } from './pages/navigation_customer/shopping_customer_detail/shopping_customer_detail.component';
import { SuccessQuotationProductCustomerComponent } from './pages/navigation_customer/success_quotation_product_customer/success_quotation_product_customer.component';
import { CreatePurchaseCustomerComponent } from './pages/navigation_customer/create_purchase_customer/create_purchase_customer.component';
import { SuccessPurchaseCustomerComponent } from './pages/navigation_customer/success_purchase_customer/success_purchase_customer.component';
import { SignUpComponent } from './pages/auth/login/sign-up/signup.component';
import { VerifyCodeComponent } from './pages/auth/login/verifyCode/verifyCode.component';
import { RatingProviderComponent } from './pages/navigation_provider/rating_provider/rating_provider.component';
import { SuccessRatingShopProviderComponent } from './pages/navigation_provider/success_rating_shop_provider/success_rating_shop_provider.component';
import { SignupPaymentMethodsComponent } from './pages/auth/login/sign-up/signup_payment_methods/signup_payment_methods.component';
import { SignupUploadFilesComponent } from './pages/auth/login/sign-up/signup_uploadfiles/signup_uploadfiles.component';
import { CreditCardComponent } from './pages/navigation_provider/payment_type_provider/credit_card/credit_card.component';
import { CashProviderComponent } from './pages/navigation_provider/payment_type_provider/cash_provider/cash_provider.component';
import { RestorePasswordComponent } from './pages/auth/login/restore_password/restore_password.component';
import { ChangePasswordComponent } from './pages/auth/login/change_password/change_password.component';
import { RatingCustomerComponent } from './pages/navigation_customer/rating_customer/rating_customer.component';
import { NoPaymentComponent } from './pages/navigation_provider/no_payment/no_payment.component';
import { SuccessCashPayComponent } from './pages/navigation_provider/success_cash_pay/success_cash_pay.component';

const routes: Routes = [

  {
    path: '',
    component: BasicLoginComponent,
    /*children: [
      {
        path: 'authentication',
        loadChildren: () => import('./pages/auth/auth.module').then(m => m.AuthModule)
      }
    ]*/
  },
  {
    path: 'login',
    component: BasicLoginComponent,
  },
  {
    path: 'signup/:parameter',
    component: SignUpComponent,
  },

  {
    path: 'signuppaymentmethods',
    component: SignupPaymentMethodsComponent,
  },
  {
    path: 'signupuploadfiles',
    component: SignupUploadFilesComponent,
  },
  {
    path: 'verifyCode',
    component: VerifyCodeComponent
  },
  {
    path: 'uploadfiles/:parameter',
    component: UploadServicesFilesComponent,
  },
  {
    path: 'Confirmation',
    component: ConfirmationComponent,
  },
  {
    path: 'restorepassword',
    component: RestorePasswordComponent,
  },
  {
    path: 'changepassword',
    component: ChangePasswordComponent
  },

  {
    path: '',
    component: AdminComponent,
    children: [
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full'
      }, {
        path: 'products',
        component: ProductsComponent,
        data: {
          breadcrumb: 'Productos',
          icon: 'icofont-chart-flow bg-danger',
          status: true
        }
      }, {
        path: 'orders',
        component: OrdersComponent,
        data: {
          breadcrumb: 'Ordenes',
          icon: 'ti-layers-alt bg-instagram',
          status: true
        }
      }, {
        path: 'quotesproviders',
        component: QuotesProvidersComponent,
        data: {
          breadcrumb: 'Cotizaciones Proveedores',
          icon: 'icofont-copy-black bg-instagram',
          status: true
        }
      },
      /* {
        path: 'reports/payments',
        component: PaymentsComponent,
        data: {
          breadcrumb: 'Pagos',
          icon: 'icofont-copy-black bg-instagram',
          status: true
        }
      },*/
      {
        path: 'quotesproviderservices',
        component: QuotesProviderServicesComponent,
        data: {
          breadcrumb: 'Cotizaciones Proveedores Servicios',
          icon: 'icofont-copy-black bg-instagram',
          status: true
        }
      }, {
        path: 'providers',
        component: ProvidersComponent,
        data: {
          breadcrumb: 'Proveedores',
          icon: 'icofont-card bg-c-green',
          status: true
        }
      },/* {
        path: 'paymentmethodsproviders',
        component: PaymentmethodsProvidersComponent,
        data: {
          breadcrumb: 'Metodos de Pago Proveedores',
          icon: 'icofont-card bg-c-green',
          status: true
        }
      }, */{
        path: 'customers',
        component: CustomersComponent,
        data: {
          breadcrumb: 'Clientes',
          icon: 'icofont-users-alt-2 bg-c-yellow',
          status: true
        }
      }, {
        path: 'pricesproviders',
        component: PricesProvidersComponent,
        data: {
          breadcrumb: 'Precios Proveedores',
          icon: 'icofont-architecture-alt bg-c-yellow',
          status: true
        }
      }, {
        path: 'servicesproviders',
        component: ServicesProvidersComponent,
        data: {
          breadcrumb: 'Servicios Proveedores',
          icon: 'icofont-architecture-alt bg-c-yellow',
          status: true
        }
      }, {
        path: 'creditcardproviders',
        component: CreditCardComponent,
        data: {
          breadcrumb: 'Tarjeta de credito',
          icon: 'payments.png',
          status: true
        }
      }, {
        path: 'cashprovider',
        component: CashProviderComponent,
        data: {
          breadcrumb: 'Efectivo',
          icon: 'payments.png',
          status: true
        }
      },

      //cotiza
      {
        path: 'cotiza',
        loadChildren: () => import('./pages/navigation/cotiza/cotiza.module').then(m => m.CotizaModule)
      },

      //Settings
      {
        path: 'setup',
        loadChildren: () => import('./pages/settings/setup/setup.module').then(m => m.SetupModule)
      }, {
        path: 'email',
        component: EmailComponent,
        data: {
          breadcrumb: 'Email',
          icon: 'icofont-email bg-c-green',
          status: true
        }
      }, {
        path: 'notifications',
        component: NotificationsComponent,
        data: {
          breadcrumb: 'Notificaciones',
          icon: 'icofont-email bg-c-green',
          status: true
        }
      }, {
        path: 'users',
        component: UsersComponent,
        data: {
          breadcrumb: 'Usuarios',
          icon: 'icofont-user-suited bg-c-yellow',
          status: true
        }
      }, {
        path: 'profile',
        component: ProfileComponent,
        data: {
          breadcrumb: 'Perfil de Usuario',
          icon: 'user-profile-icon.png',
          status: true
        }
      }, {
        path: 'config',
        component: ConfigComponent,
        data: {
          breadcrumb: 'Configuracion del Sistema',
          icon: 'icofont-justify-all bg-c-green',
          status: true
        }
      },

      //CUSTOMER
      {
        path: 'customercategories',
        component: CategoriesCusotmerComponent,
        data: {
          breadcrumb: 'Categorias',
          icon: 'productivity.png',
          status: true
        }
      }, {
        path: 'customersubcategories',
        component: SubcategoriesCustomerComponent,
        data: {
          breadcrumb: 'Subcategorias Clientes',
          icon: 'productivity.png',
          status: true
        }
      }, {
        path: 'customerproductquotation',
        component: QuotationProductCusotmerComponent,
        data: {
          breadcrumb: 'Cotización Producto',
          icon: 'productivity.png',
          status: true
        }
      }, {
        path: 'customerproducts',
        component: ProductsCusotmerComponent,
        data: {
          breadcrumb: 'Productos Clientes',
          icon: 'productivity.png',
          status: true
        }
      }, {
        path: 'customerservices',
        component: ServicesCustomerComponent,
        data: {
          breadcrumb: 'Servicios Clientes',
          icon: 'services.png',
          status: true
        }
      },
      {
        path: 'quotescustomer',
        loadChildren: () => import('./pages/navigation_customer/quotes_customer/quotes_customer.module').then(m => m.QuotesCustomerModule)
      },
      {
        path: 'customershopping',
        component: ShoppingCustomerComponent,
        data: {
          breadcrumb: 'Mis Compras',
          icon: 'mis-compras.png',
          status: true
        }
      },
      {
        path: 'customershoppingdetail',
        component: ShoppingCustomerDetailComponent,
        data: {
          breadcrumb: 'Detalle mis Compras',
          icon: 'mis-compras.png',
          status: true
        }
      }, {
        path: 'customerfavorite',
        component: FavoriteCustomerComponent,
        data: {
          breadcrumb: 'Favoritos',
          icon: 'favorites.png',
          status: true
        }
      },
      {
        path: 'successquotationproductcustomer',
        component: SuccessQuotationProductCustomerComponent,
        data: {
          breadcrumb: 'Favoritos',
          icon: 'icofont-justify-all bg-c-green',
          status: false
        }
      },
      {
        path: 'successpurchasecustomer',
        component: SuccessPurchaseCustomerComponent,
        data: {
          breadcrumb: 'Favoritos',
          icon: 'icofont-justify-all bg-c-green',
          status: false
        }
      }, {
        path: 'ratingcustomer',
        component: RatingCustomerComponent,
        data: {
          breadcrumb: 'Calificación',
          icon: 'rating.png',
          status: true
        }
      },
      //CUSTOMER

      //PROVIDER
      {
        path: 'providerproducts',
        component: ProductsProviderComponent,
        data: {
          breadcrumb: 'Productos Proveedores',
          icon: 'productivity.png',
          status: true
        }
      }, {
        path: 'providerservices',
        component: ServicesProviderComponent,
        data: {
          breadcrumb: 'Servicios Proveedores',
          icon: 'services.png',
          status: true
        }
      },
      {
        path: 'ordersprovider',
        loadChildren: () => import('./pages/navigation_provider/orders_provider/orders_provider.module').then(m => m.OrdersProviderModule)
      },
      {
        path: 'quotesprovider',
        loadChildren: () => import('./pages/navigation_provider/quotes_provider/quotes_provider.module').then(m => m.QuotesProviderModule)
      },
      {
        path: 'createpurchasecustomer',
        component: CreatePurchaseCustomerComponent,
        data: {
          breadcrumb: 'Comprar con este proveedor',
          icon: 'Cotizaciones-activas_bottom.png',
          status: true
        }
      },
      {
        path: 'saleshistoryprovider',
        component: SaleshistoryProviderComponent,
        data: {
          breadcrumb: 'Historial de ventas',
          icon: 'history.png',
          status: true
        }
      },
      {
        path: 'saleshistoryproviderdetail',
        component: SaleshistoryProviderDetailComponent,
        data: {
          breadcrumb: 'Pedido en proceso',
          icon: 'icofont-justify-all bg-c-green',
          status: true
        }
      },/* {
        path: 'providerpayment',
        component: PaymentsProviderComponent,
        data: {
          breadcrumb: 'Pagos Proveedores',
          icon: 'payments.png',
          status: true
        }
      },*/
      {
        path: 'ratingprovider',
        component: RatingProviderComponent,
        data: {
          breadcrumb: 'Calificacion Proveedor',
          icon: 'rating.png',
          status: true
        }
      },
      {
        path: 'successratingshopprovider',
        component: SuccessRatingShopProviderComponent,
        data: {
          breadcrumb: 'Calificacion Proveedor ok',
          icon: 'icofont-justify-all bg-c-green',
          status: false
        }
      }, {
        path: 'nopayment',
        component: NoPaymentComponent,
        data: {
          breadcrumb: 'Calificacion Proveedor ok',
          icon: 'icofont-justify-all bg-c-green',
          status: false
        }
      }, {
        path: 'successcashpay',
        component: SuccessCashPayComponent,
        data: {
          breadcrumb: 'Calificacion Proveedor ok',
          icon: 'icofont-justify-all bg-c-green',
          status: false
        }
      },

      //PROVIDER

      {
        path: 'dashboard',
        loadChildren: () => import('./pages/dashboard/dashboard-default/dashboard-default.module').then(m => m.DashboardDefaultModule)
      }
    ]
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
