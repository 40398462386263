export class UserAddressBE {
    id: String;
    userId: String;
    customName: String;
    neighborhood : String
    address: String
    notes: String
    latitude: number;
     longitude: number;
    city: String;
}