import { Component, OnInit, ViewEncapsulation, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';



@Component({
  selector: 'app-success_rating_shop_provider',
  templateUrl: './success_rating_shop_provider.component.html',
  styleUrls: [
    '../../../../../node_modules/ng2-toasty/style-bootstrap.css',
    '../../../../../node_modules/ng2-toasty/style-default.css',
    '../../../../../node_modules/ng2-toasty/style-material.css'
  ],
  encapsulation: ViewEncapsulation.None
})
export class SuccessRatingShopProviderComponent implements OnInit {

  position = 'bottom-right';
  title: string;
  msg: string;
  showClose = true;
  theme = 'bootstrap';
  type = 'default';
  closeOther = false;
  
  
  public category = {};


  constructor(        
    private router: Router,
  ) {
    this.router.getCurrentNavigation().extras.state
  }

  gotoSubcategory(value) {
    this.category = value
    this.router.navigateByUrl('customersubcategories', { state: this.category });
  }

  ngOnInit() {
    
  }
}
