import { Component, OnInit, ViewEncapsulation, TemplateRef } from '@angular/core';
import { ToastData, ToastOptions, ToastyService } from 'ng2-toasty';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { PricesProvidersBE } from '../../../Entity/PricesProvidersBE'
import { ServiceApi } from '../../../shared/services/ServiceApi';
import { Router, ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';


@Component({
  selector: 'app-pricesproviders',
  templateUrl: './pricesproviders.component.html',
  styleUrls: [
    '../../../../../node_modules/ng2-toasty/style-bootstrap.css',
    '../../../../../node_modules/ng2-toasty/style-default.css',
    '../../../../../node_modules/ng2-toasty/style-material.css'
  ],
  encapsulation: ViewEncapsulation.None
})
export class PricesProvidersComponent implements OnInit {

  position = 'bottom-right';
  title: string;
  msg: string;
  showClose = true;
  theme = 'bootstrap';
  type = 'default';
  closeOther = false;
  objPriceProvider = new PricesProvidersBE();
  objResponsePricesProviders: any = [];
  objproduct: any = {};
  lstPricesProviders: any = [];
  lstProviders: any = [];
  lstTrademarks: any = [];
  lstCountries: any = [];
  lstDelivertimes: any = [];
  lstMeasurements: any = [];
  dtOptions: DataTables.Settings = {};
  modalRef: BsModalRef;
  dtTrigger: Subject<any> = new Subject<any>();
  dtElement: DataTableDirective;
  isDtInitialized: boolean = false;
  loading: boolean = false;

  constructor(
    private toastyService: ToastyService,
    private services: ServiceApi,
    private modalService: BsModalService,
    private router: Router,
  ) {
    this.router.getCurrentNavigation().extras.state
  }

  SavePriceProvider() {
    this.objPriceProvider.productId = this.objproduct.id;
    console.log(this.objPriceProvider);
    this.services.executeMethod(this.services.catalog, "ProviderPrices", this.objPriceProvider, (x) => {
      this.objResponsePricesProviders = x
      if (this.objResponsePricesProviders.status == true) {
        this.modalRef.hide();
        setTimeout(() => {
          this.addToast({ title: 'Registro Exitos!', msg: '', showClose: true, timeout: 1500, theme: 'material', position: 'center-center', type: 'success' });
        }, 800);
        this.ngOnInit();
      }
      this.objPriceProvider = new PricesProvidersBE();
    });
  }

  DeletePriceProvider(category) {
    if(!confirm("Estás seguro que deseas eliminar este item?")){
      return false;
    }
    this.services.executeMethodDelete(this.services.catalog, "ProviderPrices?idProviderPrices=", category, (x) => {
      this.lstPricesProviders = x
      if (this.lstPricesProviders.status == true) {
        this.addToast({ title: 'Registro Eliminado!', msg: '', showClose: true, timeout: 1500, theme: 'material', position: 'center-center', type: 'success' });
        this.ngOnInit();
      }
      this.objPriceProvider = new PricesProvidersBE();
    });
  }

  UpdatePriceProvider() {
    this.services.executeMethodPut(this.services.catalog, "ProviderPrices", this.objPriceProvider, (x) => {
      this.objResponsePricesProviders = x
      if (this.objResponsePricesProviders.status == true) {
        this.modalRef.hide();
        setTimeout(() => {
          this.addToast({ title: 'Se Actualizo Con Exito!', msg: '', showClose: true, timeout: 1500, theme: 'material', position: 'center-center', type: 'success' });
        }, 800);
        this.ngOnInit();
      }
    });
  }

  ngOnInit() {
    this.loading = true
    this.objproduct = Object.assign({}, history.state);
    if (this.objproduct.navigationId == 1) {
      this.objproduct = JSON.parse(localStorage.getItem("product"))
    } else {
      localStorage.setItem('product', JSON.stringify(this.objproduct));
    }

    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 100
    };

    this.services.executeMethodGet(this.services.catalog, "ProviderPrices?Search=" + this.objproduct.id, (x) => {
      this.loading = false
      this.lstPricesProviders = x.information.items;
      if (this.isDtInitialized) {
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
          this.dtTrigger.next();
        });
      } else {
        this.isDtInitialized = true
        this.dtTrigger.next();
      }
    });

    this.services.executeMethodGet(this.services.security, "User?Search=0614E506-4447-4FD1-81F3-08D8820C3640", (x) => {
      this.lstProviders = x.information.items;
    });

    this.services.executeMethodGet(this.services.enviroment, "Brand", (x) => {
      this.lstTrademarks = x.information.items;
    });

    this.services.executeMethodGet(this.services.enviroment, "Country", (x) => {
      this.lstCountries = x.information.items;
    });

    this.services.executeMethodGet(this.services.enviroment, "DeliveryTime", (x) => {
      this.lstDelivertimes = x.information.items;
    });

    this.services.executeMethodGet(this.services.enviroment, "Measure", (x) => {
      this.lstMeasurements = x.information.items;
    });
  }

  handleUpload(event) {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.objPriceProvider.productImage = reader.result.toString();
    };
  }

  ModalEditPriceProvider(template, provider) {
    this.modalRef = this.modalService.show(template,{
      class: 'modal-lg modal-dialog-centered',
    });
    this.objPriceProvider = provider
  }

  ModalAddPriceProvider(template) {
    this.objPriceProvider = new PricesProvidersBE();
    this.modalRef = this.modalService.show(template,{
      class: 'modal-lg modal-dialog-centered',
    });
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

  addToast(options) {
    if (options.closeOther) {
      this.toastyService.clearAll();
    }
    this.position = options.position ? options.position : this.position;
    const toastOptions: ToastOptions = {
      title: options.title,
      msg: options.msg,
      showClose: options.showClose,
      timeout: options.timeout,
      theme: options.theme,
      onAdd: (toast: ToastData) => {
        /* added */
      },
      onRemove: (toast: ToastData) => {
        /* removed */
      }
    };

    switch (options.type) {
      case 'default': this.toastyService.default(toastOptions); break;
      case 'info': this.toastyService.info(toastOptions); break;
      case 'success': this.toastyService.success(toastOptions); break;
      case 'wait': this.toastyService.wait(toastOptions); break;
      case 'error': this.toastyService.error(toastOptions); break;
      case 'warning': this.toastyService.warning(toastOptions); break;
    }
  }
}
