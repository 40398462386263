import { Component, OnInit, ViewEncapsulation, TemplateRef } from '@angular/core';
import { ToastData, ToastOptions, ToastyService } from 'ng2-toasty';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ServiceApi } from '../../../shared/services/ServiceApi';
import { Router } from '@angular/router';
import { OrderCustomerProductBE } from '../../../Entity/OrderCustomerProductBE';
import { UserAddressBE } from '../../../Entity/UserAddressBE';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-create_purchase_customer',
  templateUrl: './create_purchase_customer.component.html',
  styleUrls: [
    '../../../../../node_modules/ng2-toasty/style-bootstrap.css',
    '../../../../../node_modules/ng2-toasty/style-default.css',
    '../../../../../node_modules/ng2-toasty/style-material.css'
  ],
  encapsulation: ViewEncapsulation.None
})
export class CreatePurchaseCustomerComponent implements OnInit {

  position = 'bottom-right';
  strAddress = "";
  title: string;
  msg: string;
  objInfo: any = {}
  objUser: any = {}
  showClose = true;
  theme = 'bootstrap';
  type = 'default';
  closeOther = false;
  public loading = false;
  modalRef: BsModalRef;
  public isCollapsed = true;

  lstAddresses: any = [];
  objOrderCustomerProduct = new OrderCustomerProductBE();
  objSubcategoryCustomer: any = {};
  objQuotationProductDetail: any = {};
  objResponseOrderCustomerProduct: any = [];
  objUserAddress: UserAddressBE = new UserAddressBE();
  DataForm: FormGroup;
  objDelete: any = {}
  public category = {};
  showError = false;


  constructor(
    private toastyService: ToastyService,
    private services: ServiceApi,
    private modalService: BsModalService,
    private router: Router,
    private _formBuilder: FormBuilder,
  ) {
    this.router.getCurrentNavigation().extras.state

    this.DataForm = this._formBuilder.group({
      Address: ['', Validators.required],
      Neighborhood: ['', Validators.required],
      Notes: ['', null],
    });

  }

  gotoSubcategory(value) {
    this.category = value
    this.router.navigateByUrl('customersubcategories', { state: this.category });
  }


  ngOnInit() {
    this.objUser = this.services.getStorage("userInfo");
    this.objInfo = Object.assign({}, history.state);
    this.objSubcategoryCustomer = this.objInfo.data;
    if (this.objSubcategoryCustomer.navigationId == 1) {
      this.objSubcategoryCustomer = JSON.parse(localStorage.getItem("SubcategoryCustomer"))
    } else {
      localStorage.setItem('SubcategoryCustomer', JSON.stringify(this.objSubcategoryCustomer));
    }

    //console.log(this.objSubcategoryCustomer)
    this.services.executeMethodGet(this.services.enviroment, "Quotation/idQuotationDetail=" + this.objSubcategoryCustomer.id, (x) => {
      this.objQuotationProductDetail = x.information;
      //console.log(this.objQuotationProductDetail)
    });
    this.loadAddress();
  }

  loadAddress() {
    this.services.executeMethodGet(this.services.enviroment, "UserAddress?Search=" + this.services.getStorage('userInfo').id, (x) => {
      this.lstAddresses = x.information.items;
    });
  }

  QuoteProduct(value) {
    if (this.strAddress == "") {
      this.showError = true;
    } else {
      this.showError = false;
      this.loading = true
      this.objOrderCustomerProduct.idQuotationDetail = value;
      this.objOrderCustomerProduct.idUserAddress = this.strAddress
      this.objOrderCustomerProduct.paymentMethods = this.objInfo.paymentMethods
      this.services.executeMethod(this.services.catalog, "Order", this.objOrderCustomerProduct, (x) => {
        this.objResponseOrderCustomerProduct = x
        this.loading = false
        if (this.objResponseOrderCustomerProduct.status == true) {
          this.router.navigateByUrl('/successpurchasecustomer');
        }
        this.objOrderCustomerProduct = new OrderCustomerProductBE();
      });
    }
  }

  ModalConfirmation(template, orderId) {
    this.modalRef = this.modalService.show(template, {
      class: 'modal-dialog-centered',
    });
  }

  ModalDelete(template, obj) {
    this.objDelete = obj
    this.modalRef = this.modalService.show(template, {
      class: 'modal-xs modal-dialog-centered',
    });
  }

  deleteAddress() {
    if(!confirm("Estás seguro que deseas eliminar este item?")){
      return false;
    }
    this.services.executeMethodDelete(this.services.security, "UserAddress/", this.objDelete.id, (x) => {
      this.modalRef.hide();
      this.loadAddress();
    });
  }

  handleAddressChange(address: any) {
    this.objUserAddress.address = address.formatted_address
    this.objUserAddress.latitude = address.geometry.location.lat()
    this.objUserAddress.longitude = address.geometry.location.lng()
  }

  SaveAddress() {
    if (this.DataForm.valid) {
      this.objUserAddress.userId = this.objUser.id
      this.services.executeMethod(this.services.catalog, "UserAddress", this.objUserAddress, (x) => {
        this.modalRef.hide();
        this.loadAddress();
      });
    } else {
      this.services.validateAllFormFields(this.DataForm);
    }
  }

  addToast(options) {
    if (options.closeOther) {
      this.toastyService.clearAll();
    }
    this.position = options.position ? options.position : this.position;
    const toastOptions: ToastOptions = {
      title: options.title,
      msg: options.msg,
      showClose: options.showClose,
      timeout: options.timeout,
      theme: options.theme,
      onAdd: (toast: ToastData) => {
        /* added */
      },
      onRemove: (toast: ToastData) => {
        /* removed */
      }
    };

    switch (options.type) {
      case 'default': this.toastyService.default(toastOptions); break;
      case 'info': this.toastyService.info(toastOptions); break;
      case 'success': this.toastyService.success(toastOptions); break;
      case 'wait': this.toastyService.wait(toastOptions); break;
      case 'error': this.toastyService.error(toastOptions); break;
      case 'warning': this.toastyService.warning(toastOptions); break;
    }
  }
}
