import { Component, OnInit } from '@angular/core';
import { ServiceApi } from '../../../../shared/services/ServiceApi';
import { UserBE } from '../../../../Entity/UserBE'
import { UserAddressBE } from '../../../../Entity/UserAddressBE'
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html'
})
export class SignUpComponent implements OnInit {

  
  user = new UserBE();
  public identity;
  public _user;
  data: any = [];
  showProvider = true
  strProfile: string = ""
  strAddress: string = ""
  objTerms: any = {};
  objPolicy: any = {};
  DataForm: FormGroup;

  objTemplate: any = {};
  modalTitle = "";
  modalContent = "";
  modalRef: BsModalRef;
  chkTerms = false;
  showError = false;
  strType = "";
  public loading = false;


  objUserAddress: UserAddressBE = new UserAddressBE();


  constructor(
    private services: ServiceApi,
    private router: Router,
    private _formBuilder: FormBuilder,
    private modalService: BsModalService,
    private activatedRoute: ActivatedRoute,
  ) {


    this.activatedRoute.paramMap.subscribe(params => {
      this.strType = params.get('parameter');
      if (this.strType == "1") {
        this.user.profileId = "0614E506-5557-4FD1-81F3-08D8820C3640"
      } else if (this.strType == "2") {
        this.user.profileId = "0614E506-4447-4FD1-81F3-08D8820C3640"
      }
    });


    this.user = new UserBE();
    this.DataForm = this._formBuilder.group({
      Profile: ['', Validators.required],
      Name: ['', Validators.required],
      Company: ['', Validators.required],
      Phone: ['', Validators.required],
      Email: ['', Validators.required],
      Nit: ['', Validators.required],
      Terms: ['', Validators.required],
      Address: ['', null],
      Password: ['', Validators.required],
    });


    this.services.executeMethodWithOutGet(this.services.security, "EmailTemplate/Register?Search=Terms", (x) => {
      this.objTerms = x.information.items[0]
    });

    this.services.executeMethodWithOutGet(this.services.security, "EmailTemplate/Register?Search=Privacy", (x) => {
      this.objPolicy = x.information.items[0]
    });

  }

  handleAddressChange(address: any) {
    this.objUserAddress.address = address.formatted_address
    this.objUserAddress.latitude = address.geometry.location.lat()
    this.objUserAddress.longitude = address.geometry.location.lng()
    this.objUserAddress.neighborhood = "Bogota"
  }

  registerAddress() {
    this.services.executeMethodWithOut(this.services.security, "UserAddress/Register", this.objUserAddress, (x) => {
      var response = x;
      this.loading = false
      if (response.status) {
        if (this.showProvider) {
          this.router.navigate(['./signuppaymentmethods']);
        }
        //this.router.navigate(['./verifyCode']);
      } else {
        //show error response.message
        alert(response.message);
      }
    });
  }

  goToLogin(){
    this.router.navigate(['./login']);
  }

  signUp() {
    this.showError = false
    if (!this.chkTerms) {
      this.showError = true
      return;
    }

    if (this.DataForm.valid) {
      this.loading = true
      this.services.executeMethodWithOut(this.services.security, "User", this.user, (x) => {
        var response = x;
        if (response.status) {
          this.user.id = response.information
          this.objUserAddress.userId = this.user.id

          this.services.setStorage('tempSignUp', this.user)
          if (this.showProvider) {
            this.registerAddress()
          } else {
            this.loading = false
            this.router.navigate(['./verifyCode']);
          }
        } else {
          //show error response.message
          alert(response.message);
        }
      });
    } else {
      this.services.validateAllFormFields(this.DataForm);
    }
  }

  GetLegal(value) {
    if (value == 1) {
      this.modalTitle = this.objTerms.subject
      this.modalContent = this.objTerms.body
    }
    if (value == 2) {
      this.modalTitle = this.objPolicy.subject
      this.modalContent = this.objPolicy.body
    }
  }

  ModalLegal(template, value) {
    this.GetLegal(value);
    this.modalRef = this.modalService.show(template, {
      class: 'modal-lg modal-dialog-centered',
    });

  }

  ngOnInit() {
    document.querySelector('body').setAttribute('themebg-pattern', 'theme1');
  }

  onChangeProfile(event) {
    this.strProfile = this.user.profileId
    if (this.user.profileId == "0614E506-4447-4FD1-81F3-08D8820C3640") {
      this.showProvider = true

      this.DataForm.controls['Address'].setValidators([Validators.required]);
      this.DataForm.get('Address').updateValueAndValidity();

    } else {
      this.showProvider = false
      this.DataForm.controls['Address'].setValidators(null);
      this.DataForm.get('Address').updateValueAndValidity();
    }
  }
}
