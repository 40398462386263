import { Component, OnInit, ViewEncapsulation } from "@angular/core";

import '../../../../assets/charts/amchart/amcharts.js';
import '../../../../assets/charts/amchart/gauge.js';
import '../../../../assets/charts/amchart/pie.js';
import '../../../../assets/charts/amchart/serial.js';
import '../../../../assets/charts/amchart/light.js';
import '../../../../assets/charts/amchart/ammap.js';
import '../../../../assets/charts/amchart/worldLow.js';
import { ServiceApi } from '../../../shared/services/ServiceApi';


@Component({
    selector: 'app-payments',
    templateUrl: './payments.component.html',
    encapsulation: ViewEncapsulation.None
  })
  export class PaymentsComponent implements OnInit {

    lstPayments: any[] = []
    lstPendings: any[] = []
    objPayments: any = {}
    lstMonthlyPayments: any[] = []
    constructor(  private services: ServiceApi){

    }

    ngOnInit(): void {
      this.GetPaymentsMonth();
      this.GetStatistics()
    }  


    GetPaymentsMonth() {  
      this.services.executeMethodGet(this.services.catalog, "MonthlyCharges", (x) => {
        this.lstPendings = x.information.items.filter(x => !x.isPayed)
        this.lstPayments = x.information.items.filter(x => x.isPayed)
      });
    }

    GetStatistics(){
      this.services.executeMethodGet(this.services.orders, "Statistics/PaymentStatistics", (x) => {   
        this.objPayments = x.table[0]
        this.lstMonthlyPayments = x.table1
      });
    }
  }