import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceApi } from '../shared/services/ServiceApi';

@Component({
    selector: 'app-confirmation',
    templateUrl: './confirmation.component.html',
})
export class ConfirmationComponent implements OnInit {
    information: any
    objData: any
    constructor(private actRoute: ActivatedRoute, private _service: ServiceApi, private router: Router) {
        this.actRoute.queryParamMap.subscribe(queryParams => {
            //this.information.params.transactionID
            this.information = queryParams
            console.log(this.information.params.transactionID)

                this._service.executeMethodWithOutGet(this._service.security,"Epayco/GetTransactionDetail/" + this.information.params.transactionID, (x) => {
                    //this.router.navigate(['./Response']);
                    this.objData = x
                });
            
        })
    }

    fillInformation(information) {
        var obj: any = {};
        obj.MerchantId = (information.params["merchantId"])
        obj.Value = (information.params["TX_VALUE"])
        obj.EmailBuyer = (information.params["buyerEmail"])
        obj.Currency = (information.params["currency"])
        obj.TypePaymentMethod = (information.params["lapPaymentMethodType"])
        obj.Order = (information.params["referenceCode"]) //My
        obj.Code = (information.params["transactionState"])
        obj.Risk = (information.params["risk"])
        obj.OrderPayU = (information.params["transactionId"])
        obj.ResponseMessage = (information.params["message"])
        obj.TrasactionDate = (information.params["processingDate"])
        obj.Signature = (information.params["signature"])
        obj.Reference1 = (information.params["pseReference1"])
        obj.Description = (information.params["description"])
        obj.Bank = (information.params["pseBank"])
        
        return obj;
    }

    
    goToHome() {
        //this.router.navigate(['./Shop']);
    }


    ngOnInit(): void {
    }
}