import { Component, OnInit, ViewEncapsulation, TemplateRef } from '@angular/core';
import { ToastData, ToastOptions, ToastyService } from 'ng2-toasty';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ServiceQuotationCustomerBE } from '../../../Entity/ServiceQuotationCustomerBE'
import { ServiceApi } from '../../../shared/services/ServiceApi';
import { Router } from '@angular/router';
import { FileUploader } from 'ng2-file-upload';
import { ServiceQutationFilesBE } from '../../../Entity/ServiceQutationFilesBE';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';


@Component({
  selector: 'app-services_customer',
  templateUrl: './services_customer.component.html',
  styleUrls: [
    '../../../../../node_modules/ng2-toasty/style-bootstrap.css',
    '../../../../../node_modules/ng2-toasty/style-default.css',
    '../../../../../node_modules/ng2-toasty/style-material.css'
  ],
  encapsulation: ViewEncapsulation.None
})
export class ServicesCustomerComponent implements OnInit {
  public loading = false;
  uploader: FileUploader;
  hasBaseDropZoneOver: boolean;
  hasAnotherDropZoneOver: boolean;
  response: string;
  position = 'bottom-right';
  title: string;
  msg: string;
  showClose = true;
  theme = 'bootstrap';
  type = 'default';
  closeOther = false;
  isJustDescription = false;
  studentId = 9998;
  uploadProgress = 0;
  selectedFiles: File[];
  uploading = false;
  errorMsg = '';
  strId: string;
  imgbs: string;
  objResponseUploadFiles: any = [];
  objServiceQutationFiles = new ServiceQutationFilesBE();
  modalRef: BsModalRef;
  lstServiceconfig: any = [];
  lstServiceTypes: any = [];
  lstimgs: any[] = [];
  lstImgsGaleryToDel: any[] = [];
  objServiceQuotationCustomer = new ServiceQuotationCustomerBE();
  objResponseServiceQuotationCustomer: any = [];
  isSuccess: boolean;
  alreadyUpload: boolean;
  DataForm: FormGroup;

  constructor(
    private toastyService: ToastyService,
    private services: ServiceApi,
    private modalService: BsModalService,
    private router: Router,
    private _formBuilder: FormBuilder
  ) {
    this.router.getCurrentNavigation().extras.state;

    this.DataForm = this._formBuilder.group({
      quantity: ['', Validators.required],
      ddlservicetype: ['', Validators.required],
      ddlmaterial: ['', Validators.required],
      ddlthickness: ['', Validators.required],
      ddlplaneunits: ['', Validators.required],
      ddlincludematerial: ['', Validators.required],
      ddldeliverymethod: ['', Validators.required],
      description: ['', Validators.required]
    });

  }

  ngOnInit() {
    this.selectedFiles = [];
    this.services.executeMethodGet(this.services.enviroment, "Service", (x) => {
      this.lstServiceconfig = x.information.items;
      //console.log(this.lstServiceconfig)
    });

    this.services.executeMethodGet(this.services.enviroment, "ServiceType?Search=" + this.lstServiceconfig.id, (x) => {
      this.lstServiceTypes = x.information.items;
    });

  }

  GetServiceType(value) {
    this.services.executeMethodGet(this.services.enviroment, "ServiceType?Search=" + value, (x) => {
      this.lstServiceTypes = x.information.items;
    });
  }

  ModalEditProduct(template, product) {
    this.objServiceQutationFiles = new ServiceQutationFilesBE();
    this.selectedFiles = [];
    this.isJustDescription = product.justDescription

    this.modalRef = this.modalService.show(template, {
      class: 'modal-lg modal-dialog-centered',
    });
    this.GetServiceType(product.id);
  }

  QuoteServiceCustomer() {

    if (this.DataForm.valid) {
      if (!this.selectedFiles || this.selectedFiles.length === 0) {
        this.errorMsg = 'Por favor selecciona un archivo';
        return;
      }

      this.loading = true
      this.objServiceQuotationCustomer.userId = this.services.getStorage('userInfo').id;
      this.objServiceQuotationCustomer.incudeMaterial = true;
      this.objServiceQuotationCustomer.justDescription = this.isJustDescription;

      this.services.executeMethod(this.services.catalog, "ServiceQuotation", this.objServiceQuotationCustomer, (x) => {
        //console.log(x)
        this.objResponseServiceQuotationCustomer = x
        if (this.objResponseServiceQuotationCustomer.status == true) {
          this.modalRef.hide();
          this.objServiceQuotationCustomer.id = this.objResponseServiceQuotationCustomer.information
          this.services.executeMethod(this.services.catalog, "ServiceQuotation/SendNotifications", this.objServiceQuotationCustomer, (y) => {

          });

          this.upload(this.objServiceQuotationCustomer.id)
        }
        this.objServiceQuotationCustomer = new ServiceQuotationCustomerBE();
      });
    } else {
      this.services.validateAllFormFields(this.DataForm);
    }

  }

  chooseFile(event, files: FileList) {
    debugger
    this.errorMsg = '';
    this.uploadProgress = 0;
    if (files.length === 0) {
      return;
    }
    for (let i = 0; i < files.length; i++) {
      const file = event.target.files[i];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.imgbs = reader.result.toString();

        this.selectedFiles.push(files[i]);
        var name = files[i].name.split(".")
        var FileExt = name[name.length - 1];

        console.log(this.imgbs)

        if (i == 0) {
          this.objServiceQutationFiles.extFile1 = "." + FileExt;
          this.objServiceQutationFiles.urlFile1 = this.imgbs;
        }
        if (i == 1) {
          this.objServiceQutationFiles.extFile2 = "." + FileExt;
          this.objServiceQutationFiles.urlFile2 = this.imgbs;
        }
        if (i == 2) {
          this.objServiceQutationFiles.extFile3 = "." + FileExt;
          this.objServiceQutationFiles.urlFile3 = this.imgbs;
        }
        if (i == 3) {
          this.objServiceQutationFiles.extFile4 = "." + FileExt;
          this.objServiceQutationFiles.urlFile4 = this.imgbs;
        }
        if (i == 4) {
          this.objServiceQutationFiles.extFile5 = "." + FileExt;
          this.objServiceQutationFiles.urlFile5 = this.imgbs;
        }
      };
      //console.log(this.selectedFiles[0])
      //console.log(files[i].name)
      //console.log(i)
    }
  }

  upload(strQuotationId) {
    //console.log(this.selectedFiles)
    /*const formData = new FormData();
    this.selectedFiles.forEach((f) => formData.append('certificates', f));*/
    this.objServiceQutationFiles.serviceQuotationId = strQuotationId
    this.uploading = true;
    this.services.executeMethodPutFiles(this.services.catalog, "ServiceQuotation/LoadFiles", this.objServiceQutationFiles, (x) => {
      this.loading = false
      this.objResponseUploadFiles = x
      if (this.objResponseUploadFiles.status == true) {
        this.router.navigateByUrl('successquotationproductcustomer');
      }
    });


  }


  humanFileSize(bytes: number): string {
    if (Math.abs(bytes) < 1024) {
      return bytes + ' B';
    }
    const units = ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    let u = -1;
    do {
      bytes /= 1024;
      u++;
    } while (Math.abs(bytes) >= 1024 && u < units.length - 1);
    return bytes.toFixed(1) + ' ' + units[u];
  }



  addToast(options) {
    if (options.closeOther) {
      this.toastyService.clearAll();
    }
    this.position = options.position ? options.position : this.position;
    const toastOptions: ToastOptions = {
      title: options.title,
      msg: options.msg,
      showClose: options.showClose,
      timeout: options.timeout,
      theme: options.theme,
      onAdd: (toast: ToastData) => {
        /* added */
      },
      onRemove: (toast: ToastData) => {
        /* removed */
      }
    };

    switch (options.type) {
      case 'default': this.toastyService.default(toastOptions); break;
      case 'info': this.toastyService.info(toastOptions); break;
      case 'success': this.toastyService.success(toastOptions); break;
      case 'wait': this.toastyService.wait(toastOptions); break;
      case 'error': this.toastyService.error(toastOptions); break;
      case 'warning': this.toastyService.warning(toastOptions); break;
    }
  }
}


