import { Component, OnInit, ViewEncapsulation, TemplateRef } from '@angular/core';
import { ToastData, ToastOptions, ToastyService } from 'ng2-toasty';
import { ServiceApi } from '../../../shared/services/ServiceApi';
import { Router } from '@angular/router';
import { FavoriteBE } from '../../../Entity/FavoriteBE';

@Component({
  selector: 'app-favorite_customer',
  templateUrl: './favorite_customer.component.html',
  styleUrls: [
    '../../../../../node_modules/ng2-toasty/style-bootstrap.css',
    '../../../../../node_modules/ng2-toasty/style-default.css',
    '../../../../../node_modules/ng2-toasty/style-material.css'
  ],
  encapsulation: ViewEncapsulation.None
})
export class FavoriteCustomerComponent implements OnInit {

  position = 'bottom-right';
  title: string;
  msg: string;
  showClose = true;
  theme = 'bootstrap';
  type = 'default';
 
  lstCategories: any = [];  
  public category = {};
  public provider = {};
  objUser: any = {}

  constructor(
    private toastyService: ToastyService,
    private services: ServiceApi,    
    private router: Router
  ) {

  }

  gotoProductQuotation(value) {
    this.category = value
    this.router.navigateByUrl('customerproductquotation', { state: this.category });
  }
  
  ngOnInit() {
   this.loadData();
  }

  loadData(){
    this.objUser = this.services.getStorage("userInfo");
    this.services.executeMethodGet(this.services.enviroment, "Favorite/" + this.objUser.id, (x) => {      
      this.lstCategories = x.information.items;
      //console.log(this.lstCategories)
    });
  }

  setFavorite(obj){
    var objFavorite = new FavoriteBE();
    objFavorite.productId = obj.product.id;
    objFavorite.userId = this.objUser.id;

    this.services.executeMethod(this.services.enviroment,"Favorite", objFavorite, (x) => {
      if(x.status){
        this.loadData();
      }
    })
    
  }

  addToast(options) {
    if (options.closeOther) {
      this.toastyService.clearAll();
    }
    this.position = options.position ? options.position : this.position;
    const toastOptions: ToastOptions = {
      title: options.title,
      msg: options.msg,
      showClose: options.showClose,
      timeout: options.timeout,
      theme: options.theme,
      onAdd: (toast: ToastData) => {
        /* added */
      },
      onRemove: (toast: ToastData) => {
        /* removed */
      }
    };

    switch (options.type) {
      case 'default': this.toastyService.default(toastOptions); break;
      case 'info': this.toastyService.info(toastOptions); break;
      case 'success': this.toastyService.success(toastOptions); break;
      case 'wait': this.toastyService.wait(toastOptions); break;
      case 'error': this.toastyService.error(toastOptions); break;
      case 'warning': this.toastyService.warning(toastOptions); break;
    }
  }
}
