import { Component, OnInit, ViewEncapsulation, TemplateRef } from '@angular/core';
import { ToastData, ToastOptions, ToastyService } from 'ng2-toasty';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ProductBE } from '../../../Entity/ProductBE'
import { ServiceApi } from '../../../shared/services/ServiceApi';
import { Router } from '@angular/router';
import { FileHolder } from 'angular2-image-upload';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';


@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: [
    '../../../../../node_modules/ng2-toasty/style-bootstrap.css',
    '../../../../../node_modules/ng2-toasty/style-default.css',
    '../../../../../node_modules/ng2-toasty/style-material.css'
  ],
  encapsulation: ViewEncapsulation.None
})
export class ProductsComponent implements OnInit {

  position = 'bottom-right';
  title: string;
  msg: string;
  showClose = true;
  theme = 'bootstrap';
  type = 'default';
  closeOther = false;
  objProduct = new ProductBE();
  objDetailProduct: any = {};
  objImageSend: any = {};
  objResponseProducts: any = [];
  lstProducts: any = [];
  lstimgs: any[] = [];
  lstImgsGaleryToDel: any[] = [];
  lstImgsGalery: any[] = [];
  lstSubcategories: any = [];
  lstSubcategoriesFiltered: any = [];
  lstCategories: any = [];
  lstTrademarks: any = [];
  lstMeasurements: any = [];
  dtOptions: DataTables.Settings = {};
  modalRef: BsModalRef;
  public product = {};
  idproduct: string;
  dtTrigger: Subject<any> = new Subject<any>();
  dtElement: DataTableDirective;
  isDtInitialized: boolean = false;
  imagesToEdit = [];
  loading: boolean = false;

  constructor(
    private toastyService: ToastyService,
    private services: ServiceApi,
    private modalService: BsModalService,
    private router: Router,
  ) {

  }

  gotoPricesProviders(value) {
    this.product = value
    this.router.navigateByUrl('pricesproviders', { state: this.product });
  }

  SaveProduct() {

    this.objProduct.codeISO3 = "COL";
    this.objProduct.price = 2000;
    this.objProduct.stock = 0;
    this.objProduct.discount = 0;
    this.objProduct.isFavorite = true;
    this.objProduct.providerId = "CB92BF61-95F1-4807-F371-08D881D277B5";
    this.objProduct.brandId = null;
    this.services.executeMethod(this.services.catalog, "Product", this.objProduct, (x) => {
      this.objResponseProducts = x
      if (this.objResponseProducts.status == true) {
        this.modalRef.hide();
        setTimeout(() => {
          this.addToast({ title: 'Registro Exitos!', msg: '', showClose: true, timeout: 1500, theme: 'material', position: 'center-center', type: 'success' });
        }, 800);
        this.ngOnInit();
      }
    });
  }

  SaveImageProduct(productid) {
    this.objImageSend.productId = productid;
    this.lstimgs.forEach(xx => {
      this.objImageSend.urlImage = xx.src;
      this.services.executeMethod(this.services.catalog, "Product/image", this.objImageSend, (x) => {
        console.log(x);
        //this.objResponseProducts = x      
        /*if (this.objResponseProducts.status == true) {
          this.modalRef.hide();
          setTimeout(() => {                          
            this.addToast({ title: 'Registro Exitos!', msg: '', showClose: true, timeout: 1500, theme: 'material', position: 'center-center', type: 'success' });
          }, 800);        
          this.ngOnInit();
        } */
      });
    });
    this.modalRef.hide();
    setTimeout(() => {
      this.addToast({ title: 'Registro Exitos!', msg: '', showClose: true, timeout: 1500, theme: 'material', position: 'center-center', type: 'success' });
    }, 800);
  }

  DeleteProduct(category) {
    if (!confirm("Estás seguro que deseas eliminar este item?")) {
      return false;
    }
    this.services.executeMethodDelete(this.services.catalog, "Product/", category, (x) => {
      this.objResponseProducts = x
      if (this.objResponseProducts.status == true) {
        this.addToast({ title: 'Registro Eliminado!', msg: '', showClose: true, timeout: 1500, theme: 'material', position: 'center-center', type: 'success' });
        this.ngOnInit();
      }
      this.objProduct = new ProductBE();
    });
  }

  UpdateProduct() {
    if (this.lstImgsGaleryToDel.length > 0) {
      this.lstImgsGaleryToDel.forEach(x => {
        console.log(x.file.name)
        if (!confirm("Estás seguro que deseas eliminar este item?")) {
          return false;
        }
        this.services.executeMethodDelete(this.services.catalog, "Product/image/", x.file.name, (xx) => {
          console.log(xx);
          //this.objResponseProducts = x      
          /*if (this.objResponseProducts.status == true) {
            this.modalRef.hide();
            setTimeout(() => {                          
              this.addToast({ title: 'Registro Exitos!', msg: '', showClose: true, timeout: 1500, theme: 'material', position: 'center-center', type: 'success' });
            }, 800);        
            this.ngOnInit();
          }*/
        });
      });
    }
    this.objProduct.codeISO3 = "COL";
    this.objProduct.price = 2000;
    this.objProduct.stock = 0;
    this.objProduct.discount = 0;
    this.objProduct.isFavorite = true;
    this.objProduct.providerId = "CB92BF61-95F1-4807-F371-08D881D277B5";
    this.objProduct.brandId = "27f57280-66b6-47a7-39da-08d888ecb5dc";

    this.objProduct.id = this.objDetailProduct.id;
    this.objProduct.name = this.objDetailProduct.productName;
    this.objProduct.subCategoryId = this.objDetailProduct.subCategoryId;
    this.objProduct.characteristics = this.objDetailProduct.characteristics;
    this.objProduct.description = this.objDetailProduct.description;
    this.objProduct.isEnabled = this.objDetailProduct.isEnabled;
    this.objProduct.color = this.objDetailProduct.color;
    this.objProduct.measuresIds = this.objDetailProduct.measuresIds;

    console.log(this.objProduct)
    this.services.executeMethodPut(this.services.catalog, "Product", this.objProduct, (x) => {
      this.objResponseProducts = x
      if (this.objResponseProducts.status == true) {
        this.modalRef.hide();
        setTimeout(() => {
          this.addToast({ title: 'Se Actualizo Con Exito!', msg: '', showClose: true, timeout: 1500, theme: 'material', position: 'center-center', type: 'success' });
        }, 800);
        this.ngOnInit();
      }
    });
  }

  GetProduct(productId) {
    this.services.executeMethodGet(this.services.catalog, "Product/" + productId, (x) => {
      this.objDetailProduct = x.information;
    });
    console.log(this.objDetailProduct)
  }

  GetProductGalery(productId) {
    this.services.executeMethodGet(this.services.catalog, "Product/image/" + productId, (x) => {
      this.lstImgsGalery = x.information.items;
      this.lstImgsGalery.forEach(xx => {
        setTimeout(() => {
          this.imagesToEdit = [
            { fileName: xx.id, url: xx.urlImage }
          ];
        }, 500);
      });
    });
  }

  ngOnInit() {
    this.loading = true;
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 100,
      processing: true,
    };

    this.services.executeMethodGet(this.services.catalog, "Product?ShowAll=true", (x) => {
      this.lstProducts = x.information.items;
      this.loading = false;
      if (this.isDtInitialized) {
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
          this.dtTrigger.next();
        });
      } else {
        this.isDtInitialized = true
        this.dtTrigger.next();
      }
    });

    this.services.executeMethodGet(this.services.enviroment, "Category", (x) => {
      this.lstCategories = x.information.items;
    });

    this.services.executeMethodGet(this.services.enviroment, "SubCategory", (x) => {
      this.lstSubcategories = x.information.items;
      this.lstSubcategoriesFiltered = x.information.items;
    });

    this.services.executeMethodGet(this.services.enviroment, "Brand", (x) => {
      this.lstTrademarks = x.information.items;
    });

    this.services.executeMethodGet(this.services.enviroment, "Measure", (x) => {
      this.lstMeasurements = x.information.items;
    });

  }

  ModalEditProduct(template, productId) {
    this.imagesToEdit = [];
    this.modalRef = this.modalService.show(template, {
      class: 'modal-lg modal-dialog-centered',
    });
    this.GetProduct(productId);
    this.GetProductGalery(productId);
  }

  ModalAddProduct(template) {
    this.objProduct = new ProductBE();
    this.modalRef = this.modalService.show(template, {
      class: 'modal-lg modal-dialog-centered',
    });
  }

  ModalGaleryProduct(template, productId) {
    this.lstimgs = [];
    this.modalRef = this.modalService.show(template, {
      class: 'modal-lg modal-dialog-centered',
    });
    this.idproduct = productId;
  }

  ModalImageProduct(template, product) {
    this.modalRef = this.modalService.show(template, {
      class: 'modal-dialog-centered',
    });
    this.objProduct = product
  }

  ModalDetailProduct(template, productId) {
    this.modalRef = this.modalService.show(template, {
      class: 'modal-lg modal-dialog-centered',
    });
    this.GetProduct(productId);
    this.GetProductGalery(productId);
  }

  handleUpload(event) {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.objProduct.urlImage = reader.result.toString();
    };
  }

  onUploadFinished(file: FileHolder) {
    this.lstimgs.push(file);
  }

  onRemoved(file: FileHolder) {
    this.lstImgsGaleryToDel.push(file);
  }

  onUploadStateChanged(state: boolean) {
    //console.log(state);
  }

  // Método para seleccionar una medida
  onSelectMeasure(measureId: string) {
    console.log(this.objProduct.measuresIds);
    if (!this.objProduct.measuresIds) {
      this.objProduct.measuresIds = [];
    }
    if (measureId !== 'undefined' && !this.objProduct.measuresIds.includes(measureId)) {
      this.objProduct.measuresIds.push(measureId);
    }
  }

  // Método para eliminar una medida
  removeMeasure(measureId: string) {
    if (this.objProduct.measuresIds) {
      this.objProduct.measuresIds = this.objProduct.measuresIds.filter(id => id !== measureId);
    }
  }


    // Método para seleccionar una medida en editar
    onSelectMeasureEdit(measureId: string) {
      console.log(this.objDetailProduct.measuresIds);
      if (!this.objDetailProduct.measuresIds) {
        this.objDetailProduct.measuresIds = [];
      }
      if (measureId !== 'undefined' && !this.objDetailProduct.measuresIds.includes(measureId)) {
        this.objDetailProduct.measuresIds.push(measureId);
      }
    }
  
    // Método para eliminar una medida en editar
    removeMeasureEdit(measureId: string) {
      if (this.objDetailProduct.measuresIds) {
        this.objDetailProduct.measuresIds = this.objDetailProduct.measuresIds.filter(id => id !== measureId);
      }
    }
  

  // Método para obtener el nombre de una medida
  getMeasureName(measureId: string): string {
    const measure = this.lstMeasurements.find(c => c.id === measureId);
    return measure ? measure.name : measureId;
  }


  addToast(options) {
    if (options.closeOther) {
      this.toastyService.clearAll();
    }
    this.position = options.position ? options.position : this.position;
    const toastOptions: ToastOptions = {
      title: options.title,
      msg: options.msg,
      showClose: options.showClose,
      timeout: options.timeout,
      theme: options.theme,
      onAdd: (toast: ToastData) => {
        /* added */
      },
      onRemove: (toast: ToastData) => {
        /* removed */
      }
    };

    switch (options.type) {
      case 'default': this.toastyService.default(toastOptions); break;
      case 'info': this.toastyService.info(toastOptions); break;
      case 'success': this.toastyService.success(toastOptions); break;
      case 'wait': this.toastyService.wait(toastOptions); break;
      case 'error': this.toastyService.error(toastOptions); break;
      case 'warning': this.toastyService.warning(toastOptions); break;
    }
  }

  onCategoryChanged(categorySelectValue) {
    if (categorySelectValue == 'undefined') {
      this.lstSubcategoriesFiltered = this.lstSubcategories;
      return;
    }
    this.lstSubcategoriesFiltered = this.lstSubcategories.filter(subCategory => subCategory.categoryId == categorySelectValue);
  }
}
