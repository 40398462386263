import { Component, OnInit, ViewEncapsulation, TemplateRef } from '@angular/core';
import { ToastData, ToastOptions, ToastyService } from 'ng2-toasty';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ServiceProvidersBE } from '../../../Entity/ServiceProvidersBE'
import { ServiceApi } from '../../../shared/services/ServiceApi';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';



@Component({
  selector: 'app-servicesproviders',
  templateUrl: './servicesproviders.component.html',
  styleUrls: [
    '../../../../../node_modules/ng2-toasty/style-bootstrap.css',
    '../../../../../node_modules/ng2-toasty/style-default.css',
    '../../../../../node_modules/ng2-toasty/style-material.css'
  ],
  encapsulation: ViewEncapsulation.None
})
export class ServicesProvidersComponent implements OnInit {  

  position = 'bottom-right';
  title: string;
  msg: string;
  showClose = true;
  theme = 'bootstrap';
  type = 'default';
  closeOther = false;
  objServiceProvider = new ServiceProvidersBE();
  objResponseServiceProvider: any = []; 
  objProvider: any = {}; 
  lstServicesProviders: any = [];  
  lstServices: any = [];
  dtOptions: DataTables.Settings = {};
  modalRef: BsModalRef;  
  dtTrigger: Subject<any> = new Subject<any>();
  dtElement: DataTableDirective;
  isDtInitialized: boolean = false;
  loading: boolean = false;

  constructor(
    private toastyService: ToastyService,
    private services: ServiceApi,
    private modalService: BsModalService,
    private router: Router,
  ) {
    this.router.getCurrentNavigation().extras.state
  }  

  SaveServiceProvider() {  
    this.objServiceProvider.userId = this.objProvider.id;
    this.services.executeMethod(this.services.catalog, "ServiceProvider", this.objServiceProvider, (x) => {
      this.objResponseServiceProvider = x      
      if (this.objResponseServiceProvider.status == true) {
        this.modalRef.hide();
        setTimeout(() => {                          
          this.addToast({ title: 'Registro Exitos!', msg: '', showClose: true, timeout: 1500, theme: 'material', position: 'center-center', type: 'success' });
        }, 800);        
        this.ngOnInit();
      }
      this.objServiceProvider = new ServiceProvidersBE();
    });
  }

  DeleteServiceProvider(category) {
    if(!confirm("Estás seguro que deseas eliminar este item?")){
      return false;
    }
    this.services.executeMethodDelete(this.services.catalog, "ServiceProvider/", category, (x) => {      
      this.objResponseServiceProvider = x
      if (this.objResponseServiceProvider.status == true) {
        this.addToast({ title: 'Registro Eliminado!', msg: '', showClose: true, timeout: 1500, theme: 'material', position: 'center-center', type: 'success' });
        this.ngOnInit();
      }
      this.objServiceProvider = new ServiceProvidersBE();
    });
  }

  UpdateServiceProvider() {
    this.services.executeMethodPut(this.services.catalog, "ServiceProvider", this.objServiceProvider, (x) => {
      this.objResponseServiceProvider = x     
      if (this.objResponseServiceProvider.status == true) {
        this.modalRef.hide();
        setTimeout(() => {                          
          this.addToast({ title: 'Se Actualizo Con Exito!', msg: '', showClose: true, timeout: 1500, theme: 'material', position: 'center-center', type: 'success' });
        }, 800);         
        this.ngOnInit();
      }      
    });
  }

  ngOnInit() {
    this.loading = true
    this.objProvider = Object.assign({}, history.state);
    if (this.objProvider.navigationId == 1) {
      this.objProvider = JSON.parse(localStorage.getItem("provider"))
    } else {
      localStorage.setItem('provider', JSON.stringify(this.objProvider));
    }    

    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 100
    };

    this.services.executeMethodGet(this.services.catalog, "ServiceProvider?Search=" + this.objProvider.id, (x) => {     
      this.loading = false 
      this.lstServicesProviders = x.information.items;   
      if (this.isDtInitialized) {
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
          this.dtTrigger.next();
        });
      } else {
        this.isDtInitialized = true
        this.dtTrigger.next();
      }   
    });    

    this.services.executeMethodGet(this.services.enviroment, "Service", (x) => {      
      this.lstServices = x.information.items;      
    });
  }

  ModalEditServiceProvider(template, provider) {
    this.modalRef = this.modalService.show(template, {
      class: 'modal-dialog-centered',
    });
    this.objServiceProvider = provider
  }

  ModalAddServiceProvider(template) {
    this.objServiceProvider = new ServiceProvidersBE();
    this.modalRef = this.modalService.show(template, {
      class: 'modal-dialog-centered',
    });
  }

  addToast(options) {
    if (options.closeOther) {
      this.toastyService.clearAll();
    }
    this.position = options.position ? options.position : this.position;
    const toastOptions: ToastOptions = {
      title: options.title,
      msg: options.msg,
      showClose: options.showClose,
      timeout: options.timeout,
      theme: options.theme,
      onAdd: (toast: ToastData) => {
        /* added */
      },
      onRemove: (toast: ToastData) => {
        /* removed */
      }
    };

    switch (options.type) {
      case 'default': this.toastyService.default(toastOptions); break;
      case 'info': this.toastyService.info(toastOptions); break;
      case 'success': this.toastyService.success(toastOptions); break;
      case 'wait': this.toastyService.wait(toastOptions); break;
      case 'error': this.toastyService.error(toastOptions); break;
      case 'warning': this.toastyService.warning(toastOptions); break;
    }
  }
}
