import { Component, OnInit, ViewEncapsulation, TemplateRef } from '@angular/core';
import { ToastData, ToastOptions, ToastyService } from 'ng2-toasty';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ServiceProvidersBE } from '../../../Entity/ServiceProvidersBE'
import { ServiceApi } from '../../../shared/services/ServiceApi';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';



@Component({
  selector: 'app-services_provider',
  templateUrl: './services_provider.component.html',
  styleUrls: [
    '../../../../../node_modules/ng2-toasty/style-bootstrap.css',
    '../../../../../node_modules/ng2-toasty/style-default.css',
    '../../../../../node_modules/ng2-toasty/style-material.css',
    './services_provider.component.scss'
  ],
  encapsulation: ViewEncapsulation.None
})
export class ServicesProviderComponent implements OnInit {

  objDelete: any = {}
  position = 'bottom-right';
  title: string;
  msg: string;
  showClose = true;
  theme = 'bootstrap';
  type = 'default';
  closeOther = false;

  objServiceProvider = new ServiceProvidersBE();
  objResponseServiceProvider: any = [];
  lstServicesProvider: any = [];
  lstServices: any = [];
  lstServicesType: any = [];
  public category = {};
  public quotation = {};
  modalRef: BsModalRef;
  DataForm: FormGroup;

  constructor(
    private toastyService: ToastyService,
    private services: ServiceApi,
    private modalService: BsModalService,
    private router: Router,
    private _formBuilder: FormBuilder
  ) {
    this.router.getCurrentNavigation().extras.state;

    this.DataForm = this._formBuilder.group({
      ddlservice: ['', Validators.required],
      ddlservicetype: ['', Validators.required],
    });

  }

  onChangeServiceType(value) {

    var obj: any = {}
    obj.Id = value;

    this.services.executeMethodGet(this.services.enviroment, "ServiceType?Search=" + obj.Id, (x) => {
      this.lstServicesType = x.information.items;
    });
  }

  ngOnInit() {
    this.services.executeMethodGet(this.services.enviroment, "ServiceProvider?Search=" + this.services.getStorage('userInfo').id, (x) => {
      this.lstServicesProvider = x.information.items;
    });

    this.services.executeMethodGet(this.services.enviroment, "Service", (x) => {
      this.lstServices = x.information.items;
    });



  }

  SaveServiceProvider() {
    if (this.DataForm.valid) {
      this.objServiceProvider.userId = this.services.getStorage('userInfo').id;
      this.services.executeMethod(this.services.catalog, "ServiceProvider", this.objServiceProvider, (x) => {
        this.objResponseServiceProvider = x
        if (this.objResponseServiceProvider.status == true) {
          this.modalRef.hide();
          setTimeout(() => {
            this.addToast({ title: 'Registro Exitos!', msg: '', showClose: true, timeout: 1500, theme: 'material', position: 'center-center', type: 'success' });
          }, 800);
          this.ngOnInit();
        }
        this.objServiceProvider = new ServiceProvidersBE();
      });
    } else {
      this.services.validateAllFormFields(this.DataForm);
    }
  }

  DeleteServiceProvider() {
    if(!confirm("Estás seguro que deseas eliminar este item?")){
      return false;
    }
    this.services.executeMethodDelete(this.services.catalog, "ServiceProvider/", this.objDelete.id, (x) => {
      this.objResponseServiceProvider = x
      if (this.objResponseServiceProvider.status == true) {
        this.modalRef.hide();
        this.addToast({ title: 'Registro Eliminado!', msg: '', showClose: true, timeout: 1500, theme: 'material', position: 'center-center', type: 'success' });
        this.ngOnInit();
      }
      this.objServiceProvider = new ServiceProvidersBE();
    });
  }

  UpdateServiceProvider() {
    this.objServiceProvider.userId = this.services.getStorage('userInfo').id;
    this.services.executeMethodPut(this.services.catalog, "ServiceProvider", this.objServiceProvider, (x) => {
      this.objResponseServiceProvider = x
      if (this.objResponseServiceProvider.status == true) {
        this.modalRef.hide();
        setTimeout(() => {
          this.addToast({ title: 'Se Actualizo Con Exito!', msg: '', showClose: true, timeout: 1500, theme: 'material', position: 'center-center', type: 'success' });
        }, 800);
        this.ngOnInit();
      }
    });
  }

  ModalEditServiceProvider(template, service) {
    this.modalRef = this.modalService.show(template, {
      class: 'modal-dialog-centered',
    });
    this.objServiceProvider = service
  }

  ModalAddServiceProvider(template) {
    this.objServiceProvider = new ServiceProvidersBE();
    this.modalRef = this.modalService.show(template, {
      class: 'modal-dialog-centered',
    });
  }

  ModalDelete(template, obj) {
    this.objDelete = obj
    this.modalRef = this.modalService.show(template, {
      class: 'modal-xs modal-dialog-centered',
    });
  }

  addToast(options) {
    if (options.closeOther) {
      this.toastyService.clearAll();
    }
    this.position = options.position ? options.position : this.position;
    const toastOptions: ToastOptions = {
      title: options.title,
      msg: options.msg,
      showClose: options.showClose,
      timeout: options.timeout,
      theme: options.theme,
      onAdd: (toast: ToastData) => {
        /* added */
      },
      onRemove: (toast: ToastData) => {
        /* removed */
      }
    };

    switch (options.type) {
      case 'default': this.toastyService.default(toastOptions); break;
      case 'info': this.toastyService.info(toastOptions); break;
      case 'success': this.toastyService.success(toastOptions); break;
      case 'wait': this.toastyService.wait(toastOptions); break;
      case 'error': this.toastyService.error(toastOptions); break;
      case 'warning': this.toastyService.warning(toastOptions); break;
    }
  }
}
