import { Component, OnInit } from '@angular/core';
import { ServiceApi } from '../../../../shared/services/ServiceApi';
import { UserLoginBE } from '../../../../Entity/UserLoginBE'
import { Router } from '@angular/router';

@Component({
  selector: 'app-changepassword',
  templateUrl: './change_password.component.html'
})
export class ChangePasswordComponent implements OnInit {

  objUserLogin = new UserLoginBE();
  public identity;
  public _user;
  data: any = [];
  strPassword = ""
  strConfirmPassword = ""
  showError = false
  strError = ""

  constructor(
    private services: ServiceApi,
    private router: Router
  ) { 
    if(this.services.getStorage("emailRecovery") == undefined){
      this.router.navigate(['./']);
    }

  }

  validate() {
    
    this.showError = false
    if (this.strPassword == this.strConfirmPassword) {
      if (this.strPassword.length < 8) {
        this.showError = true
        this.strError = "La contraseña debe tener minimo 8 caracteres"
        return;
      }


      var email = this.services.getStorage("emailRecovery")

      this.services.executeMethoddWithOutPut(this.services.security, "User/" + encodeURI(email) + "/" + encodeURI(this.strPassword), null, (x) => {
        console.log(x)
        if (x.status) {
          this.services.setStorage("emailRecovery", null)
          alert("La contraseña se cambio con exito")
          this.router.navigate(['./']);

        } else {
          this.showError = true
          this.strError = x.message
        }
      });
    } else {
      this.showError = true
      this.strError = "La Contraseña y la confirmacion no coinciden"
    }
  }

  ngOnInit() {
    document.querySelector('body').setAttribute('themebg-pattern', 'theme1');
  }


}
