import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { ToastData, ToastOptions, ToastyService } from 'ng2-toasty';
import { UserBE } from '../../../../Entity/UserBE';
import { ServiceApi } from '../../../../shared/services/ServiceApi';
import { EpaycoRequestBE } from '../../../../Entity/EpaycoRequestBE';
import { NgbCarousel, NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { CardInformationBE } from '../../../../Entity/CardInformationBE';
import { Router } from '@angular/router';
declare var $: any;

declare function myfunction();

@Component({
  selector: 'app-credit_card',
  templateUrl: './credit_card.component.html',
  styleUrls: [
    '../../../../../../node_modules/ng2-toasty/style-bootstrap.css',
    '../../../../../../node_modules/ng2-toasty/style-default.css',
    '../../../../../../node_modules/ng2-toasty/style-material.css',
    './credit_card.component.scss'
  ],
  encapsulation: ViewEncapsulation.None,
  providers: [NgbCarouselConfig],
  animations: [
    trigger('fadeInOutTranslate', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('400ms ease-in-out', style({ opacity: 1 }))
      ]),
      transition(':leave', [
        style({ transform: 'translate(0)' }),
        animate('400ms ease-in-out', style({ opacity: 0 }))
      ])
    ])
  ]
})
export class CreditCardComponent implements OnInit {
  images = [700, 533, 807, 124].map((n) => `https://picsum.photos/id/${n}/900/500`);

  position = 'bottom-right';
  title: string;
  msg: string;
  showClose = true;
  theme = 'bootstrap';
  type = 'default';
  closeOther = false;
  objUser = new UserBE();
  public userId: string;
  objResponseUser: any = [];
  lstCardsProvider: any = [];

  strNumber1 = ""
  strNumber2 = ""
  strNumber3 = ""
  strNumber4 = ""

  editProfile = true;
  editProfileIcon = 'icofont-edit';

  editAbout = true;
  editAboutIcon = 'icofont-edit';

  public basicContent: string;
  strError = ""


  objRequestPayment = new EpaycoRequestBE()


  public rowsOnPage = 10;
  public filterQuery = '';
  public sortBy = '';
  public sortOrder = 'desc';
  profitChartOption: any;

  constructor(
    private toastyService: ToastyService,
    private services: ServiceApi,
    config: NgbCarouselConfig,
    private router: Router
  ) {
    config.interval = 10000;
    config.wrap = false;
    config.keyboard = false;
    config.pauseOnHover = false;

   

    
  }


  @ViewChild('carousel',{static: false}) carousel: NgbCarousel;
  ngAfterViewInit() {
    this.carousel.pause();
  }

  ngOnInit() {
    this.objUser = this.services.getStorage("userInfo")
    this.userId = JSON.parse(localStorage.getItem("userInfo")).id;
    this.objRequestPayment.dues = 1
    
    
    this.services.executeMethodGet(this.services.security, "Epayco/GetCards/" + this.userId, (x) => {
      console.log(x)
      this.lstCardsProvider = x.information.items;
      console.log(this.lstCardsProvider)
      this.objRequestPayment.cardToken = this.lstCardsProvider[0].id

      $('.cc').on('click', function (event) {
        $('.cc').removeClass('active');
        $(this).addClass('active');
        $('.cc-dots > li').removeClass('active');
        $('.cc-dots > li:nth-child(' + ($(this).index() + 1) + ')').addClass('active');
      })

    });
  }

  GoToConfirmation(x) {    
    var objResponse = x
    this.router.navigateByUrl('/successcashpay', { state: objResponse });
  }

  onSlideEvent(event) {
    this.objRequestPayment.cardToken = event.current
  }


  handleUpload(event) {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.objUser.urlImage = reader.result.toString();
    };
  }

  toggleEditProfile() {
    this.editProfileIcon = (this.editProfileIcon === 'icofont-close') ? 'icofont-edit' : 'icofont-close';
    this.editProfile = !this.editProfile;

    if(this.editProfile){
      this.objRequestPayment.cardInformation = null;
    } else {
      this.objRequestPayment.cardInformation = new CardInformationBE();
    }
  }

  getImage(value){
    if(value == "MASTER"){
      return "./assets/images/ico_mastercard.png"
    } else {
      return "./assets/images/visa_logo.png"
    }
  }

  MakePaymentCARD() {
    debugger

    if(this.editProfile && (this.objRequestPayment.cardToken == "" && this.objRequestPayment.cardToken == undefined))
    {
      this.strError = "Debes seleccionar una tarjeta de credito"
      return;
    }
    if(!this.editProfile){
      this.objRequestPayment.cardInformation.number = this.strNumber1 + this.strNumber2 + this.strNumber3 + this.strNumber4
    }
    
    if(!this.editProfile && (this.objRequestPayment.cardInformation.number == "" && this.objRequestPayment.cardInformation.number == undefined)){
      this.strError = "Debes ingresar un numero valido"
      return;
    }
    
    

      this.strError = ""
      this.objRequestPayment.userId = this.objUser.id
      this.objRequestPayment.typePaymentMethod = 1;
      this.objRequestPayment.dues = +this.objRequestPayment.dues
      this.services.executeMethod(this.services.security, "Epayco", this.objRequestPayment, (x) => {
        if (x.status) {
          if(x.information.status){
            this.GoToConfirmation(x)
          } else {
            this.strError = x.information.message
          }

        } else {
          this.strError =  x.message
        }
      });
  }

  toggleEditAbout() {
    this.editAboutIcon = (this.editAboutIcon === 'icofont-close') ? 'icofont-edit' : 'icofont-close';
    this.editAbout = !this.editAbout;
  }

  addToast(options) {
    if (options.closeOther) {
      this.toastyService.clearAll();
    }
    this.position = options.position ? options.position : this.position;
    const toastOptions: ToastOptions = {
      title: options.title,
      msg: options.msg,
      showClose: options.showClose,
      timeout: options.timeout,
      theme: options.theme,
      onAdd: (toast: ToastData) => {
        /* added */
      },
      onRemove: (toast: ToastData) => {
        /* removed */
      }
    };

    switch (options.type) {
      case 'default': this.toastyService.default(toastOptions); break;
      case 'info': this.toastyService.info(toastOptions); break;
      case 'success': this.toastyService.success(toastOptions); break;
      case 'wait': this.toastyService.wait(toastOptions); break;
      case 'error': this.toastyService.error(toastOptions); break;
      case 'warning': this.toastyService.warning(toastOptions); break;
    }
  }

}
