export class PricesProvidersBE {
    constructor(){
    }
    id: string;
    productId: string;
    subCategoryId: string;
    providerId: string;
    brandId: string;
    countryId: string;
    deliveryTimeId: string;   
    measureId: string; 
    price: number; 
    priceMax: number;  
    productImage: string; 
    productDescription: string;
}