export class UserBE {
    constructor(){
    }
    id: string;
    profileId: string;
    names: string;
    lastNames: string;
    identificacion: string;
    email: string;
    password: string;    
    phone: string; 
    mobilePhone: string;
    company: string;
    urlImage: string;
    urlImageRut: string;
    urlImageCedulaFrontal: string;
    urlImageCedulaRespaldo: string;
    allowService: boolean;
    isEnabled: boolean;
    commisionPercent: number;
}