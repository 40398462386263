import { Component, OnInit, ViewEncapsulation, TemplateRef } from '@angular/core';
import { ToastData, ToastOptions, ToastyService } from 'ng2-toasty';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ServiceApi } from '../../../shared/services/ServiceApi';
import { Router } from '@angular/router';
import { NgbRatingConfig } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, Validators } from '@angular/forms';
import { SendOrderRatingBE } from '../../../Entity/SendOrderRatingBE';

@Component({
  selector: 'app-rating_customer',
  templateUrl: './rating_customer.component.html',
  styleUrls: [
    '../../../../../node_modules/ng2-toasty/style-bootstrap.css',
    '../../../../../node_modules/ng2-toasty/style-default.css',
    '../../../../../node_modules/ng2-toasty/style-material.css'
  ],
  styles: [`
  .star {
    font-size: 3rem;
    color: #b0c4de;
  }
  .filled {
    color: #0A0909;
  }
  .bad {
    color: #deb0b0;
  }
  .filled.bad {
    color: #ff1e1e;
  }
`],
  encapsulation: ViewEncapsulation.None
})
export class RatingCustomerComponent implements OnInit {

  ctrl = new FormControl(null, Validators.required);

  position = 'bottom-right';
  title: string;
  msg: string;
  showClose = true;
  theme = 'bootstrap';
  type = 'default';
  closeOther = false;
  
  objSendOrderRating = new SendOrderRatingBE();

  lstRatingOrder: any = [];
  lstCategories: any = [];
  objQuotationDetail: any = {};

  objQuotationProductDetail: any = {};
  objQuotationProductPDF: any = {};

  public category = {};


  constructor(
    private toastyService: ToastyService,
    private services: ServiceApi,    
    private router: Router,
    config: NgbRatingConfig
  ) {
    this.router.getCurrentNavigation().extras.state;
    config.max = 5;
    config.readonly = false;
  }

  toggle() {
    console.log(this.ctrl.value)
    if (this.ctrl.disabled) {
      this.ctrl.enable();
    } else {
      this.ctrl.disable();
    }
  }

  
  ngOnInit() {
    debugger;
    this.objQuotationDetail = Object.assign({}, history.state);
    if (this.objQuotationDetail.navigationId == 1) {
      this.objQuotationDetail = JSON.parse(localStorage.getItem("QuotationDetail"))
    } else {
      localStorage.setItem('QuotationDetail', JSON.stringify(this.objQuotationDetail));
    }   
    
    console.log(this.objQuotationDetail)
  }

  QuoteProduct(value) {   
    debugger;
    this.objSendOrderRating.userId = this.services.getStorage('userInfo').id;
    this.objSendOrderRating.userName = this.services.getStorage('userInfo').names;
    this.objSendOrderRating.orderId = value;
    this.objSendOrderRating.rate = this.ctrl.value;
    this.objSendOrderRating.isClient = true;

    this.services.executeMethod(this.services.catalog, "OrderRating", this.objSendOrderRating, (x) => {
      console.log(x)
      //this.objResponseServiceQuotationCustomer = x
    });
  }

  addToast(options) {
    if (options.closeOther) {
      this.toastyService.clearAll();
    }
    this.position = options.position ? options.position : this.position;
    const toastOptions: ToastOptions = {
      title: options.title,
      msg: options.msg,
      showClose: options.showClose,
      timeout: options.timeout,
      theme: options.theme,
      onAdd: (toast: ToastData) => {
        /* added */
      },
      onRemove: (toast: ToastData) => {
        /* removed */
      }
    };

    switch (options.type) {
      case 'default': this.toastyService.default(toastOptions); break;
      case 'info': this.toastyService.info(toastOptions); break;
      case 'success': this.toastyService.success(toastOptions); break;
      case 'wait': this.toastyService.wait(toastOptions); break;
      case 'error': this.toastyService.error(toastOptions); break;
      case 'warning': this.toastyService.warning(toastOptions); break;
    }
  }
}
