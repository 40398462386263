import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { animate, AUTO_STYLE, state, style, transition, trigger } from '@angular/animations';
import { ToastData, ToastOptions, ToastyService } from 'ng2-toasty';
import { ActivatedRoute } from '@angular/router';
import { ServiceQutationFilesBE } from '../../../../Entity/ServiceQutationFilesBE'
import { ServiceApi } from '../../../../shared/services/ServiceApi';

interface CertificateSubmissionResult {
  fileName: string;
  fileSize: number;
}

@Component({
  selector: 'app-upload-services-files',
  templateUrl: './upload-services-files.component.html',
  styleUrls: ['./upload-services-files.component.scss',
  '../../../../../../node_modules/ng2-toasty/style-bootstrap.css'
  
],
  animations: [
    trigger('mobileMenuTop', [
      state('no-block, void',
        style({
          overflow: 'hidden',
          height: '0px',
        })
      ),
      state('yes-block',
        style({
          height: AUTO_STYLE,
        })
      ),
      transition('no-block <=> yes-block', [
        animate('400ms ease-in-out')
      ])
    ])
  ]
})
export class UploadServicesFilesComponent implements OnInit {

  position = 'bottom-right';
  title: string;
  msg: string;
  showClose = true;
  theme = 'bootstrap';
  type = 'default';
  closeOther = false;

  studentId = 9998;
  uploadProgress = 0;
  selectedFiles: File[];
  uploading = false;
  errorMsg = '';
  submissionResults: CertificateSubmissionResult[] = [];
  strId: string;
  imgbs: string;
  lstimgs: any[] = [];
  objServiceQutationFiles = new ServiceQutationFilesBE();
  objResponseUploadFiles: any = [];


  navType: string; /* st1, st2(default), st3, st4 */
  themeLayout: string; /* vertical(default) */
  layoutType: string; /* dark, light */
  verticalPlacement: string; /* left(default), right */
  verticalLayout: string; /* wide(default), box */
  deviceType: string; /* desktop(default), tablet, mobile */
  verticalNavType: string; /* expanded(default), offcanvas */
  verticalEffect: string; /* shrink(default), push, overlay */
  vNavigationView: string; /* view1(default) */
  pcodedHeaderPosition: string; /* fixed(default), relative*/
  pcodedSidebarPosition: string; /* fixed(default), absolute*/
  headerTheme: string; /* theme1(default), theme2, theme3, theme4, theme5, theme6 */
  logoTheme: string; /* theme1(default), theme2, theme3, theme4, theme5, theme6 */

  innerHeight: string;
  windowWidth: number;

  toggleOn: boolean;

  headerFixedMargin: string;
  navBarTheme: string; /* theme1, themelight1(default)*/
  activeItemTheme: string; /* theme1, theme2, theme3, theme4(default), ..., theme11, theme12 */

  isCollapsedMobile: string;
  isCollapsedSideBar: string;

  chatToggle: string;
  chatToggleInverse: string;
  chatInnerToggle: string;
  chatInnerToggleInverse: string;

  menuTitleTheme: string; /* theme1, theme2, theme3, theme4, theme5(default), theme6 */
  itemBorder: boolean;
  itemBorderStyle: string; /* none(default), solid, dotted, dashed */
  subItemBorder: boolean;
  subItemIcon: string; /* style1, style2, style3, style4, style5, style6(default) */
  dropDownIcon: string; /* style1(default), style2, style3 */
  configOpenRightBar: string;
  isSidebarChecked: boolean;
  isHeaderChecked: boolean;
  isSuccess: boolean;
  alreadyUpload: boolean;

  strQuotationId: string = "";

  @ViewChild('searchFriends', /* TODO: add static flag */ { static: false }) search_friends: ElementRef;

  constructor(
    private activatedRoute: ActivatedRoute,
    private services: ServiceApi,
    private toastyService: ToastyService,
    ) {
    this.navType = 'st2';
    this.themeLayout = 'vertical';
    this.vNavigationView = 'view1';
    this.verticalPlacement = 'left';
    this.verticalLayout = 'wide';
    this.deviceType = 'desktop';
    this.verticalNavType = 'expanded';
    this.verticalEffect = 'shrink';
    this.pcodedHeaderPosition = 'fixed';
    this.pcodedSidebarPosition = 'fixed';
    this.headerTheme = 'theme1';
    this.logoTheme = 'theme1';

    this.toggleOn = true;

    this.headerFixedMargin = '80px';
    this.navBarTheme = 'themelight1';
    this.activeItemTheme = 'theme4';

    this.isCollapsedMobile = 'no-block';
    this.isCollapsedSideBar = 'no-block';

    this.chatToggle = 'out';
    this.chatToggleInverse = 'in';
    this.chatInnerToggle = 'off';
    this.chatInnerToggleInverse = 'on';

    this.menuTitleTheme = 'theme5';
    this.itemBorder = true;
    this.itemBorderStyle = 'none';
    this.subItemBorder = true;
    this.subItemIcon = 'style6';
    this.dropDownIcon = 'style1';
    this.isSidebarChecked = true;
    this.isHeaderChecked = true;
    
    

    this.activatedRoute.paramMap.subscribe(params => {
      this.strId = params.get('parameter');
      if (this.strId) {
        this.strQuotationId = this.strId
        this.verifyFiles();
      }
    });

  }

  ngOnInit() {
    this.selectedFiles = [];
  }

  verifyFiles(){
    this.alreadyUpload = false
    this.services.executeMethodGet(this.services.catalog,"ServiceQuotation/VerifyFiles/"+this.strQuotationId,(x) => {
      if(x.information == false){
        this.alreadyUpload = true
      } else { 
        this.alreadyUpload = false
      }
    });
  }

  chooseFile(event, files: FileList) {
    this.errorMsg = '';
    this.uploadProgress = 0;
    if (files.length === 0) {
      return;
    }
    for (let i = 0; i < files.length; i++) {
      const file = event.target.files[i];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.imgbs = reader.result.toString();

        this.selectedFiles.push(files[i]);
        var name = files[i].name.split(".")
        var FileExt = name[name.length-1];

     
      
      if (i == 0) {
        this.objServiceQutationFiles.extFile1 = "." + FileExt;
        this.objServiceQutationFiles.urlFile1 = this.imgbs;
      }
      if (i == 1) {
        this.objServiceQutationFiles.extFile2 = "." + FileExt;
        this.objServiceQutationFiles.urlFile2 = this.imgbs;
      }
      if (i == 2) {
        this.objServiceQutationFiles.extFile3 = "." + FileExt;
        this.objServiceQutationFiles.urlFile3 = this.imgbs;
      }
      if (i == 3) {
        this.objServiceQutationFiles.extFile4 = "." + FileExt;
        this.objServiceQutationFiles.urlFile4 = this.imgbs;
      }
      if (i == 4) {
        this.objServiceQutationFiles.extFile5 = "." + FileExt;
        this.objServiceQutationFiles.urlFile5 = this.imgbs;
      }

      console.log(this.objServiceQutationFiles)
      };
      
      //console.log(this.selectedFiles[0])

      //console.log(files[i].name)
      //console.log(i)

      

    }
  }

  upload() {
    if (!this.selectedFiles || this.selectedFiles.length === 0) {
      this.errorMsg = 'Por favor selecciona un archivo';
      return;
    }

    /*const formData = new FormData();
    this.selectedFiles.forEach((f) => formData.append('certificates', f));*/
    this.objServiceQutationFiles.serviceQuotationId = this.strQuotationId
    this.uploading = true;
    this.services.executeMethodPutFiles(this.services.catalog, "ServiceQuotation/LoadFiles", this.objServiceQutationFiles, (x) => {
      this.objResponseUploadFiles = x      
      if (this.objResponseUploadFiles.status == true) {        
        this.isSuccess = true     
        this.uploading = false;
      } 
    });


  }


  humanFileSize(bytes: number): string {
    if (Math.abs(bytes) < 1024) {
      return bytes + ' B';
    }
    const units = ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    let u = -1;
    do {
      bytes /= 1024;
      u++;
    } while (Math.abs(bytes) >= 1024 && u < units.length - 1);
    return bytes.toFixed(1) + ' ' + units[u];
  }

  onResize(event) {
    this.innerHeight = event.target.innerHeight + 'px';
    /* menu responsive */
    this.windowWidth = event.target.innerWidth;
    let reSizeFlag = true;
    if (this.deviceType === 'tablet' && this.windowWidth >= 768 && this.windowWidth <= 1024) {
      reSizeFlag = false;
    } else if (this.deviceType === 'mobile' && this.windowWidth < 768) {
      reSizeFlag = false;
    }
    /* for check device */
    if (reSizeFlag) {
      this.setMenuAttributes(this.windowWidth);
    }
  }

  setMenuAttributes(windowWidth) {
    if (windowWidth >= 768 && windowWidth <= 1024) {
      this.deviceType = 'tablet';
      this.verticalNavType = 'offcanvas';
      this.verticalEffect = 'push';
    } else if (windowWidth < 768) {
      this.deviceType = 'mobile';
      this.verticalNavType = 'offcanvas';
      this.verticalEffect = 'overlay';
    } else {
      this.deviceType = 'desktop';
      this.verticalNavType = 'expanded';
      this.verticalEffect = 'shrink';
    }
  }

  onMobileMenu() {
    this.isCollapsedMobile = this.isCollapsedMobile === 'yes-block' ? 'no-block' : 'yes-block';
  }

  addToast(options) {
    if (options.closeOther) {
      this.toastyService.clearAll();
    }
    this.position = options.position ? options.position : this.position;
    const toastOptions: ToastOptions = {
      title: options.title,
      msg: options.msg,
      showClose: options.showClose,
      timeout: options.timeout,
      theme: options.theme,
      onAdd: (toast: ToastData) => {
        /* added */
      },
      onRemove: (toast: ToastData) => {
        /* removed */
      }
    };

    switch (options.type) {
      case 'default': this.toastyService.default(toastOptions); break;
      case 'info': this.toastyService.info(toastOptions); break;
      case 'success': this.toastyService.success(toastOptions); break;
      case 'wait': this.toastyService.wait(toastOptions); break;
      case 'error': this.toastyService.error(toastOptions); break;
      case 'warning': this.toastyService.warning(toastOptions); break;
    }
  }

}


