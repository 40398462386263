import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ToastData, ToastOptions, ToastyService } from 'ng2-toasty';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ProductBE } from '../../../Entity/ProductBE'
import { ServiceApi } from '../../../shared/services/ServiceApi';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';


@Component({
  selector: 'app-quotesproviderservices',
  templateUrl: './quotesproviderservices.component.html',
  styleUrls: [
    '../../../../../node_modules/ng2-toasty/style-bootstrap.css',
    '../../../../../node_modules/ng2-toasty/style-default.css',
    '../../../../../node_modules/ng2-toasty/style-material.css'
  ],
  encapsulation: ViewEncapsulation.None
})
export class QuotesProviderServicesComponent implements OnInit {
  position = 'bottom-right';
  title: string;
  msg: string;
  showClose = true;
  theme = 'bootstrap';
  type = 'default';
  closeOther = false;
  objDetailQuotationProviderService: any = {};
  objQuotation: any = {};
  objProduct = new ProductBE();
  lstQuotesProviderServices: any = [];
  dtOptions: DataTables.Settings = {};
  modalRef: BsModalRef;
  dtTrigger: Subject<any> = new Subject<any>();
  dtElement: DataTableDirective;
  isDtInitialized: boolean = false;
  loading: boolean = false;


  constructor(
    private toastyService: ToastyService,
    private services: ServiceApi,
    private router: Router,
    private modalService: BsModalService
  ) {
    this.router.getCurrentNavigation().extras.state
  }

  ngOnInit() {
    this.loading = true
    this.objQuotation = Object.assign({}, history.state);
    if (this.objQuotation.navigationId == 1) {
      this.objQuotation = JSON.parse(localStorage.getItem("Quotation"))
    } else {
      localStorage.setItem('Quotation', JSON.stringify(this.objQuotation));
    }

    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 100
    };

    this.services.executeMethodGet(this.services.orders, "ServiceQuotationDetail?Search=" + this.objQuotation.id, (x) => {
      this.loading = false
      this.lstQuotesProviderServices = x.information.items;  
      console.log(this.lstQuotesProviderServices)      
      if (this.isDtInitialized) {
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
          this.dtTrigger.next();
        });
      } else {
        this.isDtInitialized = true
        this.dtTrigger.next();
      }      
    });    
  }

  GetQuotationDetail(quotationproviderId) {
    this.services.executeMethodGet(this.services.catalog, "ServiceQuotationDetail?Search=" + quotationproviderId, (x) => {
      console.log(quotationproviderId)
      console.log(this.objDetailQuotationProviderService)
      this.objDetailQuotationProviderService = x.information.items[0];            
    });
  }

  ModalImageProduct(template, product) {
    this.modalRef = this.modalService.show(template);
    this.objProduct = product
  }

  ModalDetailQuotationProviderService(template, quotationproviderId) {
    this.modalRef = this.modalService.show(template, {
      class: 'modal-lg modal-dialog-centered',
    });
    this.GetQuotationDetail(quotationproviderId)
  }

  addToast(options) {
    if (options.closeOther) {
      this.toastyService.clearAll();
    }
    this.position = options.position ? options.position : this.position;
    const toastOptions: ToastOptions = {
      title: options.title,
      msg: options.msg,
      showClose: options.showClose,
      timeout: options.timeout,
      theme: options.theme,
      onAdd: (toast: ToastData) => {
        /* added */
      },
      onRemove: (toast: ToastData) => {
        /* removed */
      }
    };

    switch (options.type) {
      case 'default': this.toastyService.default(toastOptions); break;
      case 'info': this.toastyService.info(toastOptions); break;
      case 'success': this.toastyService.success(toastOptions); break;
      case 'wait': this.toastyService.wait(toastOptions); break;
      case 'error': this.toastyService.error(toastOptions); break;
      case 'warning': this.toastyService.warning(toastOptions); break;
    }
  }
}
