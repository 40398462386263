export class ServiceQutationFilesBE {
    constructor() {
    }
    serviceQuotationId: string;
    urlFile1: string;
    extFile1: string;
    urlFile2: string;
    extFile2: string;
    urlFile3: string;
    extFile3: string;
    urlFile4: string;
    extFile4: string;
    urlFile5: string;
    extFile5: string;
}