import { Component, OnInit, ViewEncapsulation, TemplateRef } from '@angular/core';
import { ToastData, ToastOptions, ToastyService } from 'ng2-toasty';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { SubcategoryBE } from '../../../Entity/SubcategoryBE'
import { ServiceApi } from '../../../shared/services/ServiceApi';
import { Router, ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';


@Component({
  selector: 'app-subcategories_customer',
  templateUrl: './subcategories_customer.component.html',
  styleUrls: [
    '../../../../../node_modules/ng2-toasty/style-bootstrap.css',
    '../../../../../node_modules/ng2-toasty/style-default.css',
    '../../../../../node_modules/ng2-toasty/style-material.css'
  ],
  encapsulation: ViewEncapsulation.None
})
export class SubcategoriesCustomerComponent implements OnInit {

  position = 'bottom-right';
  title: string;
  msg: string;
  showClose = true;
  theme = 'bootstrap';
  type = 'default';
  closeOther = false;
  objSubcategoryCustomer = new SubcategoryBE();
  objCategoryCustomer: any = {};
  lstSubcategoriesCustomer: any = [];  
  lstTrademarksCustomer: any = [];

  public subcategoryCustomer = {};


  constructor(
    private toastyService: ToastyService,
    private services: ServiceApi,
    private modalService: BsModalService,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    this.router.getCurrentNavigation().extras.state
  }

  gotoProductsCustomer(value) {
    var objInfo: any = {}
    objInfo.subcategoryCustomer = value
    objInfo.categoryCustomer = this.objCategoryCustomer
    
    this.router.navigateByUrl('customerproducts', { state: objInfo });
  }
 

  ngOnInit() {
    debugger
    this.objCategoryCustomer = Object.assign({}, history.state);
    if (this.objCategoryCustomer.navigationId == 1) {
      this.objCategoryCustomer = JSON.parse(localStorage.getItem("CategoryCustomer"))
    } else {
      localStorage.setItem('CategoryCustomer', JSON.stringify(this.objCategoryCustomer));
    }   

    this.services.executeMethodGet(this.services.enviroment, "SubCategory?Search=" + this.objCategoryCustomer.id , (x) => {
      this.lstSubcategoriesCustomer = x.information.items;           
    });        

    this.services.executeMethodGet(this.services.enviroment, "Brand?Search=" + this.objCategoryCustomer.id , (x) => {      
      this.lstTrademarksCustomer = x.information.items;          
    }); 

  }

  addToast(options) {
    if (options.closeOther) {
      this.toastyService.clearAll();
    }
    this.position = options.position ? options.position : this.position;
    const toastOptions: ToastOptions = {
      title: options.title,
      msg: options.msg,
      showClose: options.showClose,
      timeout: options.timeout,
      theme: options.theme,
      onAdd: (toast: ToastData) => {
        /* added */
      },
      onRemove: (toast: ToastData) => {
        /* removed */
      }
    };

    switch (options.type) {
      case 'default': this.toastyService.default(toastOptions); break;
      case 'info': this.toastyService.info(toastOptions); break;
      case 'success': this.toastyService.success(toastOptions); break;
      case 'wait': this.toastyService.wait(toastOptions); break;
      case 'error': this.toastyService.error(toastOptions); break;
      case 'warning': this.toastyService.warning(toastOptions); break;
    }
  }
}
