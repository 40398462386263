import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import {animate, style, transition, trigger} from '@angular/animations';
import { ToastData, ToastOptions, ToastyService } from 'ng2-toasty';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { SettingsBE } from '../../../Entity/SettingsBE';
import { ServiceApi } from '../../../shared/services/ServiceApi';

@Component({
  selector: 'app-config',
  templateUrl: './config.component.html',  
  styleUrls: [
    '../../../../../node_modules/ng2-toasty/style-bootstrap.css',
    '../../../../../node_modules/ng2-toasty/style-default.css',
    '../../../../../node_modules/ng2-toasty/style-material.css'
  ],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('fadeInOutTranslate', [
      transition(':enter', [
        style({opacity: 0}),
        animate('400ms ease-in-out', style({opacity: 1}))
      ]),
      transition(':leave', [
        style({transform: 'translate(0)'}),
        animate('400ms ease-in-out', style({opacity: 0}))
      ])
    ])
  ]
})
export class ConfigComponent implements OnInit {
  position = 'bottom-right';
  title: string;
  msg: string;
  showClose = true;
  theme = 'bootstrap';
  type = 'default';
  closeOther = false;
  objSetting = new SettingsBE();
  lstSettingsEmail: any = [];
  lstSettingsOthers: any = [];
  lstSettingsContact: any = [];
  modalRef: BsModalRef;
  objResponseSettings: any = [];

  editProfile = true;
  editProfileIcon = 'icofont-edit';

  editAbout = true;
  editAboutIcon = 'icofont-edit';

  public basicContent: string;


  public rowsOnPage = 10;
  public filterQuery = '';
  public sortBy = '';
  public sortOrder = 'desc';
  profitChartOption: any;

  constructor(
    private toastyService: ToastyService,
    private services: ServiceApi,
    private modalService: BsModalService
  ) {
  }

  ngOnInit() {    
    this.services.executeMethodGet(this.services.enviroment, "Setting?Search=bb2d2b18-b720-4e18-4d5a-08d88b0e4370", (x) => {      
      this.lstSettingsEmail = x.information.items;      
    });

    this.services.executeMethodGet(this.services.enviroment, "Setting?Search=380e500c-9360-4aad-82ce-978cc11607f8", (x) => {      
      this.lstSettingsOthers = x.information.items;      
    });

    this.services.executeMethodGet(this.services.enviroment, "Setting?Search=c9514a90-ea4b-447b-8a4a-7e25db876897", (x) => {      
      this.lstSettingsContact = x.information.items;      
    });
  }

  UpdateSetting() {
    this.services.executeMethodPut(this.services.enviroment, "Setting", this.objSetting, (x) => {
      this.objResponseSettings = x     
      if (this.objResponseSettings.status == true) {
        this.modalRef.hide();
        setTimeout(() => {                          
          this.addToast({ title: 'Se Actualizo Con Exito!', msg: '', showClose: true, timeout: 1500, theme: 'material', position: 'center-center', type: 'success' });
        }, 800);      
        this.ngOnInit();
      }      
    });
  }

  ModalEditSetting(template, setting) {
    this.modalRef = this.modalService.show(template, {
      class: 'modal-dialog-centered',
    });
    this.objSetting = setting
  }

  toggleEditProfile() {
    this.editProfileIcon = (this.editProfileIcon === 'icofont-close') ? 'icofont-edit' : 'icofont-close';
    this.editProfile = !this.editProfile;
  }

  toggleEditAbout() {
    this.editAboutIcon = (this.editAboutIcon === 'icofont-close') ? 'icofont-edit' : 'icofont-close';
    this.editAbout = !this.editAbout;
  }

  addToast(options) {
    if (options.closeOther) {
      this.toastyService.clearAll();
    }
    this.position = options.position ? options.position : this.position;
    const toastOptions: ToastOptions = {
      title: options.title,
      msg: options.msg,
      showClose: options.showClose,
      timeout: options.timeout,
      theme: options.theme,
      onAdd: (toast: ToastData) => {
        /* added */
      },
      onRemove: (toast: ToastData) => {
        /* removed */
      }
    };

    switch (options.type) {
      case 'default': this.toastyService.default(toastOptions); break;
      case 'info': this.toastyService.info(toastOptions); break;
      case 'success': this.toastyService.success(toastOptions); break;
      case 'wait': this.toastyService.wait(toastOptions); break;
      case 'error': this.toastyService.error(toastOptions); break;
      case 'warning': this.toastyService.warning(toastOptions); break;
    }
  }
}
