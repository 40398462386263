import { Component, OnInit, ViewEncapsulation, TemplateRef } from '@angular/core';
import { ToastData, ToastOptions, ToastyService } from 'ng2-toasty';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { PaymentMethodBE } from '../../../../Entity/PaymentMethodBE'
import { ServiceApi } from '../../../../shared/services/ServiceApi';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';


@Component({
  selector: 'app-paymentmethods',
  templateUrl: './paymentmethods.component.html',
  styleUrls: [
    '../../../../../../node_modules/ng2-toasty/style-bootstrap.css',
    '../../../../../../node_modules/ng2-toasty/style-default.css',
    '../../../../../../node_modules/ng2-toasty/style-material.css'
  ],
  encapsulation: ViewEncapsulation.None
})
export class PaymentmethodsComponent implements OnInit {

  position = 'bottom-right';
  title: string;
  msg: string;
  showClose = true;
  theme = 'bootstrap';
  type = 'default';
  closeOther = false;
  objPaymentMethod = new PaymentMethodBE();
  objResponsePaymentMethods: any = [];
  lstPaymentMethods: any = [];
  dtOptions: DataTables.Settings = {};
  modalRef: BsModalRef;
  dtTrigger: Subject<any> = new Subject<any>();
  dtElement: DataTableDirective;
  isDtInitialized: boolean = false


  constructor(
    private toastyService: ToastyService,
    private services: ServiceApi,
    private router: Router,
    private modalService: BsModalService
  ) {

  }


  SavePaymentMethod() {
    this.services.executeMethod(this.services.enviroment, "PaymentMethod", this.objPaymentMethod, (x) => {
      this.objResponsePaymentMethods = x
      if (this.objResponsePaymentMethods.status == true) {
        this.modalRef.hide();
        setTimeout(() => {
          this.addToast({ title: 'Registro Exitos!', msg: '', showClose: true, timeout: 1500, theme: 'material', position: 'center-center', type: 'success' });
        }, 800);
        this.GetPaymentMethods();
      }
      this.objPaymentMethod = new PaymentMethodBE();
    });
  }

  DeletePaymentMethod(category) {
    if(!confirm("Estás seguro que deseas eliminar este item?")){
      return false;
    }
    this.services.executeMethodDelete(this.services.enviroment, "PaymentMethod/", category, (x) => {
      this.objResponsePaymentMethods = x
      if (this.objResponsePaymentMethods.status == true) {
        this.addToast({ title: 'Registro Eliminado!', msg: '', showClose: true, timeout: 1500, theme: 'material', position: 'center-center', type: 'success' });
        this.ngOnInit();
      }
      this.objPaymentMethod = new PaymentMethodBE();
    });
  }

  UpdatePaymentMethod() {
    this.services.executeMethodPut(this.services.enviroment, "PaymentMethod", this.objPaymentMethod, (x) => {
      this.objResponsePaymentMethods = x
      if (this.objResponsePaymentMethods.status == true) {
        this.modalRef.hide();
        setTimeout(() => {
          this.addToast({ title: 'Se Actualizo Con Exito!', msg: '', showClose: true, timeout: 1500, theme: 'material', position: 'center-center', type: 'success' });
        }, 800);
        this.ngOnInit();
      }
    });
  }

  GetPaymentMethods() {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 100
    };

    this.services.executeMethodGet(this.services.enviroment, "PaymentMethod", (x) => {
      this.lstPaymentMethods = x.information.items;
      if (this.isDtInitialized) {
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
          this.dtTrigger.next();
        });
      } else {
        this.isDtInitialized = true
        this.dtTrigger.next();
      }
    });
  }

  ngOnInit() {
    this.GetPaymentMethods();
  }

  ModalEditPaymentMethod(template, paymentmethod) {
    this.modalRef = this.modalService.show(template, {
      class: 'modal-dialog-centered',
    });
    this.objPaymentMethod = paymentmethod
  }

  ModalAddPaymentMethod(template) {
    this.objPaymentMethod = new PaymentMethodBE();
    this.modalRef = this.modalService.show(template, {
      class: 'modal-dialog-centered',
    });
  }

  addToast(options) {
    if (options.closeOther) {
      this.toastyService.clearAll();
    }
    this.position = options.position ? options.position : this.position;
    const toastOptions: ToastOptions = {
      title: options.title,
      msg: options.msg,
      showClose: options.showClose,
      timeout: options.timeout,
      theme: options.theme,
      onAdd: (toast: ToastData) => {
        /* added */
      },
      onRemove: (toast: ToastData) => {
        /* removed */
      }
    };

    switch (options.type) {
      case 'default': this.toastyService.default(toastOptions); break;
      case 'info': this.toastyService.info(toastOptions); break;
      case 'success': this.toastyService.success(toastOptions); break;
      case 'wait': this.toastyService.wait(toastOptions); break;
      case 'error': this.toastyService.error(toastOptions); break;
      case 'warning': this.toastyService.warning(toastOptions); break;
    }
  }
}
