import { Component, OnInit } from '@angular/core';
import { ServiceApi } from '../../../../../shared/services/ServiceApi';
import { UserLoginBE } from '../../../../../Entity/UserLoginBE'
import { Router } from '@angular/router';

@Component({
  selector: 'app-signup_payment_methods',
  templateUrl: './signup_payment_methods.component.html'
})
export class SignupPaymentMethodsComponent implements OnInit {

  public loading = false;
  objUserLogin = new UserLoginBE();
  public identity;
  public _user;
  lstPaymentMethods: any[] = []
  lstPaymentMethodsSelected: any[] = []
  data: any = [];

  constructor(
    private services: ServiceApi,
    private router: Router
  ) { }

  ngOnInit() {
    this.services.executeMethodGet(this.services.orders, "PaymentMethod/Register", (x) => {
      this.lstPaymentMethods = x.information.items
    });
  }

  saveValue(x) {
    var item = this.lstPaymentMethodsSelected.find(it => it == x)
    if (item == null) {
      this.lstPaymentMethodsSelected.push(x)
    } else {
      const index: number = this.lstPaymentMethodsSelected.indexOf(x);
      if (index !== -1) {
        this.lstPaymentMethodsSelected.splice(index, 1);
      }
    }
  }

  next() {
    var objUser = this.services.getStorage('tempSignUp')
    var iProcess = 0;

    this.lstPaymentMethodsSelected.forEach((x) => { 

      var request: any = {}
      request.userId = objUser.id
      request.paymentMethodId = x;

      this.loading = true 
      this.services.executeMethodWithOut(this.services.orders, "ProviderPaymentMethod/Register", request, (x) => {
        iProcess++
        if (iProcess == this.lstPaymentMethodsSelected.length) {
          this.loading = false 
          this.router.navigate(['./signupuploadfiles']);
        }
      });

    });
  
  }
}
