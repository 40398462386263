import { Component, OnInit, ViewEncapsulation, TemplateRef } from '@angular/core';
import { ToastData, ToastOptions, ToastyService } from 'ng2-toasty';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { UserBE } from '../../../Entity/UserBE'
import { ServiceApi } from '../../../shared/services/ServiceApi';
import { Router } from '@angular/router';
import { FileHolder } from 'angular2-image-upload';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';

@Component({
  selector: 'app-shopping_customer',
  templateUrl: './shopping_customer.component.html',
  styleUrls: [
    '../../../../../node_modules/ng2-toasty/style-bootstrap.css',
    '../../../../../node_modules/ng2-toasty/style-default.css',
    '../../../../../node_modules/ng2-toasty/style-material.css'
  ],
  encapsulation: ViewEncapsulation.None
})
export class ShoppingCustomerComponent implements OnInit {

  position = 'bottom-right';
  title: string;
  msg: string;
  showClose = true;
  theme = 'bootstrap';
  type = 'default';
  closeOther = false;
  public isCollapsed = true;

  lstOrders: any = [];
  NewlstOrders: any = [];
  strIdToCollapse = ""
  public category = {};
  public provider = {};
  onlyServices = false;
  onlyProducts = false;

  constructor(
    private toastyService: ToastyService,
    private services: ServiceApi,
    private modalService: BsModalService,
    private router: Router
  ) {

  }

  gotoQuotesProvidersServices(value) {
    this.provider = value
    this.router.navigateByUrl('/customershoppingdetail', { state: this.provider });
  }

  ngOnInit() {
    console.log("service" + this.onlyServices)
    console.log("Produc" + this.onlyProducts)
    var userInfo = this.services.getStorage('userInfo')
    this.services.executeMethodGet(this.services.enviroment, "Order?Search=" + userInfo.id, (x) => {
      this.lstOrders = x.information.items;
      this.lstOrders.sort(function (a, b) {
        return b.number - a.number;
      });

      if (this.onlyServices) {
        this.onlyProducts = false;
        this.NewlstOrders = [];
        console.log(this.lstOrders)
        this.lstOrders.forEach((x) => {
          console.log(x.isService)
          if (x.isService) {
            this.NewlstOrders.push(x);
          }
        });
        this.lstOrders = this.NewlstOrders;
        console.log(this.lstOrders)
      }

      if (this.onlyProducts) {
        this.onlyServices = false;
        this.NewlstOrders = [];
        console.log(this.lstOrders)
        this.lstOrders.forEach((x) => {
          console.log(x.isService)
          if (!x.isService) {
            this.NewlstOrders.push(x);
          }
        });
        this.lstOrders = this.NewlstOrders;
        console.log(this.lstOrders)
      }
    });
  }

  filter(value) {
    if (value == 1) {
      this.onlyServices = true;
    } else if (value == 2) {
      this.onlyProducts = true;
    }
    this.ngOnInit();
  }

  checkCollapse(strIdCollapse) {
    if (strIdCollapse == this.strIdToCollapse) {
      this.strIdToCollapse = ""
    } else {
      this.strIdToCollapse = strIdCollapse
    }
  }

  setColor(status) {

    if (status == "Finalizada") {
      return "green"
    } else if (status == "Pendiente Confirmación") {
      return "red"
    } else if (status == "En proceso") {
      return "blue"
    } else if (status == "Cancelada") {
      return "red"
    }
    return "black"
  }

  addToast(options) {
    if (options.closeOther) {
      this.toastyService.clearAll();
    }
    this.position = options.position ? options.position : this.position;
    const toastOptions: ToastOptions = {
      title: options.title,
      msg: options.msg,
      showClose: options.showClose,
      timeout: options.timeout,
      theme: options.theme,
      onAdd: (toast: ToastData) => {
        /* added */
      },
      onRemove: (toast: ToastData) => {
        /* removed */
      }
    };

    switch (options.type) {
      case 'default': this.toastyService.default(toastOptions); break;
      case 'info': this.toastyService.info(toastOptions); break;
      case 'success': this.toastyService.success(toastOptions); break;
      case 'wait': this.toastyService.wait(toastOptions); break;
      case 'error': this.toastyService.error(toastOptions); break;
      case 'warning': this.toastyService.warning(toastOptions); break;
    }
  }
}
