import { Component, OnInit } from '@angular/core';
import { ServiceApi } from '../../../../shared/services/ServiceApi';
import { UserLoginBE } from '../../../../Entity/UserLoginBE'
import { Router } from '@angular/router';

@Component({
  selector: 'app-basic-login',
  templateUrl: './basic-login.component.html',
  styleUrls: ['./basic-login.component.scss']
})
export class BasicLoginComponent implements OnInit {

  public loading = false;
  objUserLogin = new UserLoginBE();
  public identity;
  public _user;  
  data: any = [];

  constructor(
    private services: ServiceApi,
    private router: Router
  ) { }

  UserLogin() {
    //USER
    //this.objUserLogin.profileId = "1DC3CE2E-9A93-4574-9481-D9C8BDD79867";
    //CUSTOMER
    //this.objUserLogin.profileId = "0614E506-5557-4FD1-81F3-08D8820C3640";
    //PROVIDER
    //this.objUserLogin.profileId = "0614E506-4447-4FD1-81F3-08D8820C3640";
    this.loading = true 
    this.services.executeMethodWithOut(this.services.enviroment, "Login/authenticateFree", this.objUserLogin, (x) => {
      this.data = x;
      this.loading = false 
      if (this.data.status == true) {
        this.identity = this.data.information.token;
        this._user = this.data.information;
        var profileID = this.data.information.profileId;
        var newprofileID = profileID.toUpperCase()
        localStorage.setItem('identity', this.identity);
        localStorage.setItem('userInfo', JSON.stringify(this._user));
        localStorage.setItem('profile', newprofileID);
        if(newprofileID == "0614E506-5557-4FD1-81F3-08D8820C3640"){
        this.router.navigate(['./customercategories']);
        } else if(newprofileID == "0614E506-4447-4FD1-81F3-08D8820C3640") {
        this.router.navigate(['./providerproducts']);
        } else {
          this.router.navigate(['./dashboard']);
        }
      }
      else {
        alert(this.data.message);
        //this.objUserLogin = new UserLoginBE();
      }
    });
  }

  ngOnInit() {
    document.querySelector('body').setAttribute('themebg-pattern', 'theme1');
  }

}
