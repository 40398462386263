import { Component, OnInit, ViewEncapsulation, TemplateRef } from '@angular/core';
import { ToastData, ToastOptions, ToastyService } from 'ng2-toasty';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ProductBE } from '../../../Entity/ProductBE'
import { ServiceApi } from '../../../shared/services/ServiceApi';
import { Router } from '@angular/router';
import { FavoriteBE } from '../../../Entity/FavoriteBE';

@Component({
  selector: 'app-products_customer',
  templateUrl: './products_customer.component.html',
  styleUrls: [
    '../../../../../node_modules/ng2-toasty/style-bootstrap.css',
    '../../../../../node_modules/ng2-toasty/style-default.css',
    '../../../../../node_modules/ng2-toasty/style-material.css'
  ],
  encapsulation: ViewEncapsulation.None
})
export class ProductsCusotmerComponent implements OnInit {

  position = 'bottom-right';
  title: string;
  msg: string;
  showClose = true;
  theme = 'bootstrap';
  type = 'default';
  closeOther = false;

  lstCategories: any = [];
  objHistory: any = {};
  public category = {};
  objUser: any = {}


  constructor(
    private toastyService: ToastyService,
    private services: ServiceApi,
    private modalService: BsModalService,
    private router: Router,
  ) {
    this.router.getCurrentNavigation().extras.state
  }

  gotoProductQuotation(value) {    
    var objInfo: any = {}
    objInfo.product = value
    objInfo.categoryCustomer = this.objHistory.categoryCustomer
    this.router.navigateByUrl('customerproductquotation', { state: objInfo });
  }


  ngOnInit() {
    this.objUser = this.services.getStorage("userInfo");
    this.objHistory = Object.assign({}, history.state);
    if (this.objHistory.navigationId == 1) {
      this.objHistory.other = JSON.parse(localStorage.getItem("SubcategoryCustomer"))
    } else {
      localStorage.setItem('SubcategoryCustomer', JSON.stringify(this.objHistory.subcategoryCustomer));
    }  
    this.loadData();
  }

  loadData(){
    this.services.executeMethodGet(this.services.enviroment, "Product?Search=" + 
    this.objHistory.subcategoryCustomer.id + "&userId=" + this.objUser.id, (x) => {
      this.lstCategories = x.information.items;
      //console.log(this.lstCategories)
    });    
  }


  setFavorite(obj){
    var objFavorite = new FavoriteBE();
    objFavorite.productId = obj.id;
    objFavorite.userId = this.objUser.id;

    this.services.executeMethod(this.services.enviroment,"Favorite", objFavorite, (x) => {
      if(x.status){

      }
    })
    
  }

  addToast(options) {
    if (options.closeOther) {
      this.toastyService.clearAll();
    }
    this.position = options.position ? options.position : this.position;
    const toastOptions: ToastOptions = {
      title: options.title,
      msg: options.msg,
      showClose: options.showClose,
      timeout: options.timeout,
      theme: options.theme,
      onAdd: (toast: ToastData) => {
        /* added */
      },
      onRemove: (toast: ToastData) => {
        /* removed */
      }
    };

    switch (options.type) {
      case 'default': this.toastyService.default(toastOptions); break;
      case 'info': this.toastyService.info(toastOptions); break;
      case 'success': this.toastyService.success(toastOptions); break;
      case 'wait': this.toastyService.wait(toastOptions); break;
      case 'error': this.toastyService.error(toastOptions); break;
      case 'warning': this.toastyService.warning(toastOptions); break;
    }
  }
}
