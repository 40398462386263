import { Component, OnInit, ViewEncapsulation, TemplateRef } from '@angular/core';
import { ToastData, ToastOptions, ToastyService } from 'ng2-toasty';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { PricesProvidersBE } from '../../../Entity/PricesProvidersBE'
import { ServiceApi } from '../../../shared/services/ServiceApi';
import { Router } from '@angular/router';
import { FileHolder } from 'angular2-image-upload';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-products_provider',
  templateUrl: './products_provider.component.html',
  styleUrls: [
    '../../../../../node_modules/ng2-toasty/style-bootstrap.css',
    '../../../../../node_modules/ng2-toasty/style-default.css',
    '../../../../../node_modules/ng2-toasty/style-material.css'
  ],
  encapsulation: ViewEncapsulation.None
})
export class ProductsProviderComponent implements OnInit {

  position = 'bottom-right';
  title: string;
  msg: string;
  showClose = true;
  theme = 'bootstrap';
  type = 'default';
  closeOther = false;
  marked = false;
  ShowPricesValues = false;

  objCurrentCategory: any = {}
  lstProductsProvider: any = [];
  lstCategories: any[] = [];
  lstSubcategories: any = [];
  lstProducts: any = [];
  lstTrademarks: any = [];
  lstCountries: any = [];
  lstDelivertimes: any = [];
  lstMeasurements: any = [];
  objResponseProductProvider: any = [];
  public category = {};
  public quotation = {};
  isFreeQuotation = false;

  objDelete: any = {}
  objProductProvider = new PricesProvidersBE();
  modalRef: BsModalRef;
  DataForm: FormGroup;

  constructor(
    private toastyService: ToastyService,
    private services: ServiceApi,
    private modalService: BsModalService,
    private router: Router,
    private _formBuilder: FormBuilder,
  ) {

    this.DataForm = this._formBuilder.group({
      Description: ['', Validators.required],
      Category: ['', Validators.required],
      Subcategory: ['', Validators.required],
      Product: ['', null],
      Brand: ['', Validators.required],
      Country: ['', Validators.required],
      Measures: ['', Validators.required],
      DeliveryTime: ['', Validators.required],
      chkRange: ['', null],
      Price: ['', null],
      PriceMinimum: ['', null],
      PriceMaximum: ['', null],
    });


  }

  gotoPricesProviders(value) {
    this.category = value
    this.router.navigateByUrl('pricesproviders', { state: this.category });
  }

  onChangeCategory(value) {
    debugger
    var obj: any = {}
    obj.Id = value;

    this.services.executeMethodGet(this.services.enviroment, "SubCategory?Search=" + obj.Id, (x) => {
      this.lstSubcategories = x.information.items;
      console.log(this.lstSubcategories)
    });

    this.services.executeMethodGet(this.services.enviroment, "Brand?Search=" + obj.Id, (x) => {
      this.lstTrademarks = x.information.items;
    });
    // x.information.items.filter(x => !x.isPayed)
    var objData = this.lstCategories.find(it => it.id == obj.Id);
    this.isFreeQuotation = objData.isFreeQuotation
  }

  onChangeProducts(value) {

    var obj: any = {}
    obj.Id = value;

    this.services.executeMethodGet(this.services.enviroment, "Product?Search=" + obj.Id, (x) => {
      this.lstProducts = x.information.items;
      console.log(this.lstProducts)
    });
  }

  toggleVisibility(e) {
    this.marked = e.target.checked;
    console.log(this.marked)
    if (this.marked == true) {
      this.ShowPricesValues = true;
    }
    else {
      this.ShowPricesValues = false;
    }
  }

  ngOnInit() {
    this.services.executeMethodGet(this.services.enviroment, "ProviderPrices?Search=" + this.services.getStorage('userInfo').id, (x) => {
      this.lstProductsProvider = x.information.items;
      console.log(this.lstProductsProvider)
    });

    this.services.executeMethodGet(this.services.enviroment, "Category", (x) => {
      this.lstCategories = x.information.items;
      //console.log(this.lstCategories)
    });

    this.services.executeMethodGet(this.services.enviroment, "Country", (x) => {
      this.lstCountries = x.information.items;
    });

    this.services.executeMethodGet(this.services.enviroment, "DeliveryTime", (x) => {
      this.lstDelivertimes = x.information.items;
      console.log(this.lstDelivertimes)
    });

    this.services.executeMethodGet(this.services.enviroment, "Measure", (x) => {
      this.lstMeasurements = x.information.items;
    });

  }

  SaveProductProvider() {
    debugger;
    if (this.DataForm.valid) {
      this.objProductProvider.providerId = this.services.getStorage('userInfo').id;
      this.services.executeMethod(this.services.catalog, "ProviderPrices", this.objProductProvider, (x) => {
        this.objResponseProductProvider = x
        if (this.objResponseProductProvider.status == true) {
          this.modalRef.hide();
          setTimeout(() => {
            this.addToast({ title: 'Registro Exitos!', msg: '', showClose: true, timeout: 1500, theme: 'material', position: 'center-center', type: 'success' });
          }, 800);
          this.ngOnInit();
        }
        this.objProductProvider = new PricesProvidersBE();
      });
    } else {
      this.services.validateAllFormFields(this.DataForm);
    }
  }

  UpdateProductProvider() {
    debugger;
    this.services.executeMethodPut(this.services.catalog, "ProviderPrices", this.objProductProvider, (x) => {
      this.objResponseProductProvider = x
      if (this.objResponseProductProvider.status == true) {
        this.modalRef.hide();
        setTimeout(() => {
          this.addToast({ title: 'Se Actualizo Con Exito!', msg: '', showClose: true, timeout: 1500, theme: 'material', position: 'center-center', type: 'success' });
        }, 800);
        this.ngOnInit();
      }
    });
  }

  DeleteProductProvider() {
    if(!confirm("Estás seguro que deseas eliminar este item?")){
      return false;
    }
    this.services.executeMethodDelete(this.services.catalog, "ProviderPrices/", this.objDelete.id, (x) => {
      this.modalRef.hide();
      this.lstProductsProvider = x
      if (this.lstProductsProvider.status == true) {
        this.addToast({ title: 'Registro Eliminado!', msg: '', showClose: true, timeout: 1500, theme: 'material', position: 'center-center', type: 'success' });
        this.ngOnInit();
      }
      this.objProductProvider = new PricesProvidersBE();
    });
  }

  handleUpload(event) {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.objProductProvider.productImage = reader.result.toString();
    };
  }

  ModalEditProduct(template, product) {
    debugger;
    this.modalRef = this.modalService.show(template, {
      class: 'modal-lg modal-dialog-centered',
    });
    this.objProductProvider = product
  }

  ModalAddProduct(template) {
    this.objProductProvider = new PricesProvidersBE();
    this.modalRef = this.modalService.show(template, {
      class: 'modal-lg modal-dialog-centered',
    });
  }

  ModalDelete(template, obj) {
    this.objDelete = obj
    this.modalRef = this.modalService.show(template, {
      class: 'modal-xs modal-dialog-centered',
    });
  }

  addToast(options) {
    if (options.closeOther) {
      this.toastyService.clearAll();
    }
    this.position = options.position ? options.position : this.position;
    const toastOptions: ToastOptions = {
      title: options.title,
      msg: options.msg,
      showClose: options.showClose,
      timeout: options.timeout,
      theme: options.theme,
      onAdd: (toast: ToastData) => {
        /* added */
      },
      onRemove: (toast: ToastData) => {
        /* removed */
      }
    };

    switch (options.type) {
      case 'default': this.toastyService.default(toastOptions); break;
      case 'info': this.toastyService.info(toastOptions); break;
      case 'success': this.toastyService.success(toastOptions); break;
      case 'wait': this.toastyService.wait(toastOptions); break;
      case 'error': this.toastyService.error(toastOptions); break;
      case 'warning': this.toastyService.warning(toastOptions); break;
    }
  }
}
