import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { FormGroup, FormControl } from '@angular/forms';
import { AppConfig } from '../../app.config';
import { JwtHelperService } from "@auth0/angular-jwt";



@Injectable()
export class ServiceApi {
  UrlApi: string
  TypeTemplate: string = "";
  public informationPay: any
  now: string;
  isDevice: boolean = false;
  Categories: any[] = [];


  public security: string = "";
  public enviroment: string = "";
  public catalog: string = "";
  public orders: string = "";
  helper = new JwtHelperService();



  constructor(private http: HttpClient, private router: Router, private config: AppConfig) {
    this.config = config;
    this.UrlApi = this.config.GetConfig('UrlApi');
    this.catalog = this.config.GetConfig('catalog');
    this.enviroment = this.config.GetConfig('enviroment');
    this.security = this.config.GetConfig('security');
    this.orders = this.config.GetConfig('orders');
  }

  /*tokenExpired(token: string = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6Ijk4YTFmNzhjLWU5MjctNGI0Yy0yNjM2LTA4ZDhhYzczYTk2NyIsInVzZXJuYW1lIjoiYUBnLmNvbSIsInByb2ZpbGVJZCI6IjFkYzNjZTJlLTlhOTMtNDU3NC05NDgxLWQ5YzhiZGQ3OTg2NyIsIm5iZiI6MTYxNDk2MDI4MywiZXhwIjoxNjE0OTc4MjgzLCJpYXQiOjE2MTQ5NjAyODN9.V1k7-5ECoNMYqHQmxtOfzI-UaeOUck1ZZGXVE_Dw9Y8") {
     const expiry = (JSON.parse(atob(token.split('.')[1]))).exp;
     alert(expiry);
     alert((Math.floor((new Date).getTime() / 1000)) >= expiry)
     return (Math.floor((new Date).getTime() / 1000)) >= expiry;
   }*/

  ValidateExpiredToken() {
    const token = localStorage.getItem('identity');
    if (this.helper.isTokenExpired(token)) {
      this.router.navigate(['/']);
    } else {
      //token valid//
    }
  }

  public tryDouble(value) {
    if (isNaN(value)) {
      return 0;
    }
    return value;
  }


  public saveData() {
    //this.localService.setJsonValue('User', this.user);
    //localStorage.setItem('User', this.user);
    localStorage.setItem('Time', new Date().getTime().toString());
  }




  public getDataUser() {
    return JSON.parse(localStorage.getItem('User'));
  }



  public VerificateSesion() {
    if (localStorage.getItem('Time') != undefined) {
      var oldDate: Date;
      oldDate = new Date(Number(localStorage.getItem('Time')));
      oldDate.setHours(oldDate.getHours() + 2);
      if (new Date() > oldDate) {
        // this.Order.Customer = new CustomerBE();
        this.router.navigate(['./Main']);
        localStorage.removeItem('Time')
        this.saveData();
      }
    }

  }

  public VerificateData() {
    /*this.getData();
    if (!this.Order.Customer.IsLogged) {
      if (this.Order.PaymentMethodTotal != "CC") {
        this.router.navigate(['./Login']);
      }
    }
    return this.Order;*/
  }

  public validateAllFormFields(formGroup: FormGroup) {         //{1}
    Object.keys(formGroup.controls).forEach(field => {  //{2}
      const control = formGroup.get(field);             //{3}
      if (control instanceof FormControl) {             //{4}
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {        //{5}
        this.validateAllFormFields(control);            //{6}
      }
    });
  }



  public resetGeneralData() {
    /* this.Order.Packages = new Array<PackageOrderBE>();
     let copy = JSON.parse(JSON.stringify(this.Order.Customer));
     let copyOrder = JSON.parse(JSON.stringify(this.Order));
     this.Customer = copy;
     this.Order = new OrderBE();
     this.Order.Customer = this.Customer;
     this.Order.isCustomerShipping = copyOrder.isCustomerShipping;
     this.saveData();*/
  }

  public Round(num, decimals) {
    var t = Math.pow(10, decimals);
    return (Math.round((num * t) + (decimals > 0 ? 1 : 0) * (Math.sign(num) * (10 / Math.pow(100, decimals)))) / t).toFixed(decimals);
  }

  public validateRoute(actRoute, route) {
    /*
        let shipmentType;
    
        actRoute.paramMap.subscribe(params => {
          shipmentType = params.get('ShipmentType');
    
          if (shipmentType == 'CustomerShipping' && this.Order.Customer.Role == 1) {
            this.Order.isCustomerShipping = true;
          } else {
            this.Order.isCustomerShipping = false;
            this.router.navigate(['./' + route]);
          }
        });
    
        return shipmentType;*/
  }

  getActualRoute() {
    let route = this.router.url;
    route = route.split('/')[1];
    return route;
  }


  public executeMethodWithOut(enviroment, nameMethod, objData, returnData) {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    this.http.post(this.UrlApi + enviroment + nameMethod, JSON.stringify(objData), { headers: headers })
      .subscribe(data => {
        //this.logService.insertLocalLog(data, this.getActualRoute() + ' - Request: ' + nameMethod, null);
        returnData(data);
      },
        err => {
          console.log("Error")
          //this.logService.insertLocalLog(err, this.getActualRoute() + ' - Error: ' + nameMethod, this.getData());
        }
      );
  }

  public executeMethodWithOutGet(enviroment, nameMethod, returnData) {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    this.http.get(this.UrlApi + enviroment + nameMethod, { headers: headers })
      .subscribe(data => {
        //this.logService.insertLocalLog(data, this.getActualRoute() + ' - Request: ' + nameMethod, null);
        returnData(data);
      },
        err => {
          console.log("Error" + err.message)
          //this.logService.insertLocalLog(err, this.getActualRoute() + ' - Error: ' + nameMethod, this.getData());
        }
      );
  }

  public executeMethoddWithOutPut(enviroment, nameMethod, objData, returnData) {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    this.http.put(this.UrlApi + enviroment + nameMethod, JSON.stringify(objData), { headers: headers })
      .subscribe(data => {
        //this.logService.insertLocalLog(data, this.getActualRoute() + ' - Request: ' + nameMethod, null);
        returnData(data);
      },
        err => {
          console.log("Error")
          //this.logService.insertLocalLog(err, this.getActualRoute() + ' - Error: ' + nameMethod, this.getData());
        }
      );
  }

  public executeMethod(enviroment, nameMethod, objData, returnData) {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    headers = headers.set("Authorization", `Bearer ${localStorage.getItem('identity')}`);
     console.log("Error"+objData+returnData)
    this.http.post(this.UrlApi + enviroment + nameMethod, JSON.stringify(objData), { headers: headers })
      .subscribe(data => {
        //this.logService.insertLocalLog(data, this.getActualRoute() + ' - Request: ' + nameMethod, null);
        returnData(data);
      },
        err => {
          console.log("Error")
          //this.logService.insertLocalLog(err, this.getActualRoute() + ' - Error: ' + nameMethod, this.getData());
        }
      );
  }

  public executeMethodPut(enviroment, nameMethod, objData, returnData) {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    headers = headers.set("Authorization", `Bearer ${localStorage.getItem('identity')}`);
    this.http.put(this.UrlApi + enviroment + nameMethod, JSON.stringify(objData), { headers: headers })
      .subscribe(data => {
        //this.logService.insertLocalLog(data, this.getActualRoute() + ' - Request: ' + nameMethod, null);
        returnData(data);
      },
        err => {
          console.log("Error")
          //this.logService.insertLocalLog(err, this.getActualRoute() + ' - Error: ' + nameMethod, this.getData());
        }
      );
  }

  public executeMethodPutFiles(enviroment, nameMethod, objData, returnData) {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    //headers = headers.set("Authorization", `Bearer ${localStorage.getItem('identity')}`);
    this.http.put(this.UrlApi + enviroment + nameMethod, JSON.stringify(objData), { headers: headers })
      .subscribe(data => {
        //this.logService.insertLocalLog(data, this.getActualRoute() + ' - Request: ' + nameMethod, null);
        returnData(data);
      },
        err => {
          console.log("Error")
          //this.logService.insertLocalLog(err, this.getActualRoute() + ' - Error: ' + nameMethod, this.getData());
        }
      );
  }

  public executeMethodGet(enviroment, nameMethod, returnData) {

    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    headers = headers.set("Authorization", `Bearer ${localStorage.getItem('identity')}`);
    this.http.get(this.UrlApi + enviroment + nameMethod, { headers: headers })
      .subscribe(data => {
        //this.logService.insertLocalLog(data, this.getActualRoute() + ' - Request: ' + nameMethod, null);
        returnData(data);
      },
        err => {
          console.log("Error")
          //this.logService.insertLocalLog(err, this.getActualRoute() + ' - Error: ' + nameMethod, this.getData());
        }
      );
  }

  public executeMethodDelete(enviroment, nameMethod, objData, returnData) {

    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    headers = headers.set("Authorization", `Bearer ${localStorage.getItem('identity')}`);
    this.http.delete(this.UrlApi + enviroment + nameMethod + objData, { headers: headers })
      .subscribe(data => {
        //this.logService.insertLocalLog(data, this.getActualRoute() + ' - Request: ' + nameMethod, null);        
        returnData(data);
      },
        err => {
          console.log("Error")
          //this.logService.insertLocalLog(err, this.getActualRoute() + ' - Error: ' + nameMethod, this.getData());
        }
      );
  }

  public getUniqueId(parts: number): string {
    const stringArr = [];
    for (let i = 0; i < parts; i++) {
      // tslint:disable-next-line:no-bitwise
      const S4 = (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
      stringArr.push(S4);
    }
    return stringArr.join('-');
  }

  public setStorage(name, data) {
    localStorage.setItem(name, JSON.stringify(data));
  }

  public getStorage(name) {
    return JSON.parse(localStorage.getItem(name))
  }

}
