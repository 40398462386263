import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ToastData, ToastOptions, ToastyService } from 'ng2-toasty';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ServiceApi } from '../../../shared/services/ServiceApi';
import { Router } from '@angular/router';

@Component({
  selector: 'app-email',
  templateUrl: './email.component.html',
  styleUrls: [
    '../../../../../node_modules/ng2-toasty/style-bootstrap.css',
    '../../../../../node_modules/ng2-toasty/style-default.css',
    '../../../../../node_modules/ng2-toasty/style-material.css'
  ],
  encapsulation: ViewEncapsulation.None
})
export class EmailComponent implements OnInit {
  position = 'bottom-right';
  title: string;
  msg: string;
  showClose = true;
  theme = 'bootstrap';
  type = 'default';
  closeOther = false;
  modalRef: BsModalRef;
  lstTemplates: any = [];
  objTemplate: any = {}
  objResponseTemplates: any = [];  

  editTemplate = true;
  editIcon = 'icofont-close';  

  constructor(
    private toastyService: ToastyService,
    private services: ServiceApi,
    private router: Router,
    private modalService: BsModalService
  ) {

  }

  UpdateTemplate() {    
    this.services.executeMethodPut(this.services.enviroment, "EmailTemplate", this.objTemplate, (x) => {
      this.objResponseTemplates = x
      if (this.objResponseTemplates.status == true) {        
        setTimeout(() => {
          this.addToast({ title: 'Se Actualizo Con Exito!', msg: '', showClose: true, timeout: 300, theme: 'material', position: 'center-center', type: 'success' });
        }, 100);
        setTimeout(() => {
          this.editTemplate = true;
        }, 400);        
        this.ngOnInit();
      }
    });
  }

  ngOnInit() {
    this.services.executeMethodGet(this.services.enviroment, "EmailTemplate", (x) => {      
      this.lstTemplates = x.information.items;            
    });
  }

 
  toggleEditTemplate(template) {
    this.objTemplate = template;
    this.editIcon = 'icofont-close';
    this.editTemplate = !this.editTemplate;
  }
 
  addToast(options) {
    if (options.closeOther) {
      this.toastyService.clearAll();
    }
    this.position = options.position ? options.position : this.position;
    const toastOptions: ToastOptions = {
      title: options.title,
      msg: options.msg,
      showClose: options.showClose,
      timeout: options.timeout,
      theme: options.theme,
      onAdd: (toast: ToastData) => {
        /* added */
      },
      onRemove: (toast: ToastData) => {
        /* removed */
      }
    };

    switch (options.type) {
      case 'default': this.toastyService.default(toastOptions); break;
      case 'info': this.toastyService.info(toastOptions); break;
      case 'success': this.toastyService.success(toastOptions); break;
      case 'wait': this.toastyService.wait(toastOptions); break;
      case 'error': this.toastyService.error(toastOptions); break;
      case 'warning': this.toastyService.warning(toastOptions); break;
    }
  }
}
