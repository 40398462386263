import { Component, OnInit } from '@angular/core';
import { ServiceApi } from '../../../../shared/services/ServiceApi';
import { UserBE } from '../../../../Entity/UserBE'
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserLoginBE } from '../../../../Entity/UserLoginBE';

@Component({
  selector: 'app-verify-code',
  templateUrl: './verifyCode.component.html'
})
export class VerifyCodeComponent implements OnInit {

  user = new UserBE();
  public identity;
  public _user;  
  data: any = [];
  showProvider = true
  strCode: string  = ""
  strAddress: string = ""
  DataForm: FormGroup;

  constructor(
    private services: ServiceApi,
    private router: Router,
    private _formBuilder: FormBuilder,
  ) { 
    this.user = new UserBE();
    this.DataForm = this._formBuilder.group({
        Code: ['', Validators.required],
      });
  }

  verifyCode() {
    if (this.DataForm.valid) {

      var strEmail = ""
      var isChange = false
      var user = this.services.getStorage('tempSignUp')
      if(user != undefined  && user.email != undefined && user.email != null){
        strEmail = user.email
      } else {
        isChange = true
        strEmail = this.services.getStorage('emailRecovery')
      }
      this.services.executeMethodWithOutGet(this.services.security,"User/verificationCode/email=" + strEmail + "/code=" + this.strCode ,(x) => {
        var response = x;
        if(response.status){
          if(isChange){
            this.router.navigate(['./changepassword']);
          } else {
          this.UserLogin();
          }
        } else {
          alert("El código ingresado es incorrecto")
        }
      
      });
      } else {
        this.services.validateAllFormFields(this.DataForm);
      }
  }

  UserLogin() {
    var user = this.services.getStorage('tempSignUp')
    var objUserLogin = new UserLoginBE();
    objUserLogin.username = user.email;
    objUserLogin.password = user.password;
    this.services.executeMethodWithOut(this.services.security, "Login/authenticateFree", objUserLogin, (x) => {
      this.data = x;
      if (this.data.status == true) {
        this.services.setStorage('tempSignUp',null)
        this.identity = this.data.information.token;
        this._user = this.data.information;
        var profileID = this.data.information.profileId;
        var newprofileID = profileID.toUpperCase()
        localStorage.setItem('identity', this.identity);
        localStorage.setItem('userInfo', JSON.stringify(this._user));
        localStorage.setItem('profile', newprofileID);
        if(newprofileID == "0614E506-5557-4FD1-81F3-08D8820C3640"){
        this.router.navigate(['./customercategories']);
        } else {
        this.router.navigate(['./providerproducts']);
        }
      }
      else {
        alert(this.data.message);
      }
    });
  }

  ngOnInit() {
    document.querySelector('body').setAttribute('themebg-pattern', 'theme1');
  }

}
