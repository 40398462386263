import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { animate, AUTO_STYLE, state, style, transition, trigger } from '@angular/animations';
import { MenuItems } from '../../shared/menu-items/menu-items';
import { ServiceApi } from '../../shared/services/ServiceApi';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';
import { PerfectScrollbarComponent } from 'ngx-perfect-scrollbar';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss'],
  animations: [
    trigger('mobileMenuTop', [
      state('no-block, void',
        style({
          overflow: 'hidden',
          height: '0px',
        })
      ),
      state('yes-block',
        style({
          height: AUTO_STYLE,
        })
      ),
      transition('no-block <=> yes-block', [
        animate('400ms ease-in-out')
      ])
    ]),
    trigger('slideInOut', [
      state('in', style({
        transform: 'translate3d(0, 0, 0)'
      })),
      state('out', style({
        transform: 'translate3d(100%, 0, 0)'
      })),
      transition('in => out', animate('400ms ease-in-out')),
      transition('out => in', animate('400ms ease-in-out'))
    ]),
    trigger('slideOnOff', [
      state('on', style({
        transform: 'translate3d(0, 0, 0)'
      })),
      state('off', style({
        transform: 'translate3d(100%, 0, 0)'
      })),
      transition('on => off', animate('400ms ease-in-out')),
      transition('off => on', animate('400ms ease-in-out'))
    ]),
    trigger('fadeInOutTranslate', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('400ms ease-in-out', style({ opacity: 1 }))
      ]),
      transition(':leave', [
        style({ transform: 'translate(0)' }),
        animate('400ms ease-in-out', style({ opacity: 0 }))
      ])
    ])
  ]
})
export class AdminComponent implements OnInit {

  objUserInfo: any = {};
  objInformation: any = {};
  objTemplate: any = {};
  objMessage: any = {}
  lstNotifications: any[] = [];

  modalTitle = "";
  modalContent = "";

  navType: string; /* st1, st2(default), st3, st4 */
  themeLayout: string; /* vertical(default) */
  layoutType: string; /* dark, light */
  verticalPlacement: string; /* left(default), right */
  verticalLayout: string; /* wide(default), box */
  deviceType: string; /* desktop(default), tablet, mobile */
  verticalNavType: string; /* expanded(default), offcanvas */
  verticalEffect: string; /* shrink(default), push, overlay */
  vNavigationView: string; /* view1(default) */
  pcodedHeaderPosition: string; /* fixed(default), relative*/
  pcodedSidebarPosition: string; /* fixed(default), absolute*/
  headerTheme: string; /* theme1(default), theme2, theme3, theme4, theme5, theme6 */
  logoTheme: string; /* theme1(default), theme2, theme3, theme4, theme5, theme6 */

  innerHeight: string;
  strMessageChat: string;
  strCurrentConversation: string;
  windowWidth: number;

  toggleOn: boolean;

  headerFixedMargin: string;
  navBarTheme: string; /* theme1, themelight1(default)*/
  activeItemTheme: string; /* theme1, theme2, theme3, theme4(default), ..., theme11, theme12 */

  isCollapsedMobile: string;
  isCollapsedSideBar: string;

  chatToggle: string;
  chatToggleInverse: string;
  chatInnerToggle: string;
  chatInnerToggleInverse: string;

  menuTitleTheme: string; /* theme1, theme2, theme3, theme4, theme5(default), theme6 */
  itemBorder: boolean;
  itemBorderStyle: string; /* none(default), solid, dotted, dashed */
  subItemBorder: boolean;
  subItemIcon: string; /* style1, style2, style3, style4, style5, style6(default) */
  dropDownIcon: string; /* style1(default), style2, style3 */
  configOpenRightBar: string;
  isSidebarChecked: boolean;
  isHeaderChecked: boolean;
  modalRef: BsModalRef;
  lstSettingsContact: any[] = []
  lstChats: any[] = []
  lstMessages: any[] = []
  currentConversation: any = {}

  @ViewChild('searchFriends', /* TODO: add static flag */ { static: false }) search_friends: ElementRef;

  public config: any;

  strContactUrl: String
  strContactPhone: String
  strContactEmail: String
  strContactWP: String;

  constructor(
    public menuItems: MenuItems,
    private services: ServiceApi,
    private modalService: BsModalService,
    private router: Router,
  ) {
    this.navType = 'st5';
    this.themeLayout = 'vertical';
    this.vNavigationView = 'view1';
    this.verticalPlacement = 'left';
    this.verticalLayout = 'wide';
    this.deviceType = 'desktop';
    this.verticalNavType = 'expanded';
    this.verticalEffect = 'shrink';
    this.pcodedHeaderPosition = 'fixed';
    this.pcodedSidebarPosition = 'fixed';
    this.headerTheme = 'theme1';
    this.logoTheme = 'theme1';

    this.toggleOn = true;

    this.headerFixedMargin = '80px';
    this.navBarTheme = 'themelight1';
    this.activeItemTheme = 'theme4';

    this.isCollapsedMobile = 'no-block';
    this.isCollapsedSideBar = 'no-block';

    this.chatToggle = 'out';
    this.chatToggleInverse = 'in';
    this.chatInnerToggle = 'off';
    this.chatInnerToggleInverse = 'on';

    this.menuTitleTheme = 'theme5';
    this.itemBorder = true;
    this.itemBorderStyle = 'none';
    this.subItemBorder = true;
    this.subItemIcon = 'style6';
    this.dropDownIcon = 'style1';
    this.isSidebarChecked = true;
    this.isHeaderChecked = true;

    const scrollHeight = window.screen.height - 150;
    this.innerHeight = scrollHeight + 'px';
    this.windowWidth = window.innerWidth;
    this.setMenuAttributes(this.windowWidth);

  }

  @ViewChild("pfcScrollMessage", {static: true}) scrollbar?: PerfectScrollbarComponent;

  ngOnInit() {
    this.setBackgroundPattern('pattern2');
    this.objUserInfo = this.services.getStorage("userInfo");
    //console.log(this.objUserInfo)

    this.services.ValidateExpiredToken();
    this.getNotifications();
    this.getChats();

    this.getContacts();

    if (this.objUserInfo.paymentState == 2 && this.objUserInfo.profileId.toUpperCase() == "0614E506-4447-4FD1-81F3-08D8820C3640") {
      this.router.navigateByUrl('nopayment');
      $(".itemsMenu").css("display", "none")
    }
  }

  getContacts() {
    this.services.executeMethodGet(this.services.enviroment, "Setting?Search=c9514a90-ea4b-447b-8a4a-7e25db876897", (x) => {
      this.lstSettingsContact = x.information.items;

      this.strContactWP = this.lstSettingsContact.find(x => x.name == "Whatsapp").value
      this.strContactUrl = this.lstSettingsContact.find(x => x.name == "Website").value
      this.strContactPhone = this.lstSettingsContact.find(x => x.name == "Telefono").value
      this.strContactEmail = this.lstSettingsContact.find(x => x.name == "Email").value

    });
  }

  getNotifications() {
    var objUser = this.services.getStorage("userInfo");
    this.services.executeMethodGet(this.services.orders, "Notification?Search=" + objUser.id, (x) => {
      this.objInformation = x.information
      this.lstNotifications = x.information.items
      this.lstNotifications.sort((a, b) => {
        return <any>new Date(b.createdDate) - <any>new Date(a.createdDate);
      });
    });
  }

  getChats() {
    var objUser = this.services.getStorage("userInfo");
    this.services.executeMethodGet(this.services.orders, "Conversations?Search=" + objUser.id, (x) => {
      this.lstChats = x.information.items
    });
  }

  getMessages(conversationId) {
    this.strCurrentConversation = conversationId
    this.services.executeMethodGet(this.services.orders, "Messages?Search=" + conversationId, (x) => {
      this.lstMessages = x.information.items
      setTimeout(() => {
        this.scrollbar.directiveRef.scrollToBottom(0, 500);  // 500ms is the speed
     }, 100);
      
    });
  }

  sendMessage() {
    this.objMessage.message = this.strMessageChat
    this.objMessage.conversationId = this.strCurrentConversation
    this.objMessage.userFromId = this.objUserInfo.id
    this.objMessage.messageTypeId = "5D5E5A2B-1B8E-4792-9A86-3F59B63F3C16"

    this.services.executeMethod(this.services.orders, "Messages", this.objMessage, (x) => {
      this.strMessageChat = "";
      this.getMessages(this.strCurrentConversation)
    });
  }

  gotoQuotationDetail(strId) {

    var quotation: any = {}
    quotation.id = strId
    this.router.navigateByUrl('quotescustomer/productsquotesallcustomer', { state: quotation });

  }

  goToOrderDetail(strId, isservice) {
    var order: any = {}
    order.id = strId
    order.isService = isservice
    this.router.navigateByUrl('/customershoppingdetail', { state: order });
  }

  goToOrderProvider(strId, isservice) {
    var order: any = {}
    order.id = strId
    order.isService = isservice
    this.router.navigateByUrl('ordersprovider/detailprocessprovider', { state: order });
  }



  showNotification(obj) {

    if (this.objUserInfo.profileId.toUpperCase() == "0614E506-5557-4FD1-81F3-08D8820C3640") {
      this.services.executeMethodPut(this.services.orders, "Notification", obj, (x) => {
        this.getNotifications();
        if (obj.typeAction == 1) {
          this.gotoQuotationDetail(obj.processId)
        } else if (obj.typeAction == 2 || obj.typeAction == 3 || obj.typeAction == 4 || obj.typeAction == 5) {
          this.goToOrderDetail(obj.processId, obj.isService)
        } else {
          if (obj.isService) {
            this.services.executeMethodGet(this.services.orders, "ServiceQuotationDetail/GetInformation/" + obj.processId, (x) => {
              var objInfo: any = {}
              objInfo.originData = x.serviceQuotation
              objInfo.data = x.detailOut

              this.router.navigateByUrl('quotescustomer/quotebyservicecustomer', { state: objInfo });
            });
          } else {
            this.gotoQuotationDetail(obj.processId)
          }

        }
      });
    } else {
      if (obj.typeAction == 2 || obj.typeAction == 3 || obj.typeAction == 4 || obj.typeAction == 5) {
        this.goToOrderProvider(obj.processId, obj.isService)
      } else
        if (obj.typeAction == 7) {
          this.services.executeMethodGet(this.services.orders, "ServiceQuotation/GetServiceById/" + obj.processId, (x) => {
            var objInfo: any = {}
            objInfo.id = obj.processId
            objInfo.data = x.information

            this.router.navigateByUrl('quotesprovider/createservicesquotationprovider', { state: objInfo });
          });
        }

    }
  }

  GetLegal(value) {
    if (value == 1) {
      value = "Terms"
    }
    if (value == 2) {
      value = "Privacy"
    }
    if (value == 3) {
      value = "Industry"
    }
    this.services.executeMethodGet(this.services.orders, "EmailTemplate?Search=" + value, (x) => {
      this.objTemplate = x.information.items
      this.modalTitle = this.objTemplate[0].subject
      this.modalContent = this.objTemplate[0].body
    });
  }

  ModalLegal(template, value) {
    this.GetLegal(value);
    this.modalRef = this.modalService.show(template, {
      class: 'modal-lg modal-dialog-centered',
    });

  }

  ModalSupport(template, value) {
    this.GetLegal(value);
    this.modalRef = this.modalService.show(template, {
      class: 'modal-xs modal-dialog-centered',
    });

  }

  onResize(event) {
    this.innerHeight = event.target.innerHeight + 'px';
    /* menu responsive */
    this.windowWidth = event.target.innerWidth;
    let reSizeFlag = true;
    if (this.deviceType === 'tablet' && this.windowWidth >= 768 && this.windowWidth <= 1024) {
      reSizeFlag = false;
    } else if (this.deviceType === 'mobile' && this.windowWidth < 768) {
      reSizeFlag = false;
    }
    /* for check device */
    if (reSizeFlag) {
      this.setMenuAttributes(this.windowWidth);
    }
  }

  setMenuAttributes(windowWidth) {
    if (windowWidth >= 768 && windowWidth <= 1024) {
      this.deviceType = 'tablet';
      this.verticalNavType = 'offcanvas';
      this.verticalEffect = 'push';
    } else if (windowWidth < 768) {
      this.deviceType = 'mobile';
      this.verticalNavType = 'offcanvas';
      this.verticalEffect = 'overlay';
    } else {
      this.deviceType = 'desktop';
      this.verticalNavType = 'expanded';
      this.verticalEffect = 'shrink';
    }
  }

  toggleOpened() {
    if (this.windowWidth < 768) {
      this.toggleOn = this.verticalNavType === 'offcanvas' ? true : this.toggleOn;
    }
    this.verticalNavType = this.verticalNavType === 'expanded' ? 'offcanvas' : 'expanded';
  }

  onClickedOutside(e: Event) {
    if (this.windowWidth < 768 && this.toggleOn && this.verticalNavType !== 'offcanvas') {
      this.toggleOn = true;
      this.verticalNavType = 'offcanvas';
    }
  }

  onMobileMenu() {
    this.isCollapsedMobile = this.isCollapsedMobile === 'yes-block' ? 'no-block' : 'yes-block';
  }

  toggleChat() {
    this.chatToggle = this.chatToggle === 'out' ? 'in' : 'out';
    this.chatToggleInverse = this.chatToggleInverse === 'out' ? 'in' : 'out';
    this.chatInnerToggle = 'off';
    this.chatInnerToggleInverse = 'off';
  }

  toggleChatInner(item) {
    this.chatInnerToggle = this.chatInnerToggle === 'off' ? 'on' : 'off';
    this.chatInnerToggleInverse = this.chatInnerToggleInverse === 'off' ? 'on' : 'off';
    if (item != undefined && item != null) {
      this.currentConversation = item
      this.getMessages(item.id)
    }
  }

  searchFriendList(e: Event) {
    const search = (this.search_friends.nativeElement.value).toLowerCase();
    let search_input: string;
    let search_parent: any;
    const friendList = document.querySelectorAll('.userlist-box .media-body .chat-header');
    Array.prototype.forEach.call(friendList, function (elements, index) {
      search_input = (elements.innerHTML).toLowerCase();
      search_parent = (elements.parentNode).parentNode;
      if (search_input.indexOf(search) !== -1) {
        search_parent.classList.add('show');
        search_parent.classList.remove('hide');
      } else {
        search_parent.classList.add('hide');
        search_parent.classList.remove('show');
      }
    });
  }

  toggleOpenedSidebar() {
    this.isCollapsedSideBar = this.isCollapsedSideBar === 'yes-block' ? 'no-block' : 'yes-block';
  }

  toggleRightbar() {
    this.configOpenRightBar = this.configOpenRightBar === 'open' ? '' : 'open';
  }

  setSidebarPosition() {
    this.isSidebarChecked = !this.isSidebarChecked;
    this.pcodedSidebarPosition = this.isSidebarChecked === true ? 'fixed' : 'absolute';
  }

  setHeaderPosition() {
    this.isHeaderChecked = !this.isHeaderChecked;
    this.pcodedHeaderPosition = this.isHeaderChecked === true ? 'fixed' : 'relative';
    this.headerFixedMargin = this.isHeaderChecked === true ? '80px' : '';
  }

  setBackgroundPattern(pattern) {
    document.querySelector('body').setAttribute('themebg-pattern', pattern);
  }

  setLayoutType(type: string) {
    this.layoutType = type;
    if (type === 'dark') {
      this.headerTheme = 'theme6';
      this.navBarTheme = 'theme1';
      this.logoTheme = 'theme6';
      document.querySelector('body').classList.add('dark');
    } else {
      this.headerTheme = 'theme1';
      this.navBarTheme = 'themelight1';
      this.logoTheme = 'theme1';
      document.querySelector('body').classList.remove('dark');
    }
  }

  setNavBarTheme(theme: string) {
    if (theme === 'themelight1') {
      this.navBarTheme = 'themelight1';
    } else {
      this.navBarTheme = 'theme1';
    }
  }

  getName(strName: string){
    var data = strName.split('/')
    return data[data.length - 1]
  }

  openFile() {
    document.getElementById('upload-file').click();
  }
  
  addAttachment(fileInput: any) {
    const file = fileInput.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      var data = file.name.split('.')
      var strBase64 = reader.result.toString().split(",")[1];
      var strExtension = "." +  data[data.length - 1];
      this.sendMessageFile(strBase64,strExtension)

    };
    //  handle the rest 
  }

  sendMessageFile(urlFile, extension) {
    this.objMessage.urlImage = urlFile
    this.objMessage.extension = extension
    this.objMessage.message = "File"
    this.objMessage.conversationId = this.strCurrentConversation
    this.objMessage.userFromId = this.objUserInfo.id
    this.objMessage.messageTypeId = "DAED5E7F-6176-42A4-B55B-9C7EA860A502"

    this.services.executeMethod(this.services.orders, "Messages", this.objMessage, (x) => {
      this.strMessageChat = "";
      this.getMessages(this.strCurrentConversation)
    });
  }

}
